// eslint-disable-next-line max-classes-per-file
import React from 'react';
import config from 'config';
import Select from 'react-select';
import SignaturePad from 'react-signature-canvas';
import ReactBootstrapSlider from 'react-bootstrap-slider';
import ReCAPTCHA from 'react-google-recaptcha';
//import Slider, { Range } from 'rc-slider';
//import 'rc-slider/assets/index.css';
import TextAreaAutosize from 'react-textarea-autosize';
import StarRating from './star-rating';
import HeaderBar from './header-bar';
import DatePicker from './date-picker';
import ComponentHeader from './component-header';
import ComponentLabel from './component-label';
//import "bootstrap-slider/dist/css/bootstrap.css";
import 'bootstrap-slider/dist/css/bootstrap-slider.css';
import myxss from './myxss';
import services from 'services/services';
import xhr from 'xhr.js';
import isEqual from 'lodash/isEqual';

import ReactS3Uploader from 'react-s3-uploader';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label as RebassLabel, Textarea, Input } from '@rebass/forms';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import {
    SCREENER_QUESTION_ERRORS_CLASSNAME,
    VALIDATION_EXACT,
    VALIDATION_MAXIMUM,
    VALIDATION_MINIMUM
} from 'utils/constants';
import { getElementLabel } from './getElementLabel';

import RecordRTC from 'recordrtc';

import {
    FiUploadCloud,
    FiCheckCircle,
    FiMonitor,
    FiRotateCcw,
    FiVideoOff,
    FiVideo,
    FiStopCircle,
    FiTrash2,
    FiAlertCircle
} from 'react-icons/fi';
import Badge from 'components/Badge';

function isNonStandardOption(option) {
    return option.is_add_other || option.is_none_of_the_above;
}

function renderNonStandardOption(option, element_add_other, element_none_of_the_above) {
    if (option.is_add_other) {
        return element_add_other;
    } else if (option.is_none_of_the_above) {
        return element_none_of_the_above;
    }
}

const FormElements = {};

class Header extends React.Component {
    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={baseClasses}>
                {this.props.editElement ? (
                    this.props.editElement
                ) : (
                    <div>
                        {helpersScreener.showQuestionNumber(this.props.all_questions, this.props.data)}
                        <Box className="fs-headline-24 color-text-primary">
                            <div
                                style={{ whiteSpace: 'pre-wrap' }}
                                className="quill-render"
                                dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }}
                            />
                        </Box>
                        <ComponentLabel
                            {...this.props}
                            isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                        />
                    </div>
                )}
            </div>
        );
    }
}

class Paragraph extends React.Component {
    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={baseClasses}>
                {this.props.editElement ? (
                    this.props.editElement
                ) : (
                    <div>
                        {helpersScreener.showQuestionNumber(this.props.all_questions, this.props.data)}
                        <Box className="fs-body-16 color-text-secondary">
                            <div
                                style={{ whiteSpace: 'pre-wrap' }}
                                className="quill-render"
                                dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }}
                            />
                        </Box>
                        <ComponentLabel
                            {...this.props}
                            isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                        />
                    </div>
                )}
            </div>
        );
    }
}

class Label extends React.Component {
    render() {
        let classNames = 'static';
        if (this.props.data.bold) {
            classNames += ' bold';
        }
        if (this.props.data.italic) {
            classNames += ' italic';
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={baseClasses}>
                {this.props.editElement ? (
                    this.props.editElement
                ) : (
                    <div>
                        <ComponentHeader {...this.props} />
                        <label
                            className={classNames}
                            dangerouslySetInnerHTML={{ __html: myxss.process(this.props.data.content) }}
                        />
                    </div>
                )}
            </div>
        );
    }
}

class LineBreak extends React.Component {
    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={baseClasses}>
                {this.props.editElement ? (
                    this.props.editElement
                ) : (
                    <div>
                        <ComponentHeader {...this.props} />
                        <hr />
                    </div>
                )}
            </div>
        );
    }
}

class AddPipeInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
            recordVideo: null,
            src: null,
            permissionAllowed: false,
            uploadSuccess: null,
            isRecordingVideo: false,
            isUploadingVideo: false,
            hasRecordedVideo: false,
            hasUploadedVideo: false,
            isCountingDown: false,
            uploadVideoError: false
        };
        this.inputField = React.createRef();

        this.requestUserMedia = this.requestUserMedia.bind(this);
        this.allowRecord = this.allowRecord.bind(this);
        this.startRecord = this.startRecord.bind(this);
        this.stopRecord = this.stopRecord.bind(this);
        this.uploadVideo = this.uploadVideo.bind(this);
        this.uploadVideoNotIos = this.uploadVideoNotIos.bind(this);
        this.clickRecordMobile = this.clickRecordMobile.bind(this);

        const randomId = helpers.ID();
        this.videoWrapperId = `video-wrapper-${randomId}`;
        this.videoTimerId = `video-timer-${randomId}`;
        this.videoRecorderId = `video-rec-${randomId}`;
        this.videoControlsId = `video-controls-${randomId}`;
    }

    getAutocompleteAttribute() {
        //return 'name';
    }

    getAddPipeContainerId() {
        return this.props.data.id + '-addpipe-container';
    }

    captureUserMedia(callback) {
        navigator.mediaDevices
            .getUserMedia({ video: true, audio: { echoCancellation: true } })
            .then(function(camera) {
                callback(camera);
            })
            .catch(function(error) {
                alert('Unable to capture your camera. Please allow video capture.');
                console.error(error);
                helpers.trackError(error);
            });
    }

    resetToStartRecord(element) {
        if (element) {
            element.muted = true;
            element.volume = 0;
            element.controls = 0;
            element.autoplay = 1;
        }
    }

    requestUserMedia() {
        console.log('requestUserMedia');
        this.captureUserMedia(camera => {
            console.log('camera', camera);

            const element = document.getElementById(this.videoRecorderId);
            this.resetToStartRecord(element);

            if ('srcObject' in element) {
                element.srcObject = camera;
            } else if ('mozSrcObject' in element) {
                element.mozSrcObject = camera;
            } else {
                element.srcObject = camera;
            }

            //console.log('setting state', this.state)

            this.setState({
                permissionAllowed: true
            });

            const timer = document.getElementById(this.videoTimerId);
            if (timer) {
                timer.innerHTML = '';
            }
        });
    }

    allowRecord() {
        this.prepareToReRecord();

        const hasGetUserMedia = !!(
            navigator.getUserMedia ||
            navigator.webkitGetUserMedia ||
            navigator.mozGetUserMedia ||
            navigator.msGetUserMedia ||
            // for safari
            (navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
        );

        if (!hasGetUserMedia) {
            alert(
                'Your browser cannot stream from your webcam. Please switch to Chrome, Firefox, or the latest version of Safari.'
            );
            return;
        }
        this.requestUserMedia();
    }

    startRecordingTimer() {
        this.startRecordingTimerInterval = setInterval(() => {
            this.decreaseTimeRemaining();
        }, 1000);
    }

    startRecord() {
        this.setState({ isCountingDown: true });

        const defaultSeconds = this.props.data.video_recording_time ? this.props.data.video_recording_time : 120;

        //const element_controls = document.getElementById(this.videoControlsId);
        //element_controls.style.display = 'none';

        const element = document.getElementById(this.videoRecorderId);
        this.resetToStartRecord(element);

        let i = 3;
        const timer = document.getElementById(this.videoTimerId);
        if (timer) {
            timer.style.display = 'block';
            timer.innerHTML = i;
        }

        let interval = setInterval(() => {
            i--;

            if (timer) {
                timer.innerHTML = i;
            }

            if (i == 0) {
                this.setState({
                    isRecordingVideo: true,
                    isCountingDown: false,
                    timeRemaining: defaultSeconds
                });

                if (timer) {
                    timer.innerHTML = '';
                }
                //console.log('STARTING TO RECORD')
                clearInterval(interval);

                const camera = element.srcObject;

                this.state.recordVideo = RecordRTC(camera, { type: 'video' });
                this.state.recordVideo.startRecording();

                if (timer) {
                    timer.style.display = 'none';
                }

                this.startRecordingTimer();
            }
        }, 1000);
    }

    decreaseTimeRemaining() {
        console.log('decreaseTimeRemaining');
        if (this.state.timeRemaining === 0) {
            this.stopRecord();
        }

        this.setState(prevState => ({ ...prevState, timeRemaining: prevState.timeRemaining - 1 }));
    }

    stopRecord() {
        if (this.startRecordingTimerInterval) {
            clearInterval(this.startRecordingTimerInterval);
            console.log('clear startRecordingTimerInterval');
        } else {
            console.log('NOT FOUND clear startRecordingTimerInterval');
        }

        this.setState({ isRecordingVideo: false });

        //const element_controls = document.getElementById(this.videoControlsId);
        //element_controls.style.display = 'block';

        const element_timer = document.getElementById(this.videoTimerId);
        if (element_timer) {
            element_timer.style.display = 'none';
        }

        this.state.recordVideo.stopRecording(() => {
            this.setState({ hasRecordedVideo: true });

            if (element_timer) {
                element_timer.innerHTML = '';
            }

            const element = document.getElementById(this.videoRecorderId);
            element.src = element.srcObject = null;

            element.src = URL.createObjectURL(this.state.recordVideo.getBlob());
            element.muted = false;
            element.volume = 1;
            element.controls = 1;
            element.autoplay = 0;
            //console.log(element.src)

            this.state.recordVideo.stopRecording();

            this.uploadVideoNotIos();
        });
    }

    uploadFromIOS(file) {
        try {
            const blob = file.slice(0, file.size, 'video/mp4');

            let params = {
                type: 'video/mp4',
                data: blob,
                id: Math.floor(Math.random() * 90000) + 10000
            };
            this.uploadVideo(params);
        } catch (e) {
            helpers.trackError(e);
        }
    }

    prepareToReRecord() {
        // un-set the value so user cant "submit" (if re-recording)
        const videoFileLink = '';
        this.setVideoLinkInAnswer(videoFileLink);

        this.setState({
            isUploadingVideo: false,
            hasUploadedVideo: false,
            hasRecordedVideo: false
        });
    }

    clickRecordMobile() {
        if (this.state.hasRecordedVideo == true) {
            if (!confirm('Delete recording and start over?')) {
                return;
            }
        }
        this.prepareToReRecord();
        document.getElementById('video_file').click();
    }

    uploadVideoNotIos() {
        let params = {
            type: 'video/webm',
            data: this.state.recordVideo.blob,
            id: Math.floor(Math.random() * 90000) + 10000
        };
        this.uploadVideo(params);
    }

    uploadVideo(params) {
        this.setState({ isUploadingVideo: true, uploadVideoError: false });

        this.getSignedUrl(params, signedUrlResponse => {
            var options = {
                headers: {
                    'Content-Type': params.type
                }
            };

            xhr.put(signedUrlResponse.signedUrl, params.data, options)
                .then(ok => {
                    console.log(ok);

                    console.log('uploaded', signedUrlResponse.fileName);

                    this.setVideoLinkInAnswer(signedUrlResponse.fileName);
                    this.setState({ isUploadingVideo: false, hasUploadedVideo: true });
                })
                .catch(error => {
                    this.setState({ isUploadingVideo: false, uploadVideoError: true });
                    helpers.trackError(error);
                    alert('Error, try to upload again.');
                    //this.allowRecord();
                });
            console.log('ready to upload', signedUrlResponse);
        });

        /*S3Upload(params)
            .then((success) => {
                console.log('enter then statement')
                if(success) {
                    console.log(success)
                    this.setState({ uploadSuccess: true, isUploadingVideo: false });
                }
            }, (error) => {
                alert(error, 'error occurred. check your aws settings and try again.')
            }
        )*/
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    componentDidMount() {
        return;

        // old addpipe stuff

        const that = this;
        //console.log(that.props);
        //console.log('this.props', this.props, this.props.read_only)
        if (that.props.addpipe_token && that.props.addpipe_eid && !this.props.editElement && !this.props.read_only) {
            const addPipeLoading = setInterval(() => {
                const addPipeContainerId = this.getAddPipeContainerId();
                const addPipeContainerElement = document.getElementById(addPipeContainerId);
                //console.log('addPipeContainerElement', addPipeContainerElement)

                if (window.PipeSDK && addPipeContainerElement) {
                    let accountHash = that.props.addpipe_token;
                    var pipeParams = {
                        size: { width: 640, height: 390 },
                        qualityurl: 'avq/360p.xml',
                        accountHash: accountHash,
                        eid: that.props.addpipe_eid,
                        mrt: 120,
                        cornerradius: 4
                    };

                    PipeSDK.insert(addPipeContainerId, pipeParams, function(recorderObject) {
                        recorderObject.onReadyToRecord = function() {
                            console.log('ready to record!!!');
                        };

                        recorderObject.onSaveOk = function(
                            recorderId,
                            streamName,
                            streamDuration,
                            cameraName,
                            micName,
                            audioCodec,
                            videoCodec,
                            fileType,
                            videoId,
                            audioOnly,
                            location
                        ) {
                            //onSaveOK is part of the desktop recorder's JS events API (recording from camera or screen)
                            const link = 'https://' + location + '/' + accountHash + '/' + streamName + '.mp4';
                            that.setVideoLinkInAnswer(link);
                        };
                        recorderObject.onVideoUploadSuccess = function(
                            recorderId,
                            filename,
                            filetype,
                            videoId,
                            audioOnly,
                            location
                        ) {
                            //onVideoUploadSuccess is part of the native mobile recorder's JS events API (upload new or existing recording)
                            const link = 'https://' + location + '/' + accountHash + '/' + streamName + '.mp4';
                            that.setVideoLinkInAnswer(link);
                        };
                        recorderObject.onDesktopVideoUploadSuccess = function(
                            recorderId,
                            filename,
                            filetype,
                            videoId,
                            audioOnly,
                            location
                        ) {
                            //onDesktopVideoUploadSuccess is part of the desktop recorder's JS events API (upload existing file from desktop)
                            const link = 'https://' + location + '/' + accountHash + '/' + streamName + '.mp4';
                            that.setVideoLinkInAnswer(link);
                        };
                    });
                    clearInterval(addPipeLoading);
                    console.log('ADDPIPE FOUND');
                } else {
                    console.log('ADDPIPE not found');
                }
            }, 100);
        }
    }

    setVideoLinkInAnswer(link) {
        //document.getElementById(addPipeContainerId).value = link;
        try {
            this.inputField.current.value = link;

            if (this.props.handleChange) {
                //console.log('fire handlechange')
                this.props.handleChange();
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    getSignedUrl(file, callback) {
        if (file) {
            console.log('getting preseign url');
            services
                .getAwsSignedUrlForScreenerVideo(file.type)
                .then(response => {
                    //console.log(response);
                    callback(response);
                })
                .catch(error => {
                    alert('Upload error, please try again.');
                    this.setState({ isUploadingVideo: false });
                    helpers.trackError(error);
                });
        } else {
            /*
            addToast('File not found', {
                appearance: 'error',
                autoDismiss: false
            });
            */
            //setBulkImportProcessing(false);
        }
    }

    render() {
        const props = {};
        props.type = 'text';
        props.className = 'form-control';
        props.name = this.props.data.field_name;
        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        if (this.props.read_only || this.props.is_disabled) {
            props.disabled = 'disabled';
        }
        //console.log('this.props.read_only', this.props.read_only)
        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        //console.log('should_show', should_show)
        //console.log('textinput', this.props.data.label, should_show, this.props.all_questions)
        if (should_show || this.props.editElement || this.props.read_only) {
            //console.log('this.props.defaultValue', this.props.defaultValue)
            const video_link = helpersScreener.getVideoLinkFromScreenerAnswer(this.props.defaultValue);
            const { h, m, s } = helpers.secondsToTime(this.state.timeRemaining);

            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                {this.props.read_only ? (
                                    <>
                                        {video_link ? (
                                            <a href={video_link} target="_blank">
                                                View video
                                            </a>
                                        ) : (
                                            'No video'
                                        )}
                                    </>
                                ) : (
                                    <div>
                                        <div id={this.getAddPipeContainerId()}></div>

                                        {helpers.is_iOS() ? (
                                            <>
                                                <Button
                                                    type="button"
                                                    variant={
                                                        this.state.hasUploadedVideo == true ? 'secondary' : 'primary'
                                                    }
                                                    onClick={this.clickRecordMobile}
                                                >
                                                    {this.state.hasUploadedVideo == true ? (
                                                        <>
                                                            <FiRotateCcw /> Re-record
                                                        </>
                                                    ) : (
                                                        <>
                                                            <FiVideo /> Record
                                                        </>
                                                    )}
                                                </Button>

                                                {this.state.hasUploadedVideo == true && (
                                                    <Box className="green">
                                                        <FiCheckCircle /> Video successfully uploaded
                                                    </Box>
                                                )}

                                                {this.state.uploadVideoError == true && (
                                                    <Box className="red">Error during uploading, please try again</Box>
                                                )}

                                                {this.state.isUploadingVideo == true && (
                                                    <div className="yellow">Uploading...</div>
                                                )}

                                                <input
                                                    id="video_file"
                                                    aria-labelledby={getElementLabel(this.props.data.id)}
                                                    type="file"
                                                    style={{ display: 'none' }}
                                                    accept="video/*"
                                                    capture="user"
                                                    onInput={e => this.uploadFromIOS(e.target.files[0])}
                                                />
                                            </>
                                        ) : (
                                            <div id={this.videoWrapperId} className={'video-rec-wrapper'}>
                                                <div id={this.videoTimerId} className="video-rec-timer"></div>
                                                <div>
                                                    <video
                                                        id={this.videoRecorderId}
                                                        autoPlay
                                                        style={{
                                                            width: '100%',
                                                            height: '360px'
                                                        }}
                                                    />
                                                </div>
                                                <div id={this.videoControlsId} className="video-rec-controls">
                                                    <>
                                                        {this.state.hasRecordedVideo == true ? (
                                                            <>
                                                                {this.state.isUploadingVideo ? (
                                                                    <div className="yellow">Uploading...</div>
                                                                ) : (
                                                                    <>
                                                                        <Button
                                                                            variant="secondary"
                                                                            mr={isMobile ? 0 : 3}
                                                                            mb={isMobile ? 3 : 0}
                                                                            className="secondary-white"
                                                                            onClick={() => {
                                                                                if (
                                                                                    confirm(
                                                                                        'Delete recording and start over?'
                                                                                    )
                                                                                ) {
                                                                                    this.allowRecord();
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FiRotateCcw /> Re-record
                                                                        </Button>
                                                                        {this.state.uploadVideoError == true && (
                                                                            <Button
                                                                                variant="primary"
                                                                                mr={0}
                                                                                onClick={this.uploadVideoNotIos}
                                                                            >
                                                                                <FiUploadCloud /> Upload Video
                                                                            </Button>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {this.state.isRecordingVideo == true ? (
                                                                    <>
                                                                        <span className="red">
                                                                            {h ? h + 'hour' : ''} {m ? m + 'min' : ''}{' '}
                                                                            {s}sec remaining
                                                                        </span>
                                                                        {isMobile ? (
                                                                            <>
                                                                                <br />
                                                                                <br />
                                                                            </>
                                                                        ) : (
                                                                            ''
                                                                        )}
                                                                        <Button
                                                                            variant="secondary"
                                                                            className="secondary-white"
                                                                            ml={isMobile ? 0 : 3}
                                                                            mr={0}
                                                                            onClick={this.stopRecord}
                                                                        >
                                                                            <div className="recording-square"></div>{' '}
                                                                            Stop Recording
                                                                        </Button>
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {this.state.permissionAllowed == false && (
                                                                            <>
                                                                                <Button
                                                                                    variant="primary"
                                                                                    onClick={this.allowRecord}
                                                                                    mr={0}
                                                                                >
                                                                                    <FiMonitor /> Allow To Record
                                                                                </Button>
                                                                                <Box className="white fs-12" mt={3}>
                                                                                    We need <strong>temporary</strong>{' '}
                                                                                    permission to access your camera and
                                                                                    microphone to record your answer.
                                                                                    <br />
                                                                                    Please click <strong>
                                                                                        Allow
                                                                                    </strong>{' '}
                                                                                    when prompted by your browser.
                                                                                </Box>
                                                                            </>
                                                                        )}
                                                                        {this.state.permissionAllowed == true &&
                                                                            this.state.isCountingDown == false && (
                                                                                <Button
                                                                                    variant="secondary"
                                                                                    className="secondary-white"
                                                                                    onClick={this.startRecord}
                                                                                >
                                                                                    <div className="recording-circle"></div>{' '}
                                                                                    Start Recording
                                                                                </Button>
                                                                            )}
                                                                        {this.state.isCountingDown == true && (
                                                                            <div className="green">Get ready...</div>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>

                                                    {this.state.hasUploadedVideo == true && (
                                                        <Box className="green" mt={2}>
                                                            <FiCheckCircle /> Video successfully uploaded
                                                        </Box>
                                                    )}
                                                </div>
                                            </div>
                                        )}

                                        {/*uploadProgressNum*/}

                                        <input
                                            aria-labelledby={getElementLabel(this.props.data.id)}
                                            hidden
                                            {...props}
                                        />
                                        <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class FileUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            uploadProcessing: false,
            uploadComplete: false,
            uploadMaxFiles: false,
            fileStore: []
        };
        this.inputField = React.createRef();
        this.screenerFileUploadButton = this.screenerFileUploadButton.bind(this);
        this.cancelFileUpload = this.cancelFileUpload.bind(this);
        this.uploaded_files = [];
    }

    getAutocompleteAttribute() {
        //return 'name';
    }

    getAddPipeContainerId() {
        return this.props.data.id + '-fileupload-container';
    }

    componentDidMount() {}

    saveFileLink() {
        //document.getElementById(addPipeContainerId).value = link;
        console.log('setting value!!', this.uploaded_files);

        let new_value = [];
        this.uploaded_files.forEach(file => {
            new_value.push(file.remote_name);
        });

        this.inputField.current.value = new_value.length ? JSON.stringify(new_value) : null;
        console.log('this.inputField.current.value', this.inputField.current.value);
        if (this.props.handleChange) {
            this.props.handleChange();
        }
    }

    screenerFileUploadButton() {
        document.getElementById('s_file').click();
        document.getElementById('s_file').onchange = e => {
            this.setState({ uploadProcessing: true, uploadComplete: false });

            const file = e.target.files[0];

            this.getSignedUrl(file, signedUrlResponse => {
                var options = {
                    headers: {
                        'Content-Type': file.type
                    }
                };

                xhr.put(signedUrlResponse.signedUrl, file, options)
                    .then(ok => {
                        console.log('uploaded file', signedUrlResponse.fileName);

                        this.uploaded_files.push({
                            remote_name: signedUrlResponse.fileName,
                            original_name: file.name
                        });

                        this.setState(
                            {
                                uploadProcessing: false,
                                uploadComplete: true,
                                fileStore: this.uploaded_files
                            },
                            () => {
                                this.saveFileLink();
                            }
                        );
                        console.log('this.props.data.upload_files_cnt', this.props.data.upload_files_cnt);
                        if (
                            this.uploaded_files.length >= this.props.data.upload_files_cnt ||
                            this.props.data.upload_files_cnt === undefined
                        ) {
                            this.setState({
                                uploadMaxFiles: true
                            });
                        }
                    })
                    .catch(error => {
                        this.setState({
                            uploadProcessing: false
                        });

                        const errorText = services.parseAndTrackXhrErrors(error);

                        alert(errorText);
                    });
                console.log('ready to upload', signedUrlResponse);
            });

            //console.log(this.props.auth);

            /*

            const formData = new FormData();
            formData.append('s_file', file);

            xhr.post(`/screener-upload-file`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(response => {
                const s_file = response.data;
                this.saveFileLink(s_file);
                this.setState({
                    uploadProcessing: false,
                    uploadComplete: true
                });
            }).catch(error => {

                this.setState({
                    uploadProcessing: false
                });

                const errorText = services.parseAndTrackXhrErrors(error);

                alert(errorText);
            });*/
        };
    }

    cancelFileUpload(delete_remote_name) {
        const index = this.uploaded_files.forEach((file, index) => {
            if (file && file.remote_name == delete_remote_name) {
                this.uploaded_files.splice(index, 1);
            }
        });

        this.saveFileLink();

        this.setState({
            fileStore: this.uploaded_files,
            uploadMaxFiles: false
        });
    }

    getSignedUrl(file, callback) {
        if (file) {
            console.log('getting preseign file upload url');
            const file_extension = file.name ? file.name.split('.').pop() : '';
            services
                .getAwsSignedUrlForScreenerFile(file_extension)
                .then(response => {
                    //console.log(response);
                    callback(response);
                })
                .catch(error => {
                    alert('Upload error, please try again.');
                    this.setState({ uploadProcessing: false });
                    helpers.trackError(error);
                });
        } else {
            /*
            addToast('File not found', {
                appearance: 'error',
                autoDismiss: false
            });
            */
            //setBulkImportProcessing(false);
        }
    }

    render() {
        const props = {};
        props.type = 'text';
        props.className = 'form-control';
        props.name = this.props.data.field_name;
        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        if (this.props.read_only || this.props.is_disabled) {
            props.disabled = 'disabled';
        }
        //console.log('this.props.read_only', this.props.read_only)
        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        //console.log('should_show', should_show)
        //console.log('textinput', this.props.data.label, should_show, this.props.all_questions)
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                {this.props.read_only ? (
                                    <>
                                        {this.props.defaultValue
                                            ? helpers.renderLinksForUploadedScreenerFile(this.props.defaultValue)
                                            : ''}
                                    </>
                                ) : (
                                    <Box mt={3}>
                                        {this.state.fileStore.length > 0 == true && (
                                            <Box mb={3} className="green medium">
                                                {this.state.fileStore.map((file, idx) => {
                                                    return (
                                                        <div className="upload-file__wrapper" key={idx}>
                                                            <span>
                                                                <FiCheckCircle
                                                                    style={{
                                                                        verticalAlign: 'top',
                                                                        margin: '4px 4px 0 0'
                                                                    }}
                                                                />
                                                                {file.original_name}
                                                            </span>
                                                            <span>
                                                                <button
                                                                    onClick={() =>
                                                                        this.cancelFileUpload(file.remote_name)
                                                                    }
                                                                >
                                                                    <FiTrash2 style={{ color: 'red' }} />
                                                                </button>
                                                            </span>
                                                        </div>
                                                    );
                                                })}
                                            </Box>
                                        )}
                                        {this.state.uploadMaxFiles ? null : (
                                            <Button
                                                type="button"
                                                variant={
                                                    this.state.uploadComplete == true ? 'secondary-gray' : 'primary'
                                                }
                                                onClick={this.screenerFileUploadButton}
                                                disabled={this.state.uploadProcessing}
                                                mr={2}
                                            >
                                                {this.state.uploadProcessing ? (
                                                    'Uploading...'
                                                ) : (
                                                    <>
                                                        <FiUploadCloud /> Upload file
                                                    </>
                                                )}
                                            </Button>
                                        )}
                                        <Box mt={2}>Max file size is 10MB.</Box>
                                        <input
                                            aria-labelledby={getElementLabel(this.props.data.id)}
                                            type="file"
                                            id="s_file"
                                            hidden
                                        />
                                        <input
                                            aria-labelledby={getElementLabel(this.props.data.id)}
                                            hidden
                                            {...props}
                                        />

                                        <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                                    </Box>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class HiddenValue extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();

        //console.log(props)
        this.state = {
            value: props.defaultValue !== undefined ? props.defaultValue : props.data.default_value
        };
    }

    getAutocompleteAttribute() {
        //return 'name';
    }

    render() {
        const props = {};
        props.type = 'hidden';
        props.className = 'form-control';
        props.name = this.props.data.field_name;
        if (this.props.mutable) {
            //props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        if (this.props.read_only || this.props.is_disabled) {
            props.disabled = 'disabled';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        //console.log('should_show', should_show)
        //console.log('textinput', this.props.data.label, should_show, this.props.all_questions)
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses} style={{ padding: 0, border: 0 }}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <Input
                                    {...props}
                                    required={this.props.data.required}
                                    value={this.state.value}
                                    onChange={e => {
                                        this.setState({ value: e.target.value });
                                        if (this.props.handleChange) {
                                            this.props.handleChange();
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class TextInput extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
    }

    getAutocompleteAttribute() {
        //return 'name';
    }

    render() {
        const props = {};
        props.type = 'text';
        props.className = 'form-control';
        props.name = this.props.data.field_name;
        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        if (this.props.read_only || this.props.is_disabled) {
            props.disabled = 'disabled';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        //console.log('should_show', should_show)
        //console.log('textinput', this.props.data.label, should_show, this.props.all_questions)
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <Input
                                    mt={this.props.isPopup ? undefined : 3}
                                    {...props}
                                    aria-labelledby={getElementLabel(this.props.data.id)}
                                    placeholder="Your answer"
                                    required={this.props.data.required}
                                    autoComplete={this.getAutocompleteAttribute()}
                                    onChange={e => {
                                        if (this.props.handleChange) {
                                            this.props.handleChange(e);
                                        }
                                    }}
                                />
                                <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class NumberInput extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
    }

    render() {
        const props = {};
        props.className = 'form-control';
        props.name = this.props.data.field_name;

        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        if (this.props.read_only) {
            props.disabled = 'disabled';
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        console.log('should_show NUMBER', should_show);
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <Input
                                    type="number"
                                    aria-labelledby={getElementLabel(this.props.data.id)}
                                    mt={this.props.isPopup ? '4px' : 3}
                                    {...props}
                                    onChange={e => {
                                        //console.log('changing??')
                                        if (this.props.handleChange) {
                                            this.props.handleChange(e);
                                        }
                                    }}
                                />
                                <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class TextArea extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
    }

    render() {
        const props = {};
        props.className = 'form-control';
        props.name = this.props.data.field_name;

        if (this.props.read_only) {
            props.disabled = 'disabled';
        }

        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <TextAreaAutosize
                                    {...props}
                                    type="text"
                                    aria-labelledby={getElementLabel(this.props.data.id)}
                                    style={{ width: '100%', marginTop: this.props.isPopup ? '4px' : '8px' }}
                                    minRows={4}
                                    className="theme-input"
                                    placeholder="Your answer"
                                    onChange={e => {
                                        if (this.props.handleChange) {
                                            this.props.handleChange(e);
                                        }
                                    }}
                                />
                                <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Dropdown extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
    }

    render() {
        const props = {};
        props.className = 'form-control';
        props.name = this.props.data.field_name;

        if (this.props.mutable) {
            props.defaultValue = this.props.defaultValue;
            props.ref = this.inputField;
        }

        if (this.props.read_only) {
            props.disabled = 'disabled';

            // we do this on read-only because sometimes the options are dynamic, and get upated,
            // but defaultValue only works on the first render
            props.value = this.props.defaultValue;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
                this.props.data,
                this.props.all_questions,
                this.props.answers_by_form_element_name
            );
            //console.log('DD props?', this.props.data, all_options, this.props.answers_by_form_element_name)
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <select
                                    {...props}
                                    aria-labelledby={getElementLabel(this.props.data.id)}
                                    style={{ maxWidth: '100%' }}
                                    onChange={e => {
                                        if (this.props.handleChange) {
                                            this.props.handleChange(e);
                                        }
                                    }}
                                >
                                    <option value="" key="">
                                        Choose
                                    </option>
                                    {all_options.map(option => {
                                        const this_key = `${option.key}`;
                                        return (
                                            <option value={option.value} key={this_key}>
                                                {helpersScreener.insertPiping(
                                                    option.text,
                                                    this.props.all_questions,
                                                    this.props.answers_by_form_element_name
                                                )}
                                                {option.hasOwnProperty('qualification') &&
                                                (this.props.read_only || this.props.show_qualification)
                                                    ? ` [${option.qualification}]`
                                                    : ''}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Signature extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultValue: props.defaultValue
        };
        this.inputField = React.createRef();
        this.canvas = React.createRef();
    }

    clear = () => {
        if (this.state.defaultValue) {
            this.setState({ defaultValue: '' });
        } else if (this.canvas.current) {
            this.canvas.current.clear();
        }
    };

    render() {
        const { defaultValue } = this.state;
        let canClear = !!defaultValue;
        const props = {};
        props.type = 'hidden';
        props.name = this.props.data.field_name;

        if (this.props.mutable) {
            props.defaultValue = defaultValue;
            props.ref = this.inputField;
        }
        const pad_props = {
            backgroundColor: '#fff',
            canvasProps: {
                width: isMobile ? window.innerWidth - 32 * 2 - 24 * 2 : 600,
                height: isMobile ? 200 : 200
            }
        };
        // umd requires canvasProps={{ width: 400, height: 150 }}
        if (this.props.mutable) {
            pad_props.defaultValue = defaultValue;
            pad_props.ref = this.canvas;
            canClear = !this.props.read_only;
            const parent_props = this.props;
            pad_props.onEnd = () => {
                //console.log('ending!')
                if (parent_props.handleChange) {
                    //console.log('fire handlechange')
                    parent_props.handleChange();
                }
            };
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let sourceDataURL;
        if (defaultValue && defaultValue.length > 0) {
            sourceDataURL = `data:image/png;base64,${defaultValue}`;
        }

        const signatureLineCss = {
            position: 'absolute',
            bottom: '32px',
            right: '32px',
            left: '32px',
            height: '1px',
            borderTop: '1px dashed #777'
        };

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                {this.props.read_only === true || !!sourceDataURL ? (
                                    <img src={sourceDataURL} />
                                ) : (
                                    <span
                                        className={'border rounded inline-block relative'}
                                        style={{ overflow: 'hidden' }}
                                    >
                                        <SignaturePad {...pad_props} />
                                        <div style={signatureLineCss}></div>
                                    </span>
                                )}
                                <Box mt={3}>
                                    {canClear && (
                                        <Button type="button" variant="secondary-gray" onClick={this.clear}>
                                            Clear Signature
                                        </Button>
                                    )}
                                </Box>
                                <input {...props} />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        const { defaultValue, data } = props;
        this.state = { value: this.getDefaultValue(defaultValue, data.options) };
    }

    getDefaultValue(defaultValue, options) {
        if (defaultValue) {
            if (typeof defaultValue === 'string') {
                const vals = defaultValue.split(',').map(x => x.trim());
                return options.filter(x => vals.indexOf(x.value) > -1);
            }
            return options.filter(x => defaultValue.indexOf(x.value) > -1);
        }
        return [];
    }

    // state = { value: this.props.defaultValue !== undefined ? this.props.defaultValue.split(',') : [] };

    handleChange = e => {
        this.setState({ value: e });
    };

    render() {
        const options = this.props.data.options.map(option => {
            option.label = option.text;
            return option;
        });
        const props = {};
        props.isMulti = true;
        props.name = this.props.data.field_name;
        props.onChange = this.handleChange;

        props.options = options;
        if (!this.props.mutable) {
            props.value = options[0].text;
        } // to show a sample of what tags looks like
        if (this.props.mutable) {
            props.isDisabled = this.props.read_only;
            props.value = this.state.value;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <Select {...props} />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Checkboxes extends React.Component {
    constructor(props) {
        super(props);
        this.options = {};
        this.lastOptionsProp = null;
        this.isNoneOfTheAboveRef = React.createRef();
    }

    /**
     * Generate option key
     *
     * @param {string} optionKey - option key
     * @returns {string}
     */
    generateOptionKey(optionKey) {
        return `option_${optionKey}`;
    }

    /**
     * Generate input key
     *
     * @param {string} optionKey - option key
     * @returns {string}
     */
    generateInputKey(optionKey) {
        return `input_${optionKey}`;
    }

    /**
     * Generate ref key
     *
     * @param {string} optionKey - option key
     * @returns {string} - ref key
     */
    generateRefKey(optionKey) {
        return `child_ref_${optionKey}`;
    }

    getElementIdFromParentProps(parentProps) {
        return parentProps && parentProps.data && parentProps.data.id ? parentProps.data.id : '';
    }

    /**
     * Get none of the above option
     *
     * @returns {any}
     */
    getNoneOfTheAboveOption() {
        return this.props.data.options.find(option => option.is_none_of_the_above);
    }

    /**
     * Clear all options if none of the above is checked
     */
    clearAboveOptions() {
        const noneOfTheAboveOption = this.getNoneOfTheAboveOption();

        if (!noneOfTheAboveOption) return;

        const noneOfTheAboveOptionRefKey = this.generateRefKey(noneOfTheAboveOption.key);

        // clear all other options
        Object.keys(this.options).forEach(optionRefKey => {
            if (optionRefKey !== noneOfTheAboveOptionRefKey) {
                this.options[optionRefKey].checked = false;
            }
        });
    }

    /**
     * Clear none of the above option if any other option is checked
     */
    clearNoneOfTheAbove() {
        const noneOfTheAboveOption = this.getNoneOfTheAboveOption();

        if (!noneOfTheAboveOption) return;

        const noneOfTheAboveOptionRefKey = this.generateRefKey(noneOfTheAboveOption.key);
        const noneOfTheAboveOptionRef = this.options[noneOfTheAboveOptionRefKey];

        // if none of the above is checked and any other option is checked, uncheck none of the above
        if (!noneOfTheAboveOptionRef || !noneOfTheAboveOptionRef.checked || this.checkedCount === 0) return;

        // uncheck none of the above
        noneOfTheAboveOptionRef.checked = false;
    }

    /**
     * Get a value that checks if the question has a "None of the above" option
     * Function memorizes the result to avoid unnecessary re-calculations
     *
     * @returns {boolean} - true if the question has a "None of the above" option
     */
    get isNoneOfTheAbove() {
        if (this.isNoneOfTheAboveRef.current == null || !isEqual(this.props.data.options, this.lastOptionsProp)) {
            this.isNoneOfTheAboveRef.current = !!this.getNoneOfTheAboveOption();
            this.lastOptionsProp = this.props.data.options;
        }

        return this.isNoneOfTheAboveRef.current;
    }

    renderOptions(parentProps) {
        let classNames = 'custom-control custom-checkbox';
        if (parentProps.data.inline) {
            classNames += ' option-inline';
        }

        const numColumns = parentProps.data.num_columns ? parentProps.data.num_columns : 1;
        let columns = {};
        for (let i = 1; i <= numColumns; i++) {
            columns[i] = [];
        }

        let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
            parentProps.data,
            parentProps.all_questions,
            parentProps.answers_by_form_element_name
        );

        const elementsPerColumn = Math.ceil(all_options.length / numColumns);
        // 6 / 2 = 3
        // 7 / 2 = 3.5

        //console.log('...columns', columns)
        let columnCounter = 1;
        let elementCounter = 1;

        all_options.forEach(option => {
            const this_key = `${option.key}`;
            const props = {};
            props.name = this.generateOptionKey(option.key);
            props.type = 'checkbox';
            props.value = option.value;
            //console.log('chx', parentProps.defaultValue, props)
            if (parentProps.mutable) {
                props.defaultChecked = false;

                let defaultValueArray = [];
                if (helpers.isJsonString(parentProps.defaultValue)) {
                    defaultValueArray = JSON.parse(parentProps.defaultValue);
                } else if (Array.isArray(parentProps.defaultValue)) {
                    defaultValueArray = parentProps.defaultValue;
                }

                //console.log(option.value, defaultValueArray, parentProps.defaultValue)
                if (
                    defaultValueArray !== undefined &&
                    Array.isArray(defaultValueArray) &&
                    defaultValueArray.indexOf(option.key) > -1
                ) {
                    props.defaultChecked = true;
                } else if (
                    defaultValueArray !== undefined &&
                    Array.isArray(defaultValueArray) &&
                    defaultValueArray.indexOf(option.value) > -1
                ) {
                    props.defaultChecked = true;
                }
            }
            if (this.props.read_only) {
                props.disabled = 'disabled';
            }

            let element_add_other;
            let props_add_other = {};
            if (option.is_add_other) {
                props_add_other = {
                    name: this.generateInputKey(option.key),
                    type: 'input',
                    value: parentProps.defaultValueOther
                };

                if (this.props.read_only) {
                    props_add_other.disabled = 'disabled';
                }

                element_add_other = (
                    <Box>
                        <label htmlFor={this_key}>
                            <div>
                                {parentProps.data.other_specify_label ? (
                                    <div style={{ marginBottom: '4px' }}>{parentProps.data.other_specify_label}</div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <Input
                                width={200}
                                {...props_add_other}
                                aria-labelledby={getElementLabel(parentProps.data.id)}
                                placeholder=""
                                onClick={() => {
                                    // when user clicks on the input of OTHER, make sure to checkmark the value
                                    const els = document.getElementsByName(props.name);
                                    if (els.length == 1) {
                                        const el = els[0];
                                        if (!el.checked) {
                                            el.click();
                                        }
                                    }
                                }}
                            />
                        </label>{' '}
                    </Box>
                );
            }

            let element_none_of_the_above;
            let props_none_of_the_above = {};
            if (option.is_none_of_the_above) {
                props_none_of_the_above = {
                    name: this.generateInputKey(option.key),
                    type: 'input',
                    value: 1
                };

                if (this.props.read_only) {
                    props_none_of_the_above.disabled = 'disabled';
                }

                element_none_of_the_above = (
                    <label className="custom-control-label" htmlFor={this_key}>
                        {parentProps.data.none_above_specify_label || 'None of the above'}

                        {option.hasOwnProperty('qualification') &&
                        this.props.show_qualification &&
                        option.qualification ? (
                            <Badge size="sm" style={{ marginLeft: '12px' }} type="pure">
                                {option.qualification}
                            </Badge>
                        ) : null}
                    </label>
                );
            }

            //console.log('columnCounter', columnCounter);
            columns[columnCounter].push(
                <Box key={this_key}>
                    <Flex sx={{ gridGap: 2 }} mt={1} fontSize={2} className={classNames}>
                        <Box
                            sx={{
                                width: '16px',
                                flexShrink: 0,
                                display: 'flex',
                                alignItems: 'start',
                                marginTop: '6px'
                            }}
                        >
                            <input
                                id={this_key}
                                className={`custom-control-input ${this.getElementIdFromParentProps(parentProps)}`}
                                ref={c => {
                                    if (c && parentProps.mutable) {
                                        const refKey = this.generateRefKey(option.key);

                                        if (!this.options[refKey]) {
                                            // NOTE: We can't assign it to a reference, because some logic outside this component must access it
                                            this.options[refKey] = c;
                                        }
                                    }
                                }}
                                onChange={e => {
                                    // When none of the above is set we want to add extra logic to improve UX
                                    if (this.isNoneOfTheAbove) {
                                        if (option.is_none_of_the_above && e.target.checked) {
                                            this.clearAboveOptions();
                                        } else {
                                            this.clearNoneOfTheAbove();
                                        }
                                    }

                                    if (this.props.handleChange) {
                                        this.props.handleChange(e);
                                    }
                                }}
                                {...props}
                            />
                        </Box>
                        <Box sx={{ flexGrow: 1 }}>
                            {isNonStandardOption(option) ? (
                                renderNonStandardOption(option, element_add_other, element_none_of_the_above)
                            ) : (
                                <>
                                    <label className="custom-control-label" htmlFor={this_key}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: helpersScreener.insertPiping(
                                                    option.text,
                                                    this.props.all_questions,
                                                    this.props.answers_by_form_element_name
                                                )
                                            }}
                                            className="option-label__tag"
                                        ></div>
                                        {option.hasOwnProperty('qualification') &&
                                        this.props.show_qualification &&
                                        option.qualification ? (
                                            <Badge size="sm" style={{ marginLeft: '12px' }} type="pure">
                                                {option.qualification}
                                            </Badge>
                                        ) : null}
                                    </label>
                                </>
                            )}
                            {option.image && (
                                <label htmlFor={this_key}>
                                    <img src={option.image} style={{ maxWidth: '320px' }} />
                                </label>
                            )}
                        </Box>
                    </Flex>
                </Box>
            );

            elementCounter++;
            if (elementCounter > elementsPerColumn) {
                columnCounter++;
                elementCounter = 1;
            }
        });

        //console.log('elementsPerColumn', elementsPerColumn, 'columns', columns);

        let elements = [];
        // each column is a flexbox of width 1/numColumns
        Object.keys(columns).forEach(key => {
            elements.push(
                <Flex width={[1, 1 / numColumns]} key={key} sx={{ flexDirection: 'column' }}>
                    {columns[key]}
                </Flex>
            );
        });

        return elements;
    }

    /**
     * Get the number of checked options
     *
     * @returns {number}
     */
    get checkedCount() {
        return Object.keys(this.options).filter(key => this.options[key].checked).length;
    }

    /**
     * Get error message based on validation rules
     */
    get errorMessage() {
        if (!this.props.data.validation || !this.props.data.validation_rules) return null;

        // Don't display an error if checkboxes are not touched
        if (this.checkedCount === 0) return null;

        for (const rule of this.props.data.validation_rules) {
            if (rule.validation_type === VALIDATION_MINIMUM && this.checkedCount < rule.validation_value) {
                return rule.validation_message || `Please select at least ${rule.validation_value} options.`;
            }

            if (rule.validation_type === VALIDATION_MAXIMUM && this.checkedCount > rule.validation_value) {
                return rule.validation_message || `Please select at most ${rule.validation_value} options.`;
            }

            if (rule.validation_type === VALIDATION_EXACT && this.checkedCount !== +rule.validation_value) {
                return rule.validation_message || `Please select exactly ${rule.validation_value} options.`;
            }

            // If no validation type is set, we assume that exactly one option should be selected
            // Check out: validation_types.push({ title: 'Select One', value: '' });
            if (rule.validation_type === '' && this.checkedCount !== 1) {
                return 'Please select exactly 1 option.';
            }
        }

        return null;
    }

    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );

        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <Flex style={{ width: '100%', flexWrap: 'wrap' }}>
                                    {this.renderOptions(this.props)}
                                </Flex>
                                {this.errorMessage && (
                                    <Badge style={{ marginTop: '16px' }} type="danger">
                                        <FiAlertCircle /> {this.errorMessage}
                                    </Badge>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

const matrixLabelAccessabilityStyles = {
    visibility: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0
};

class Matrix extends React.Component {
    constructor(props) {
        super(props);
        this.options = {};
    }

    getOptionId(this_key, column_key) {
        return this_key + '_' + column_key;
    }

    render() {
        const self = this;
        // let classNames = 'custom-control custom-matrix';
        let classNames = '';
        if (this.props.data.inline) {
            classNames += ' option-inline';
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
            this.props.data,
            this.props.all_questions,
            this.props.answers_by_form_element_name
        );

        //console.log('MATRIX' ,all_options, this.props.answers_by_form_element_name)

        const rows = all_options.filter(o => o.type == 'row');
        const columns = all_options.filter(o => o.type == 'column');

        const renderColumns = (
            <>
                <Box className="sticky z-1 bg-white" style={{ left: 0 }}></Box>
                {columns.map(c => (
                    <Box key={c.text} className="nowrap center" style={{ padding: '8px', minWidth: 'auto' }}>
                        {helpersScreener.insertPiping(
                            c.text,
                            this.props.all_questions,
                            this.props.answers_by_form_element_name
                        )}
                    </Box>
                ))}
            </>
        );

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <>
                            <ComponentHeader {...this.props} />
                            <div className="matrix-container">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <div
                                    style={{
                                        gridTemplateColumns: `minmax(max-content, 200px) repeat(${columns.length}, minmax(max-content, 140px))`,
                                        overflowX: 'auto'
                                    }}
                                    className={`matrix-parent grid relative w-full ${helpersScreener.getInputParentClassName(
                                        this.props.data.field_name
                                    )}`}
                                >
                                    {isMobile ? null : renderColumns}

                                    {rows.map((row, index) => {
                                        const this_key = `${row.key}`;

                                        return (
                                            <React.Fragment key={this_key}>
                                                <Box
                                                    className="sticky z-1"
                                                    style={{
                                                        left: 0,
                                                        background: index % 2 === 0 ? '#f9f9f9' : 'white',
                                                        padding: '4px',
                                                        wordWrap: 'break-word'
                                                    }}
                                                    maxWidth="200px"
                                                    width="100%"
                                                >
                                                    {helpersScreener.insertPiping(
                                                        row.text,
                                                        this.props.all_questions,
                                                        this.props.answers_by_form_element_name
                                                    )}
                                                </Box>
                                                {columns.map(column => {
                                                    const props = {};
                                                    props.name = `option_${row.key}`;
                                                    props.type = helpersScreener.getMatrixType(this.props.data);

                                                    if (self.props.mutable) {
                                                        props.defaultChecked =
                                                            self.props.defaultValue !== undefined &&
                                                            self.props.defaultValue.indexOf(
                                                                row.key + '_' + column.key
                                                            ) > -1;
                                                    }
                                                    if (this.props.read_only) {
                                                        props.disabled = 'disabled';
                                                    }

                                                    const option_id = self.getOptionId(this_key, column.key);
                                                    if (props.type == 'text') {
                                                        const found_text_ans = self.props.defaultValue.find(
                                                            kv => kv.key == option_id
                                                        );
                                                        //console.log(found_text_ans);
                                                        if (found_text_ans) {
                                                            props.defaultValue = found_text_ans.value;
                                                        }
                                                    }

                                                    let BoxStyles = {};
                                                    if (props.type == 'text') {
                                                        BoxStyles.gridGap = 3;
                                                    }
                                                    BoxStyles.textAlign = isMobile ? 'left' : 'center';

                                                    const column_text_html = (
                                                        <label
                                                            htmlFor={self.getOptionId(this_key, column.key)}
                                                            style={{
                                                                margin: props.type == 'text' ? '0' : '-2px 0 0 8px',
                                                                maxWidth: '140px',
                                                                wordWrap: 'break-word',
                                                                ...(!isMobile ? matrixLabelAccessabilityStyles : {})
                                                            }}
                                                        >
                                                            {helpersScreener.insertPiping(
                                                                column.text,
                                                                self.props.all_questions,
                                                                self.props.answers_by_form_element_name
                                                            )}
                                                        </label>
                                                    );

                                                    return (
                                                        <Box
                                                            sx={BoxStyles}
                                                            style={{
                                                                display: 'flex',
                                                                height: '100%',
                                                                width: '100%',
                                                                padding: '4px',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                background: index % 2 === 0 ? '#f9f9f9' : 'white'
                                                            }}
                                                        >
                                                            {props.type == 'text' && column_text_html}
                                                            <input
                                                                id={self.getOptionId(this_key, column.key)}
                                                                //className="custom-control-input"
                                                                ref={refthing => {
                                                                    if (refthing && self.props.mutable) {
                                                                        self.options[
                                                                            `child_ref_${row.key}_${column.key}`
                                                                        ] = refthing;
                                                                    }
                                                                }}
                                                                onChange={e => {
                                                                    if (self.props.handleChange) {
                                                                        self.props.handleChange(e);
                                                                    }
                                                                }}
                                                                className={props.type == 'text' ? 'theme-input' : ''}
                                                                {...props}
                                                            />
                                                            {props.type != 'text' && column_text_html}
                                                        </Box>
                                                    );
                                                })}
                                            </React.Fragment>
                                        );
                                    })}
                                    <div className={SCREENER_QUESTION_ERRORS_CLASSNAME}></div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class RadioButtons extends React.Component {
    constructor(props) {
        super(props);
        this.options = {};
    }

    renderOptions(parentProps) {
        let self = this;

        let classNames = 'custom-control custom-radio';
        if (this.props.data.inline) {
            classNames += ' option-inline';
        }

        const numColumns = parentProps.data.num_columns ? parentProps.data.num_columns : 1;
        let columns = {};
        for (let i = 1; i <= numColumns; i++) {
            columns[i] = [];
        }

        let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
            parentProps.data,
            parentProps.all_questions,
            parentProps.answers_by_form_element_name
        );

        const elementsPerColumn = Math.ceil(all_options.length / numColumns);
        // 6 / 2 = 3
        // 7 / 2 = 3.5

        //console.log('...columns', columns)
        let columnCounter = 1;
        let elementCounter = 1;

        all_options.forEach(option => {
            const this_key = `${option.key}`;
            const props = {};
            props.name = self.props.data.field_name;

            props.type = 'radio';
            props.value = option.value;
            if (self.props.mutable) {
                props.defaultChecked =
                    self.props.defaultValue !== undefined &&
                    (self.props.defaultValue.indexOf(option.key) > -1 ||
                        self.props.defaultValue.indexOf(option.value) > -1);
            }
            if (parentProps.read_only) {
                props.disabled = 'disabled';
            }

            let props_add_other = {};
            if (option.is_add_other) {
                props_add_other = {
                    name: `input_${option.key}`,
                    type: 'input',
                    value: self.props.defaultValueOther
                };

                if (parentProps.read_only) {
                    props_add_other.disabled = 'disabled';
                }
            }

            columns[columnCounter].push(
                <Flex sx={{ gridGap: 2 }} mt={1} fontSize={2} className={classNames} key={this_key}>
                    <Box sx={{ width: '16px', flexShrink: 0, alignItems: 'start', display: 'flex', marginTop: '6px' }}>
                        <input
                            id={this_key}
                            className="custom-control-input"
                            ref={c => {
                                if (c && self.props.mutable) {
                                    self.options[`child_ref_${option.key}`] = c;
                                }
                            }}
                            onChange={e => {
                                if (parentProps.handleChange) {
                                    parentProps.handleChange(e);
                                }
                            }}
                            {...props}
                        />
                    </Box>
                    <Box sx={{ flexGrow: 1 }}>
                        {option.is_add_other ? (
                            <Box>
                                <label htmlFor={this_key}>
                                    <div>
                                        {parentProps.data.other_specify_label ? (
                                            <div style={{ marginBottom: '4px' }}>
                                                {parentProps.data.other_specify_label}
                                            </div>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <Input
                                        width={160}
                                        {...props_add_other}
                                        aria-labelledby={getElementLabel(parentProps.data.id)}
                                        placeholder=""
                                        onClick={() => {
                                            // when user clicks on the input of OTHER, make sure to checkmark the value
                                            const el = document.getElementById(this_key);
                                            if (el) {
                                                el.click();
                                            }
                                        }}
                                    />
                                </label>
                            </Box>
                        ) : (
                            <>
                                <label className="custom-control-label" htmlFor={this_key}>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: helpersScreener.insertPiping(
                                                option.text,
                                                parentProps.all_questions,
                                                parentProps.answers_by_form_element_name
                                            )
                                        }}
                                        className="option-label__tag"
                                    ></div>
                                    {option.hasOwnProperty('qualification') &&
                                    parentProps.show_qualification &&
                                    option.qualification ? (
                                        <Badge size="sm" style={{ marginLeft: '12px' }} type="pure">
                                            {option.qualification}
                                        </Badge>
                                    ) : null}
                                </label>
                            </>
                        )}
                        {option.image && (
                            <label htmlFor={this_key}>
                                <img src={option.image} style={{ maxWidth: '320px' }} />
                            </label>
                        )}
                    </Box>
                </Flex>
            );

            elementCounter++;
            if (elementCounter > elementsPerColumn) {
                columnCounter++;
                elementCounter = 1;
            }
        });

        //console.log('elementsPerColumn', elementsPerColumn, 'columns', columns);

        let elements = [];
        // each column is a flexbox of width 1/numColumns
        Object.keys(columns).forEach(key => {
            elements.push(
                <Flex key={key} width={[1, 1 / numColumns]} sx={{ flexDirection: 'column' }}>
                    {columns[key]}
                </Flex>
            );
        });

        return (
            <fieldset style={{ margin: 0, padding: 0, border: 'none', width: '100%' }}>
                <legend style={{ display: 'none' }}>{parentProps.data.label}</legend>
                {elements}
            </fieldset>
        );
    }

    render() {
        const self = this;

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <Flex style={{ width: '100%', flexWrap: 'wrap' }}>
                                    {this.renderOptions(self.props)}
                                </Flex>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Image extends React.Component {
    render() {
        const style = this.props.data.center ? { textAlign: 'center' } : null;

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses} style={style}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            {!this.props.mutable && (
                                <HeaderBar
                                    parent={this.props.parent}
                                    editModeOn={this.props.editModeOn}
                                    data={this.props.data}
                                    onDestroy={this.props._onDestroy}
                                    onDuplicate={this.props._onDuplicate}
                                    onEdit={this.props.onEdit}
                                    required={this.props.data.required}
                                />
                            )}
                            {this.props.data.src && (
                                <img
                                    src={this.props.data.src}
                                    width={this.props.data.width}
                                    height={this.props.data.height}
                                />
                            )}
                            {!this.props.data.src && <div className="no-image">No Image</div>}
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Rating extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        this.state = {
            value: ''
        };
    }

    render() {
        const props = {};
        const name = this.props.data.field_name;
        props.name = this.props.data.field_name;
        props.ratingAmount = 5;

        if (this.props.mutable) {
            props.rating = this.props.defaultValue !== undefined ? parseFloat(this.props.defaultValue, 10) : 0;
            props.editing = true;
            props.disabled = this.props.read_only;
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                <StarRating
                                    {...props}
                                    rating={this.state.value}
                                    onRatingClick={(e, ratingCache) => {
                                        const value = ratingCache.rating;
                                        this.setState({ value: value });
                                        if (this.props.handleChange) {
                                            this.props.handleChange(e);
                                        }
                                    }}
                                />
                                <input name={name} value={this.state.value} type="hidden" />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class HyperLink extends React.Component {
    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        return (
            <div className={baseClasses}>
                {this.props.editElement ? (
                    this.props.editElement
                ) : (
                    <div>
                        <ComponentHeader {...this.props} />
                        <div className="form-group">
                            <a target="_blank" href={this.props.data.href}>
                                {this.props.data.content}
                            </a>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

class Download extends React.Component {
    render() {
        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <a href={`${this.props.download_path}?id=${this.props.data.file_path}`}>
                                    {this.props.data.content}
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Camera extends React.Component {
    constructor(props) {
        super(props);
        this.state = { img: null };
    }

    displayImage = e => {
        const self = this;
        const target = e.target;
        let file;
        let reader;

        if (target.files && target.files.length) {
            file = target.files[0];
            // eslint-disable-next-line no-undef
            reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onloadend = () => {
                self.setState({
                    img: reader.result
                });
            };
        }
    };

    clearImage = () => {
        this.setState({
            img: null
        });
    };

    render() {
        let baseClasses = 'SortableItem rfb-item';
        const name = this.props.data.field_name;
        const fileInputStyle = this.state.img ? { display: 'none' } : null;
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }
        let sourceDataURL;
        if (this.props.read_only === true && this.props.defaultValue && this.props.defaultValue.length > 0) {
            if (this.props.defaultValue.indexOf(name > -1)) {
                sourceDataURL = this.props.defaultValue;
            } else {
                sourceDataURL = `data:image/png;base64,${this.props.defaultValue}`;
            }
        }
        //console.log('sourceDataURL', sourceDataURL);
        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                {this.props.read_only === true &&
                                this.props.defaultValue &&
                                this.props.defaultValue.length > 0 ? (
                                    <div>
                                        <img src={sourceDataURL} />
                                    </div>
                                ) : (
                                    <div className="image-upload-container">
                                        <div style={fileInputStyle}>
                                            <input
                                                name={name}
                                                type="file"
                                                accept="image/*"
                                                capture="camera"
                                                className="image-upload"
                                                onChange={this.displayImage}
                                            />
                                            <div className="image-upload-control">
                                                <div className="btn btn-default btn-school">
                                                    <i className="fas fa-camera"></i> Upload Photo
                                                </div>
                                                <p>Select an image from your computer or device.</p>
                                            </div>
                                        </div>

                                        {this.state.img && (
                                            <div>
                                                <img
                                                    src={this.state.img}
                                                    height="100"
                                                    className="image-upload-preview"
                                                />
                                                <br />
                                                <div
                                                    className="btn btn-school btn-image-clear"
                                                    onClick={this.clearImage}
                                                >
                                                    <i className="fas fa-times"></i> Clear Photo
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class RangeElement extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        this.state = {
            value:
                props.defaultValue !== undefined
                    ? parseInt(props.defaultValue, 10)
                    : parseInt(props.data.default_value, 10)
        };
    }

    changeValue = e => {
        //console.log(e);
        this.setState({
            value: e.target.value
        });

        if (this.props.handleChange) {
            this.props.handleChange(e);
        }
    };

    render() {
        const props = {};
        const name = this.props.data.field_name;

        props.type = 'range';
        props.list = `tickmarks_${name}`;
        props.min = this.props.data.min_value;
        props.max = this.props.data.max_value;
        props.step = this.props.data.step;

        if (this.props.read_only) {
            props.disabled = 'disabled';
        }

        let marks = {};
        let ticks = [];
        for (let i = props.min; i <= props.max; i++) {
            marks[i] = i;
            ticks.push(i);
        }
        //props.marks = marks;
        props.ticks = ticks.map(t => {
            return t;
        });
        //props.ticks = marks;

        props.value = this.state.value;
        //props.onChange = this.changeValue;
        props.change = this.changeValue;

        if (this.props.mutable) {
            props.ref = this.inputField;
        }

        const datalist = [];
        for (let i = parseInt(props.min_value, 10); i <= parseInt(props.max_value, 10); i += parseInt(props.step, 10)) {
            datalist.push(i);
        }

        //console.log('datalist', datalist)
        /*props.ticks_labels = datalist.map((item, index) => {
            return index;
        })*/

        const oneBig = 100 / (datalist.length - 1);

        const _datalist = datalist.map((d, idx) => <option key={`${props.list}_${idx}`}>{d}</option>);

        const visible_marks = datalist.map((d, idx) => {
            const option_props = {};
            let w = oneBig;
            if (idx === 0 || idx === datalist.length - 1) {
                w = oneBig / 2;
            }
            option_props.key = `${props.list}_label_${idx}`;
            option_props.style = { width: `${w}%` };
            if (idx === datalist.length - 1) {
                option_props.style = { width: `${w}%`, textAlign: 'right' };
            }
            return <label {...option_props}>{d}</label>;
        });

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className="form-group">
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                            </div>

                            <div className="range">
                                <Flex style={{ justifyContent: 'space-between' }}>
                                    <span className="text-left range-nav">{this.props.data.min_label}</span>
                                    <span className="text-right range-nav">{this.props.data.max_label}</span>
                                </Flex>
                                <ReactBootstrapSlider {...props} />
                                {/*<Slider dots={true} {...props} />*/}
                            </div>
                            <div className="visible_marks">{visible_marks}</div>
                            <input name={name} value={this.state.value} type="hidden" />
                            <datalist id={props.list}>{_datalist}</datalist>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

class Captcha extends React.Component {
    constructor(props) {
        super(props);
        this.inputField = React.createRef();
        this.state = { value: this.props.defaultValue };
    }

    render() {
        const props = {};
        props.type = 'hidden';
        props.className = 'form-control';
        props.name = this.props.data.field_name;
        if (this.props.mutable) {
            props.ref = this.inputField;
        }

        let baseClasses = 'SortableItem rfb-item';
        if (this.props.data.pageBreakBefore) {
            baseClasses += ' alwaysbreak';
        }

        let should_show = helpersScreener.checkQuestionLogic(
            this.props.data,
            this.props.all_questions,
            this.props.page_index,
            this.props.answers_by_form_element_name,
            this.props.show_qualification
        );
        if (should_show || this.props.editElement || this.props.read_only) {
            return (
                <div className={baseClasses}>
                    {this.props.editElement ? (
                        this.props.editElement
                    ) : (
                        <div>
                            <ComponentHeader {...this.props} />
                            <div className={helpersScreener.getInputParentClassName(props.name)}>
                                <ComponentLabel
                                    {...this.props}
                                    isQuestionLogicVisible={this.props.show_qualification && this.props.isQuestionLogic}
                                />
                                {this.props.read_only === true || this.props.defaultValue !== undefined ? (
                                    <>
                                        {this.props.defaultValue
                                            ? helpersScreener.getCaptchaValueHumanReadable(this.props.defaultValue)
                                            : ''}
                                    </>
                                ) : (
                                    <ReCAPTCHA
                                        sitekey={config.GOOGLE_RECAPTCHA_SITEKEY}
                                        onChange={e => {
                                            this.setState({ value: e });
                                            if (this.props.handleChange) {
                                                this.props.handleChange(e);
                                            }
                                        }}
                                        onExpired={e => {
                                            this.setState({ value: '' });
                                            if (this.props.handleChange) {
                                                this.props.handleChange('');
                                            }
                                        }}
                                        onErrored={e => {
                                            this.setState({ value: '' });
                                            if (this.props.handleChange) {
                                                this.props.handleChange('');
                                            }
                                        }}
                                    />
                                )}
                                <input
                                    {...props}
                                    required={this.props.data.required}
                                    value={this.state.value}
                                    aria-label={getElementLabel(this.props.data.id)}
                                />
                            </div>
                        </div>
                    )}
                </div>
            );
        } else {
            return null;
        }
    }
}

FormElements.Header = Header;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.LineBreak = LineBreak;
FormElements.TextInput = TextInput;
FormElements.NumberInput = NumberInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
FormElements.Signature = Signature;
FormElements.Checkboxes = Checkboxes;
FormElements.Matrix = Matrix;
FormElements.DatePicker = DatePicker;
FormElements.RadioButtons = RadioButtons;
FormElements.Image = Image;
FormElements.Rating = Rating;
FormElements.Tags = Tags;
FormElements.HyperLink = HyperLink;
FormElements.Download = Download;
FormElements.Camera = Camera;
FormElements.RangeElement = RangeElement;
FormElements.AddPipeInput = AddPipeInput;
FormElements.FileUpload = FileUpload;
FormElements.HiddenValue = HiddenValue;
FormElements.Captcha = Captcha;

export default FormElements;
