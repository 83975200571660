import React, { useState, useEffect, useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button, Heading } from 'rebass';
import { Label, Select, Input, Checkbox } from '@rebass/forms';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import moment from 'moment-timezone';
import NiceDropdown from 'components/NiceDropdown';
import analytics from 'utils/analytics';
import {
    FiChevronDown,
    FiChevronUp,
    FiSidebar,
    FiCheckCircle,
    FiXCircle,
    FiPhone,
    FiAlertCircle,
    FiCheckSquare,
    FiMinus,
    FiMoreVertical,
    FiFileText,
    FiFile,
    FiEye,
    FiUser
} from 'react-icons/fi';
import {
    STUDY_TABLE_COLUMN_ASSIGNED,
    STUDY_TABLE_COLUMN_SPOT,
    STUDY_TABLE_COLUMN_CONFIRMED,
    STUDY_TABLE_COLUMN_LOCATION,
    STUDY_TABLE_COLUMN_PAID,
    STUDY_TABLE_COLUMN_LAST_CONTACTED,
    SP_STATUS_KEY_SCHEDULED,
    SP_STATUS_KEY_COMPLETED,
    SP_STATUS_KEY_PAID_SENT,
    SP_STATUS_KEY_HOLD,
    SP_STATUS_KEY_PAYMENT_FAILED,
    COL_EMAIL,
    COL_FIRST_NAME,
    COL_LAST_NAME,
    IS_QUALIFIED
} from 'utils/constants';
import ReactModal from 'react-modal';
import ReactModalActions from 'components/ReactModalActions';

import helpersStudy from 'utils/helpers-study';
import ListWrapper from 'components/ListWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import ParticipantMaskedValue from 'components/Participant/ParticipantMaskedValue';
import StatusLabel from 'components/StatusLabel';

const COL_WIDTH = 220;
const COL_WIDTH_PX = `${COL_WIDTH}px`;
const COL_WIDTH_MIN = 20;
const avatar_style = { width: '16px', height: '16px', margin: '2px 4px 0 0' };

/**
 * Status label for participant status
 *
 * @param {Object} param0
 * @param {Object} param0.studyPerson study person object
 */
function ParticipantStatusLabel({ studyPerson }) {
    const status = useMemo(() => helpersStudy.getHumanReadableSPStatus(studyPerson.status), [studyPerson.status]);
    const color = useMemo(() => {
        if ([SP_STATUS_KEY_SCHEDULED, SP_STATUS_KEY_COMPLETED, SP_STATUS_KEY_PAID_SENT].includes(studyPerson.status)) {
            return 'green';
        } else if ([SP_STATUS_KEY_HOLD].includes(studyPerson.status)) {
            return 'orange';
        } else if ([SP_STATUS_KEY_PAYMENT_FAILED].includes(studyPerson.status)) {
            return 'danger';
        } else {
            return 'gray';
        }
    }, [studyPerson.status]);

    if (!status) return null;

    return <StatusLabel className="fs-body-12" color={color} status={status} />;
}

function StudyParticipantsList(props) {
    const [showCheckAllDropdown, setShowCheckAllDropdown] = useState(false);
    const [showParticipantMoreDropdownSpId, setShowParticipantMoreDropdownSpId] = useState(null);
    const [sortObject, setSortObject] = useState(props.sort);
    const [showModalSelectCount, setShowModalSelectCount] = useState(false);
    const [randomIsChecked, setRandomIsChecked] = useState(false);

    const [horizontalScrollFix, setHorizontalScrollFix] = useState(true);
    const { addToast } = useToasts();

    useEffect(
        () => {
            // Query the table
            const table = document.getElementById('study-people-table');

            if (table) {
                //console.log('RESIZE TABLE');
                // Query all headers
                const cols = table.querySelectorAll('th');

                // Loop over them
                [].forEach.call(cols, function(col) {
                    // skip if there is already a resizer
                    if (col.querySelectorAll('.resizer').length > 0) {
                        return;
                    }

                    // Create a resizer element
                    const resizer = document.createElement('div');
                    resizer.classList.add('resizer');

                    // Create a vertical line element
                    const vl = document.createElement('div');
                    vl.classList.add('resizer-vl');
                    resizer.appendChild(vl);

                    // Set the height
                    //resizer.style.height = `${table.offsetHeight}px`;
                    resizer.style.height = `44px`;

                    // Add a resizer element to the column
                    col.appendChild(resizer);

                    // Will be implemented in the next section
                    createResizableColumn(col, resizer);
                });
            }
        },
        // array of variables that can trigger an update if they change. Pass an
        // an empty array if you just want to run it once after component mounted.
        [props.study_people]
    );

    useEffect(() => {
        ReactTooltip.rebuild();
    }, [props.study_people]);

    function getSortClass(group, column, id) {
        if (group == sortObject.group && column == sortObject.column && id == sortObject.id) {
            return 'text-primary th-clickable';
        } else {
            return 'th-clickable';
        }
    }

    function shouldShowColumn(columnId) {
        return props.showHideColumns.find(column => column.id == columnId && column.checked);
    }

    function getColumnOrder(columnId) {
        const found = props.showHideColumns.find(column => column.id == columnId);
        return found.order;
    }

    function renderSortArrow(group, column, id) {
        return (
            <Box
                style={{
                    display: 'inline-block',
                    verticalAlign: 'top',
                    margin: '-1px 0 0 4px',
                    flexShrink: 0
                    //float: 'right'
                }}
            >
                {// eslint-disable-next-line no-nested-ternary
                group == sortObject.group && column == sortObject.column && id == sortObject.id ? (
                    sortObject.direction == 'desc' ? (
                        <FiChevronDown />
                    ) : (
                        <FiChevronUp />
                    )
                ) : (
                    <FiChevronDown />
                )}
            </Box>
        );
    }

    function renderScreenerQuestionHeaders() {
        const stickyColumnName = 'col-sticky-name';

        let headers = [];
        headers.push({
            id: stickyColumnName,
            order: -1, // always first
            element: (
                <th
                    className="sticky-row-style"
                    style={{ position: 'sticky', left: 0, zIndex: 100, minWidth: '240px' }}
                >
                    {props.checkAllOrVisibleStatus === 'none' && (
                        <Button
                            variant="transparent-icon-nobg"
                            mr={2}
                            style={{
                                position: 'absolute',
                                top: '6px',
                                left: '-8px'
                            }}
                            onClick={() => {
                                setShowCheckAllDropdown(!showCheckAllDropdown);
                            }}
                        >
                            <FiCheckSquare />
                        </Button>
                    )}
                    {props.checkAllOrVisibleStatus !== 'none' && (
                        <Button
                            variant="small-icon"
                            mr={2}
                            style={{
                                position: 'absolute',
                                top: '12px',
                                left: '0',
                                border: `1px solid ${props.theme.colors.amber}`,
                                color: `${props.theme.colors.amber}`
                            }}
                            onClick={() => {
                                props.deselectAll();
                            }}
                        >
                            <FiMinus />
                        </Button>
                    )}
                    {showCheckAllDropdown && (
                        <NiceDropdown
                            width={'160px'}
                            positionTop={'36px'}
                            positionLeft={'2px'}
                            onClose={() => {
                                setShowCheckAllDropdown(false);
                            }}
                            items={[
                                {
                                    id: 'visible',
                                    title: 'Select visible'
                                },
                                {
                                    id: 'custom_count',
                                    title: 'Select count'
                                },
                                {
                                    id: 'all',
                                    title: `Select all`
                                }
                            ]}
                            onChange={value => {
                                if (value == 'custom_count') {
                                    setShowModalSelectCount(true);
                                } else {
                                    props.handleCheckAllDropdownChange(value);
                                }
                            }}
                        />
                    )}
                    <Box
                        className={`th-inner-content ${getSortClass('sp', 'name')}`}
                        onClick={e => {
                            onClickSort(e, { sort_group: 'sp', sort_column: 'name', default_sort_direction: 'asc' });
                        }}
                    >
                        <span style={{ display: 'inline-block', marginLeft: '8px' }}>Full Name</span>
                        {renderSortArrow('sp', 'name')}
                    </Box>
                </th>
            )
        });

        if (shouldShowColumn(STUDY_TABLE_COLUMN_ASSIGNED.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_ASSIGNED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_ASSIGNED.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box
                            className={`th-inner-content ${getSortClass('sp', 'assigned')}`}
                            onClick={e => {
                                onClickSort(e, { sort_group: 'sp', sort_column: 'assigned' });
                            }}
                        >
                            Assigned{renderSortArrow('sp', 'assigned')}
                        </Box>
                    </th>
                )
            });
        }

        if (shouldShowColumn(STUDY_TABLE_COLUMN_SPOT.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_SPOT.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_SPOT.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box
                            className={`th-inner-content ${getSortClass('sp', 'spot')}`}
                            onClick={e => {
                                onClickSort(e, { sort_group: 'sp', sort_column: 'spot' });
                            }}
                        >
                            Session{renderSortArrow('sp', 'spot')}
                        </Box>
                    </th>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_CONFIRMED.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_CONFIRMED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_CONFIRMED.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box
                            className={`th-inner-content ${getSortClass('sp', 'confirmed')}`}
                            onClick={e => {
                                onClickSort(e, { sort_group: 'sp', sort_column: 'confirmed' });
                            }}
                        >
                            Confirmed{renderSortArrow('sp', 'confirmed')}
                        </Box>
                    </th>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_LOCATION.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_LOCATION.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_LOCATION.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box className="th-inner-content">Location</Box>
                    </th>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_PAID.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_PAID.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_PAID.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box
                            className={`th-inner-content ${getSortClass('sp', 'paid')}`}
                            onClick={e => {
                                onClickSort(e, { sort_group: 'sp', sort_column: 'paid' });
                            }}
                        >
                            Paid{renderSortArrow('sp', 'paid')}
                        </Box>
                    </th>
                )
            });
        }

        if (shouldShowColumn(STUDY_TABLE_COLUMN_LAST_CONTACTED.id)) {
            headers.push({
                id: STUDY_TABLE_COLUMN_LAST_CONTACTED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_LAST_CONTACTED.id),
                element: (
                    <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                        <Box
                            className={`th-inner-content ${getSortClass('sp', 'last_contacted_at')}`}
                            onClick={e => {
                                onClickSort(e, { sort_group: 'sp', sort_column: 'last_contacted_at' });
                            }}
                        >
                            Last Contacted At{renderSortArrow('sp', 'last_contacted_at')}
                        </Box>
                    </th>
                )
            });
        }

        if (props.study.custom_sp_attributes) {
            props.study.custom_sp_attributes.forEach(cspa => {
                if (shouldShowColumn(helpersStudy.getCspaTableColumnId(cspa))) {
                    headers.push({
                        id: helpersStudy.getCspaTableColumnId(cspa),
                        order: getColumnOrder(helpersStudy.getCspaTableColumnId(cspa)),
                        element: (
                            <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                                <Box className={`th-inner-content`}>{cspa.title}</Box>
                            </th>
                        )
                    });
                }
            });
        }

        if (props.study.screeners && props.study.screeners.length) {
            props.study.screeners.forEach(s => {
                if (shouldShowColumn(helpersStudy.getScreenerAnsweredTableColumnId(s.id))) {
                    headers.push({
                        id: helpersStudy.getScreenerAnsweredTableColumnId(s.id),
                        order: getColumnOrder(helpersStudy.getScreenerAnsweredTableColumnId(s.id)),
                        element: (
                            <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                                <Flex
                                    className={`th-inner-content ${getSortClass('sp', 'screener_answered', s.id)}`}
                                    onClick={e => {
                                        onClickSort(e, {
                                            sort_group: 'sp',
                                            sort_column: 'screener_answered',
                                            sort_id: s.id
                                        });
                                    }}
                                >
                                    <Box className="ellipsis">Answered: {s.title}</Box>
                                    {renderSortArrow('sp', 'screener_answered', s.id)}
                                </Flex>
                            </th>
                        )
                    });
                }
            });
        }

        const questions = helpersStudy.getAllScreenerQuestionsList(props.study.screener, props.study.screeners);
        questions.forEach(question => {
            if (!question.static) {
                if (shouldShowColumn(helpersStudy.getScreenerQuestionTableColumnId(question.field_name))) {
                    headers.push({
                        id: helpersStudy.getScreenerQuestionTableColumnId(question.field_name),
                        order: getColumnOrder(helpersStudy.getScreenerQuestionTableColumnId(question.field_name)),
                        element: renderScreenerQTableHeader(
                            helpers.removeHtml(question.label),
                            question.screener_id,
                            question.field_name
                        )
                    });
                }
            }
        });
        props.study.screeners.forEach(s => {
            if (shouldShowColumn(helpersStudy.getScreenerCustomVariabledTableColumnId(s.id))) {
                headers.push({
                    id: helpersStudy.getScreenerCustomVariabledTableColumnId(s.id),
                    order: getColumnOrder(helpersStudy.getScreenerCustomVariabledTableColumnId(s.id)),
                    element: (
                        <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                            Custom Variables: {s.title}
                        </th>
                    )
                });
            }
        });

        headers = headers.sort(function(a, b) {
            if (a.order === b.order) {
                return 0;
            } else {
                return a.order - b.order;
            }
        });

        return headers.map(h => h.element);
    }

    function renderScreenerQTableHeader(title, screener_id, field_name) {
        return (
            <th style={{ width: COL_WIDTH_PX }} className={`ellipsis`}>
                <Flex
                    className={`th-inner-content ${getSortClass('screener_q', screener_id, field_name)}`}
                    style={{ width: COL_WIDTH_PX }}
                    onClick={e => {
                        onClickSort(e, { sort_group: 'screener_q', sort_column: screener_id, sort_id: field_name });
                    }}
                >
                    <Box className="ellipsis">{title}</Box>
                    {renderSortArrow('screener_q', screener_id, field_name)}
                </Flex>
            </th>
        );
    }

    function getScreenersList(study_person) {
        let list = [];
        if (props.study.screener && props.study.screener[0] && props.study.screener[0].length) {
            const screener_link_old = helpersScreener.getScreenerLink(props.study.uuid, props.auth.account, {
                defaultDomain: true
            });
            list.push({
                id: 'default',
                title: `Open screener: Default`,
                icon: <FiFileText />,
                onClick: () => {
                    window.open(screener_link_old);
                    //helpers.popupWindow({ url: , w: 800, h: 600 });
                }
            });
        }
        if (props.study.screeners && props.study.screeners.length) {
            props.study.screeners.forEach(s => {
                const screener_link = helpersScreener.getScreenerLinkNewForPerson(
                    s.uuid,
                    props.auth.account,
                    study_person,
                    { defaultDomain: true }
                );
                const s_a_found = study_person.screener_answers.find(s_a => s_a.study_screener_id == s.id);

                list.push({
                    id: s.id,
                    title: `${s_a_found ? 'View answers' : 'Open screener'}: ${s.title}`,
                    icon: s_a_found ? <FiFileText /> : <FiFile />,
                    onClick: () => {
                        window.open(screener_link);
                        //helpers.popupWindow({ url: , w: 800, h: 600 });
                    }
                });
            });
        }
        return list;
    }

    function onSearchIp(ip) {
        //console.log('seraching ip!', ip)
        if (props.onSearchKeywordChange) {
            //console.log('calling parent')
            props.onSearchKeywordChange({
                target: {
                    value: 'ip:' + ip
                }
            });
            addToast('Quick Search updated with IP address', {
                appearance: 'success',
                autoDismiss: true
            });

            analytics.track('study-click_duplicate_ip');
        }
    }

    function getTooltip(sp) {
        let tooltip_info = [];
        let is_danger = false;

        const emailStatusLabels = helpers.getEmailStatusLabels(sp.person);
        if (emailStatusLabels) {
            tooltip_info.push(emailStatusLabels);
        }

        /*const phone_number = helpers.personGetCustomValue(sp.person, 'Phone Number');
        if (!helpersStudy.isPhoneNumberValid(props.study, phone_number) && sp.spot) {
            is_danger = true;
            tooltip_info.push(<>Invalid phone number format for SMS and Voice reminders <FiPhone className='va-top' style={{margin: '3px 0 0 0'}}/> {phone_number}</>);
        }*/

        // check if latest email to respondent failed
        if (helpersStudy.didLatestEmailFail(sp.emails_sent)) {
            is_danger = true;
            tooltip_info.push(<>Latest email failed to send, view profile for more details.</>);
        }

        if (helpers.personIsOptedOut(sp.person)) {
            tooltip_info.push('Opt-out from email communication');
        }

        if (sp.screener_answers) {
            sp.screener_answers.forEach(sa => {
                let sa_screener = getScreenerById(sa.study_screener_id);
                if (helpersStudy.shouldShowDupeIpWarning(sa_screener, sa)) {
                    is_danger = true;
                    tooltip_info.push(
                        <>
                            Duplicate IP (
                            <span className="link" onClick={() => onSearchIp(sa.ip)}>
                                {sa.ip}
                            </span>
                            ) across responses for "{sa_screener.title}"{' '}
                            <a href="#" data-beacon-article="6321057ac713d51da3ede31e" className="help-question">
                                ?
                            </a>
                        </>
                    );
                }
            });
        }

        if (!tooltip_info.length) {
            return [null, false];
        }

        const tooltip = (
            <ReactTooltip
                id={`sp-tooltip-${sp.id}`}
                effect="solid"
                place="right"
                className="react-tooltip-hover-stay"
                delayHide={500}
            >
                <ul className="tight">
                    {tooltip_info.map(ti => (
                        <li>{ti}</li>
                    ))}
                </ul>
            </ReactTooltip>
        );

        return [tooltip, is_danger];
    }

    function getPersonLink(person, name) {
        return (
            <span
                className="link muted va-top"
                onClick={e => {
                    e.preventDefault();
                    props.onClickParticipant(person);
                }}
            >
                {name}
            </span>
        );
    }

    function getPersonIdentifier(sp, display_tooltip, tooltip_is_danger) {
        const first_name_key = helpers.personGetCustomDataKey(sp.person, COL_FIRST_NAME);
        const first_name = helpers.personGetCustomValue(sp.person, COL_FIRST_NAME);
        const last_name_key = helpers.personGetCustomDataKey(sp.person, COL_LAST_NAME);
        const last_name = helpers.personGetCustomValue(sp.person, COL_LAST_NAME);

        let identifier = (
            <>
                {!first_name && !!first_name_key.pii_masked && !first_name_key.unmasked && (
                    <ParticipantMaskedValue
                        role={props.auth.account_role.type}
                        participantId={sp.person_id}
                        setValue={value => {
                            sp.person.custom_data_values2[first_name_key.id] = value;
                            first_name_key.unmasked = true;
                            props.onParticipantUnmask(sp);
                        }}
                        keyId={first_name_key.id}
                        label={'first name'}
                        style={{ marginRight: '5px', minWidth: '150px' }}
                    />
                )}
                {(!!first_name || !!last_name) && (
                    <span
                        className="link muted va-top strong"
                        onClick={e => {
                            e.preventDefault();
                            props.onClickParticipant(sp);
                        }}
                    >
                        {getPersonLink(sp, `${first_name} ${last_name}`)}
                    </span>
                )}
                {!last_name && !!last_name_key.pii_masked && !last_name_key.unmasked && (
                    <ParticipantMaskedValue
                        role={props.auth.account_role.type}
                        participantId={sp.person_id}
                        setValue={value => {
                            sp.person.custom_data_values2[last_name_key.id] = value;
                            last_name_key.unmasked = true;
                            props.onParticipantUnmask(sp);
                        }}
                        keyId={last_name_key.id}
                        label={'last name'}
                        style={{ marginLeft: '5px', minWidth: '150px' }}
                    />
                )}
                {!first_name &&
                    (!first_name_key.pii_masked || first_name_key.unmasked) &&
                    !last_name &&
                    (!last_name_key.pii_masked || last_name_key.unmasked) &&
                    getPersonLink(sp, <span className="text-secondary-dark">Name missing</span>)}
            </>
        );

        if (display_tooltip) {
            identifier = (
                <>
                    <FiAlertCircle
                        data-tip
                        data-for={`sp-tooltip-${sp.id}`}
                        style={{ margin: '4px 4px 0 0' }}
                        className={`va-top ${tooltip_is_danger ? 'red' : 'gray'}`}
                    />{' '}
                    {identifier}
                </>
            );
        }

        // keep this last
        if (helpersStudy.isSpLocked(sp)) {
            identifier = (
                <>
                    <FiEye style={{ verticalAlign: 'top', margin: '5px 8px 0 0' }} />
                    {identifier}
                </>
            );
        }

        return identifier;
    }

    function onClickSort(e, { sort_group, sort_column, sort_id, default_sort_direction }) {
        // skip if its the resizer

        if (e && e.target && e.target.classList && e.target.classList.contains('resizer')) {
            return;
        }

        //console.log(sortObject.direction);
        let newSortDirection;
        //console.log(newSortDirection);
        //console.log(sort_id == sortObject.id)
        if (sort_group == sortObject.group && sort_column == sortObject.column && sort_id == sortObject.id) {
            // flip the sort direction
            newSortDirection = sortObject.direction == 'desc' ? 'asc' : 'desc';
        } else {
            newSortDirection = default_sort_direction ? default_sort_direction : 'desc';
        }
        //console.log(newSortDirection);

        const newSortObject = { group: sort_group, column: sort_column, id: sort_id, direction: newSortDirection };
        setSortObject(newSortObject);

        props.onChangeSort(newSortObject);
    }

    function showTimeAgoFromDate(string) {
        let to_return;
        try {
            if (string) {
                // this rounds down instead of up
                moment.relativeTimeRounding(Math.floor);

                to_return = moment.utc(string).fromNow();
            }
        } catch (e) {
            helpers.trackError(e);
            to_return = 'Error showing date';
        }

        return to_return;
    }

    function getScreenerTitle(study_screener_id) {
        if (study_screener_id && props.study.screeners) {
            const found_screener = props.study.screeners.find(s => s.id == study_screener_id);
            if (found_screener) {
                return found_screener.title;
            }
        }
    }

    function getScreenerById(study_screener_id) {
        if (study_screener_id && props.study.screeners) {
            const found_screener = props.study.screeners.find(s => s.id == study_screener_id);
            if (found_screener) {
                return found_screener;
            }
        }
    }

    function renderScreenerAnsweredStatus(status, sp_id, s_id) {
        const tooltip_id = `screener-answered-tooltip-${sp_id}-${s_id}`;
        const icon_style = { margin: '0 8px 1px 0' };
        let icon = null;
        let message = '';
        switch (status) {
            case 'qualified':
                icon = (
                    <FiCheckCircle data-tip data-for={tooltip_id} className="fs-16 color-success" style={icon_style} />
                );
                message = 'Qualified';
                break;
            case 'disqualified':
                icon = <FiXCircle data-tip data-for={tooltip_id} className="fs-16 color-danger" style={icon_style} />;
                message = 'Disqualified';
                break;
            case 'over_quota':
                icon = (
                    <FiCheckCircle data-tip data-for={tooltip_id} className="fs-16 color-warning" style={icon_style} />
                );
                message = 'Qualified, Over Quota';
                break;
            case 'incomplete':
                icon = (
                    <FiAlertCircle data-tip data-for={tooltip_id} className="fs-16 color-warning" style={icon_style} />
                );
                message = 'Incomplete';
                break;
        }

        return (
            <>
                {icon}
                <ReactTooltip id={tooltip_id} place="left" effect="solid">
                    {message}
                </ReactTooltip>
            </>
        );
    }

    function renderStudyPersonItemInnards(sp, study, showScreenerAnswersColumns) {
        let all_columns = [];
        const all_questions = helpersStudy.getAllScreenerQuestionsList(study.screener, study.screeners);
        let all_answers_for_sp = [];

        if (shouldShowColumn(STUDY_TABLE_COLUMN_ASSIGNED.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_ASSIGNED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_ASSIGNED.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        <>
                            {sp && sp.assigned_user && sp.assigned_user.id ? (
                                <>
                                    {sp.assigned_user.avatar ? (
                                        <img
                                            src={sp.assigned_user.avatar}
                                            style={avatar_style}
                                            className="circle va-top"
                                        />
                                    ) : (
                                        <FiUser style={avatar_style} className="circle va-top" />
                                    )}
                                    {sp.assigned_user.name}
                                </>
                            ) : (
                                ''
                            )}
                        </>
                    </td>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_SPOT.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_SPOT.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_SPOT.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        {helpersStudy.displayStudyPersonSpot(sp, study)}
                    </td>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_CONFIRMED.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_CONFIRMED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_CONFIRMED.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        {helpersStudy.displayConfirmedAt(sp, study)}
                    </td>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_LOCATION.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_LOCATION.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_LOCATION.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        {helpers.getStudyPersonLocation(sp, study)}
                    </td>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_PAID.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_PAID.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_PAID.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        {sp.incentive_status == 'paid' ? (
                            helpers.renderCurrencyAmount(sp.incentive_currency, sp.incentive_total_paid)
                        ) : (
                            <>&nbsp;</>
                        )}
                    </td>
                )
            });
        }
        if (shouldShowColumn(STUDY_TABLE_COLUMN_LAST_CONTACTED.id)) {
            all_columns.push({
                id: STUDY_TABLE_COLUMN_LAST_CONTACTED.id,
                order: getColumnOrder(STUDY_TABLE_COLUMN_LAST_CONTACTED.id),
                element: (
                    <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                        {helpersStudy.displayStudyLastContactedAt(sp, study)}
                    </td>
                )
            });
        }

        study.custom_sp_attributes.forEach(cspa => {
            if (shouldShowColumn(helpersStudy.getCspaTableColumnId(cspa))) {
                let found = sp.custom_attributes.find(attr => attr.sp_attribute_id == cspa.id);

                let render_val = '';
                if (found) {
                    if (cspa.type && cspa.type == 'checkbox') {
                        if (found.value && helpers.isJsonString(found.value)) {
                            let render_val_arr = [];
                            //console.log('JSON.parse(found.value)', JSON.parse(found.value))
                            JSON.parse(found.value).forEach(v => {
                                const cspa_d_found = cspa.definition.find(d => d && d.value == v);
                                if (cspa_d_found) {
                                    render_val_arr.push(cspa_d_found.label);
                                }
                            });
                            render_val = render_val_arr.join(', ');
                        }
                    } else if (cspa.type && cspa.type == 'dropdown') {
                        const cspa_d_found = cspa.definition.find(d => d && d.value == found.value);
                        if (cspa_d_found) {
                            render_val = cspa_d_found.label;
                        }
                    } else {
                        render_val = found.value;
                    }
                }
                all_columns.push({
                    id: helpersStudy.getCspaTableColumnId(cspa),
                    order: getColumnOrder(helpersStudy.getCspaTableColumnId(cspa)),
                    element: (
                        <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                            {render_val}
                        </td>
                    )
                });
            }
        });

        if (props.study.screeners && props.study.screeners.length) {
            props.study.screeners.forEach(s => {
                if (shouldShowColumn(helpersStudy.getScreenerAnsweredTableColumnId(s.id))) {
                    let s_a_element = null;
                    let screener_answer_found = null;
                    if (sp.screener_answers && sp.screener_answers instanceof Array) {
                        screener_answer_found = sp.screener_answers.find(sa => sa.study_screener_id == s.id);
                        if (screener_answer_found) {
                            //console.log(helpers.personGetCustomValue(sp.person, 'Email'), screener_answer_found)

                            const status = screener_answer_found.incomplete
                                ? 'incomplete'
                                : screener_answer_found.is_qualified == IS_QUALIFIED.YES
                                ? 'qualified'
                                : screener_answer_found.is_qualified == IS_QUALIFIED.OVER_QUOTA
                                ? 'over_quota'
                                : 'disqualified';
                            s_a_element = (
                                <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                                    {renderScreenerAnsweredStatus(status, sp.id, s.id)}
                                    {moment
                                        .utc(screener_answer_found.created_at)
                                        .local()
                                        .format('MMM Do, YYYY [at] h:mma')}
                                </td>
                            );
                        }
                    }
                    if (!screener_answer_found) {
                        s_a_element = <td></td>;
                    }

                    all_columns.push({
                        id: helpersStudy.getScreenerAnsweredTableColumnId(s.id),
                        order: getColumnOrder(helpersStudy.getScreenerAnsweredTableColumnId(s.id)),
                        element: s_a_element
                    });
                }
            });
        }

        // get all answers
        if (sp.screener_answers && sp.screener_answers instanceof Array && sp.screener_answers.length) {
            sp.screener_answers.forEach(single_screener => {
                const sa = JSON.parse(single_screener.answers);
                if (sa) {
                    all_answers_for_sp = all_answers_for_sp.concat(helpersStudy.getAllScreenerAnswersList(sa));
                }
            });
        }

        const [tooltip, tooltip_is_danger] = getTooltip(sp);

        const question_answer_map = helpersStudy.mapQuestionsToAnswers(all_questions, all_answers_for_sp);
        //console.log(question_answer_map);
        //console.log(all_answers_for_sp);
        let local_screener_answer;
        all_questions.forEach(question => {
            if (question) {
                if (!question.static) {
                    let sa_col_id = helpersStudy.getScreenerQuestionTableColumnId(question.field_name);
                    if (shouldShowColumn(sa_col_id)) {
                        let answers_readable;
                        local_screener_answer = question_answer_map[question.field_name];
                        //console.log(question.label, local_screener_answer)

                        if (Array.isArray(local_screener_answer)) {
                            answers_readable = local_screener_answer.join(', ');

                            // if its a date
                            if (question.element == 'DatePicker') {
                                answers_readable = showTimeAgoFromDate(answers_readable);
                            } else if (question.element == 'Signature') {
                                if (local_screener_answer && local_screener_answer.length && local_screener_answer[0]) {
                                    answers_readable = 'Signed';
                                } else {
                                    answers_readable = 'Not Signed';
                                }
                            } else if (question.element == 'FileUpload') {
                                answers_readable =
                                    local_screener_answer && local_screener_answer.length && local_screener_answer[0]
                                        ? helpers.renderLinksForUploadedScreenerFile(local_screener_answer[0])
                                        : '';
                            } else if (question.element == 'AddPipeInput') {
                                const video_link = helpersScreener.getVideoLinkFromScreenerAnswer(
                                    local_screener_answer
                                );
                                //console.log('video_link in list', video_link)
                                if (video_link) {
                                    answers_readable = (
                                        <a href={video_link} target="_blank">
                                            View video
                                        </a>
                                    );
                                }
                            } else if (question.element == 'Captcha') {
                                answers_readable =
                                    local_screener_answer && local_screener_answer.length && local_screener_answer[0]
                                        ? helpersScreener.getCaptchaValueHumanReadable(local_screener_answer[0])
                                        : '';
                            }

                            // otherwise just keep the value
                        } else {
                            if (question.element == 'DatePicker') {
                                answers_readable = showTimeAgoFromDate(local_screener_answer);
                            } else {
                                answers_readable = local_screener_answer;
                            }
                        }

                        all_columns.push({
                            id: sa_col_id,
                            order: getColumnOrder(sa_col_id),
                            element: (
                                <td className={'ellipsis'} style={{ maxWidth: COL_WIDTH_PX }}>
                                    {answers_readable}
                                </td>
                            )
                        });
                    }
                }
            }
        });

        let screener_ids_answered = [];

        if (sp.screener_answers && sp.screener_answers instanceof Array && sp.screener_answers.length) {
            sp.screener_answers.forEach(single_screener => {
                screener_ids_answered.push(single_screener.study_screener_id);

                let sa_cspa_col_id = helpersStudy.getScreenerCustomVariabledTableColumnId(
                    single_screener.study_screener_id
                );
                if (shouldShowColumn(sa_cspa_col_id)) {
                    let cv_row;
                    if (single_screener.custom_variables && Object.keys(single_screener.custom_variables)) {
                        cv_row = (
                            <td className="ellipsis" style={{ maxWidth: COL_WIDTH_PX }}>
                                {Object.keys(single_screener.custom_variables).map((key, index) => {
                                    const separator =
                                        Object.keys(single_screener.custom_variables).length == index + 1 ? '' : ', ';
                                    return (
                                        <>
                                            {key}: {single_screener.custom_variables[key]}
                                            {separator}
                                        </>
                                    );
                                })}
                            </td>
                        );
                    } else {
                        cv_row = <td className="ellipsis"></td>;
                    }
                    all_columns.push({
                        id: sa_cspa_col_id,
                        order: getColumnOrder(sa_cspa_col_id),
                        element: cv_row
                    });
                }
            });
        }

        // if they didn't answer some of the screeners, lets still render the columns
        if (props.study.screeners) {
            props.study.screeners.forEach(s => {
                // if this screener was not answered
                if (!screener_ids_answered.includes(s.id)) {
                    if (shouldShowColumn(helpersStudy.getScreenerCustomVariabledTableColumnId(s.id))) {
                        all_columns.push({
                            id: helpersStudy.getScreenerCustomVariabledTableColumnId(s.id),
                            order: getColumnOrder(helpersStudy.getScreenerCustomVariabledTableColumnId(s.id)),
                            element: <td className="ellipsis"></td>
                        });
                    }
                }
            });
        }

        all_columns = all_columns.sort(function(a, b) {
            if (a.order === b.order) {
                return 0;
            } else {
                return a.order - b.order;
            }
        });

        return (
            <>
                <tr
                    onClick={e => helpers.clickTableRowAddStyle(e)}
                    className={helpersStudy.isSpLocked(sp) ? 'row-being-viewed' : ''}
                >
                    <td
                        style={{
                            maxWidth: '300px',
                            position: 'sticky',
                            left: 0,
                            whiteSpace: 'nowrap',
                            zIndex: showParticipantMoreDropdownSpId == sp.id ? 4 : 2
                        }}
                        className="sticky-row-style"
                    >
                        <Box style={{ width: '100%', position: 'relative' }}>
                            {props.adminFacing && (
                                <div className="study-person-more-hover-parent">
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        mr={1}
                                        ml={2}
                                        className="va-top secondary-tiny study-person-more-hover"
                                        onClick={e => {
                                            e.preventDefault();
                                            props.onClickParticipant(sp);
                                        }}
                                    >
                                        <FiSidebar /> View
                                    </Button>

                                    <span style={{ position: 'relative', display: 'inline-block' }}>
                                        <Button
                                            ml={1}
                                            variant="secondary"
                                            className="secondary-tiny secondary-tiny-icon float-right study-person-more-hover"
                                            style={
                                                showParticipantMoreDropdownSpId == sp.id
                                                    ? { visibility: 'visible' }
                                                    : {}
                                            }
                                            onClick={() => setShowParticipantMoreDropdownSpId(sp.id)}
                                        >
                                            <FiMoreVertical />
                                        </Button>
                                        {showParticipantMoreDropdownSpId == sp.id && (
                                            <NiceDropdown
                                                width={'160px'}
                                                positionTop={'28px'}
                                                positionLeft={'6px'}
                                                onClose={() => {
                                                    setShowParticipantMoreDropdownSpId(null);
                                                }}
                                                items={getScreenersList(sp)}
                                                onChange={value => {
                                                    console.log(value);
                                                }}
                                            />
                                        )}
                                    </span>
                                </div>
                            )}
                            <input
                                type="checkbox"
                                key={`checkbox-${sp.person_id}`}
                                data-personid={sp.person_id}
                                checked={props.p_checked.find(person => person.id == sp.person_id)}
                                onChange={e => {
                                    props.onCheckParticipant(sp.person_id);
                                }}
                                name="person_id"
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    transform: 'translateY(-50%)',
                                    left: '-15px'
                                }}
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                                disabled={['all', 'custom_count'].includes(props.checkAllOrVisibleStatus)}
                            />
                            <Box ml="8px">
                                <Box className="ellipsis">
                                    <Flex>{getPersonIdentifier(sp, tooltip !== null, tooltip_is_danger)}</Flex>
                                </Box>
                                <ParticipantStatusLabel studyPerson={sp} />
                            </Box>
                        </Box>
                    </td>
                    {all_columns.map(h => h.element)}
                </tr>
                {tooltip}
            </>
        );
    }

    function renderStudyPeople() {
        const render = [];
        if (props.study_people && props.study_people.data) {
            props.study_people.data.forEach(sp => {
                try {
                    const participantElement = renderStudyPersonItemInnards(sp, props.study);
                    render.push(participantElement);
                } catch (e) {
                    helpers.trackError(e);
                    render.push(<tr>Error in this row - it was reported to Panelfox.</tr>);
                }
            });
        }
        //console.log(render);
        return render;
    }

    const createResizableColumn = function(col, resizer) {
        // Track the current position of mouse
        let x = 0;
        let w = 0;

        const mouseDownHandler = function(e) {
            resizer.classList.add('resizing');
            // Get the current mouse position
            x = e.clientX;

            // Calculate the current width of column
            const styles = window.getComputedStyle(col);
            w = parseInt(styles.width, 10);

            // Attach listeners for document's events
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function(e) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;

            // Update the width of column
            const new_width = w + dx - 34;
            const new_width_px = `${new_width}px`;
            //console.log(new_width, COL_WIDTH_MIN)
            if (new_width >= COL_WIDTH_MIN) {
                //console.log('new_width', new_width, new_width_px)
                const inner_cols = col.querySelectorAll('.th-inner-content');
                if (inner_cols && inner_cols[0]) {
                    inner_cols[0].style.width = new_width_px;
                }
            }
        };

        // When user releases the mouse, remove the existing event listeners
        const mouseUpHandler = function() {
            resizer.classList.remove('resizing');
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        resizer.addEventListener('mousedown', mouseDownHandler);
    };

    return (
        <Flex
            flexDirection="column"
            style={
                horizontalScrollFix
                    ? { position: 'relative', width: '100%', height: `calc(100vh - 250px)`, minHeight: '200px' }
                    : { position: 'relative', width: '100%' }
            }
        >
            {props.isLoading && (
                <div
                    style={{
                        pointerEvents: 'none',
                        position: 'absolute',
                        top: '44px',
                        left: '0',
                        right: 0,
                        bottom: 0,
                        background: 'rgba(0,0,0,.03)',
                        zIndex: 100
                    }}
                >
                    <div style={{ height: '300px' }}>
                        <LoadingWrapper>
                            <LoadingIndicator />
                        </LoadingWrapper>
                    </div>
                </div>
            )}
            {props.study_people.data.length > 0 && (
                <ListWrapper
                    style={{
                        position: 'relative',
                        fontSize: '14px',
                        margin: 0,
                        overflow: 'scroll'
                    }}
                >
                    <table className="table-main" id="study-people-table">
                        <thead>
                            <tr>{renderScreenerQuestionHeaders()}</tr>
                        </thead>
                        <tbody>{renderStudyPeople()}</tbody>
                    </table>
                </ListWrapper>
            )}

            {props.study_people.data.length < props.study_people.total && (
                <Box sx={{ paddingTop: '8px' }}>
                    <Button type="button" variant="secondary-gray" onClick={props.loadMoreStudyPeople} mr={3}>
                        {props.study_people_loading_more ? 'Loading...' : 'Load more'}
                    </Button>

                    <span style={{ display: 'inline-block' }} className="va-top">
                        <Select
                            value={props.pagination_limit}
                            onChange={e => props.onChangePaginationLimit(e.target.value)}
                        >
                            <option value="20">Show 20 per page</option>
                            <option value="50">Show 50 per page</option>
                            <option value="100">Show 100 per page</option>
                        </Select>
                    </span>
                </Box>
            )}

            <ReactModal
                isOpen={showModalSelectCount}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowModalSelectCount(false)}
                style={{ content: { width: '380px' } }}
            >
                <form
                    onSubmit={e => {
                        const count = document.getElementById('select_count').value;
                        if (count > 0 && helpers.isNumber(count)) {
                            //console.log(count);
                            props.handleCheckAllDropdownChange('custom_count', count, randomIsChecked);
                            setShowModalSelectCount(false);
                        }
                        e.preventDefault();
                    }}
                >
                    <Box m={4}>
                        <H1>Select Count</H1>
                        <Label>How many?</Label>
                        <Input type="number" id="select_count" autoFocus min="1" autoComplete="off" />

                        <Label mt={3}>
                            <Checkbox
                                name="daterange_enabled"
                                checked={randomIsChecked == 1}
                                onChange={e => {
                                    setRandomIsChecked(e.target.checked ? true : false);
                                }}
                            />
                            <span style={{ padding: '4px 0 0 0' }}>Randomize</span>
                        </Label>
                    </Box>

                    <ReactModalActions>
                        <div style={{ textAlign: 'right', width: '100%' }}>
                            <Button
                                type="button"
                                variant="transparent"
                                mr={3}
                                onClick={() => setShowModalSelectCount(false)}
                            >
                                Cancel
                            </Button>

                            <Button type="submit" mr={0}>
                                Continue
                            </Button>
                        </div>
                    </ReactModalActions>
                </form>
            </ReactModal>
        </Flex>
    );
}

export default StudyParticipantsList;
