import React, { useMemo } from 'react';
import { Box, Button, Flex } from 'rebass';
import classNames from 'classnames';
import { FiClock, FiGlobe, FiLock, FiPrinter } from 'react-icons/fi';
import { Switch } from '@rebass/forms';

import moment from 'utils/moment';

import styles from './styles.module.css';

/**
 * Display badge with information about the respondent. For example, IP address or answered at.
 *
 * @param {React.ReactNode} icon
 * @param {string} prefix
 * @param {string} value
 */
const RespondentBadge = ({ icon, prefix, value }) => (
    <Box className={styles.badge}>
        {icon}
        <span className={styles.badgePrefix}>{prefix}:</span>
        {value}
    </Box>
);

/**
 * Display admin tools for the screener page.
 *
 * @param {boolean} [isPrintable] Indicates if the page is in printable mode
 * @param {string} [ip] IP address of the respondent
 * @param {Date | string | number} [answeredAt] Date and time the screener was answered
 * @param {string} [timezone] Timezone of the current user
 * @param {React.CSSProperties} [style]
 * @param {string} [className]
 * @param {(next: boolean) => void} [onPrintableSwitchChange] Callback to switch between printable and default view.
 * @param {boolean} [isLogicVisible] Indicates if the logic switch is visible
 * @param {(value: boolean) => void} [logicSwitchOnChange] Callback to switch between logic enabled and disabled
 */
export function ScreenerAdminTools({
    className,
    style,
    isPrintable,
    onPrintableSwitchChange,
    ip,
    answeredAt,
    timezone,
    isLogicVisible,
    logicSwitchOnChange
}) {
    const isRespondentInfo = !!(ip || answeredAt);
    const answeredAtValue = useMemo(() => {
        let value = moment(answeredAt);

        if (timezone) {
            value = value.tz(timezone);
        }

        return value.format('YYYY-MM-DD HH:mm:ss z');
    }, [answeredAt, timezone]);

    return (
        <Box style={style} className={classNames('no-print', styles.container, className)}>
            <div className={styles.lock}>
                <FiLock />
                Only visible to you
            </div>
            <Flex alignItems="center" className="gap-4" flexWrap="wrap" style={{ marginBottom: '8px' }}>
                <h1 className="fs-headline-20 m-0 grow">Admin tools</h1>
                {isPrintable && (
                    <Button type="button" variant="secondary-gray" onClick={window.print} className="m-0">
                        <FiPrinter /> Print
                    </Button>
                )}
            </Flex>
            <Box style={{ marginBottom: isRespondentInfo ? '16px' : 0 }}>
                {logicSwitchOnChange && (
                    <Flex alignItems="center" className="gap-2" style={{ marginBottom: '4px' }}>
                        <span className="color-text-primary fs-accent-14">Show all logic</span>
                        <Switch
                            className="switch-small"
                            onClick={() => logicSwitchOnChange(!isLogicVisible)}
                            checked={isLogicVisible}
                        />
                    </Flex>
                )}
                {onPrintableSwitchChange && (
                    <Flex alignItems="center" className="gap-2">
                        <span className="color-text-primary fs-accent-14">Printable view</span>
                        <Switch
                            className="switch-small"
                            onClick={() => onPrintableSwitchChange(!isPrintable)}
                            checked={isPrintable}
                        />
                    </Flex>
                )}
            </Box>
            {isRespondentInfo && (
                <Box className="grow">
                    <p className="m-0 fs-accent-12 color-text-secondary" style={{ marginBottom: '8px' }}>
                        Respondent Information
                    </p>
                    <Flex className="gap-2" flexWrap="wrap">
                        {ip && <RespondentBadge icon={<FiGlobe />} prefix="IP Address" value={ip} />}
                        {answeredAt && (
                            <RespondentBadge icon={<FiClock />} prefix="Answered at" value={answeredAtValue} />
                        )}
                    </Flex>
                </Box>
            )}
        </Box>
    );
}
