import React from 'react';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const COL_FIRST_NAME = 'First Name';
export const COL_LAST_NAME = 'Last Name';
export const COL_EMAIL = 'Email';
export const COL_PHONE_NUMBER = 'Phone Number';
export const COL_CREATED_AT = 'Created At';
export const COL_UPDATED_AT = 'Updated At';
export const COL_LAST_CONTACTED_AT = 'Last Contacted At';
export const COL_LAST_INTERVIEWED_AT = 'Last Interviewed At';
export const COL_OPT_IN = 'Opt In';
export const COL_OPT_IN_SMS = 'SMS Opt In';
export const COL_OPT_OUT = 'Opt Out';
export const COL_BLOCKLIST = 'Blocklist';
export const GENERIC_CONSENT = 'Consent';

export const EMAIL_CAMPAIGN_STATUS_SENT = 'sent';
export const EMAIL_CAMPAIGN_STATUS_READY = 'ready';
export const EMAIL_CAMPAIGN_STATUS_SCHEDULED = 'scheduled';
export const EMAIL_CAMPAIGN_STATUS_CANCELLED = 'cancelled';

export const EMAIL_STATUS_SENT = 'sent';
export const EMAIL_STATUS_BOUNCED = 'bounced';
export const EMAIL_STATUS_COMPLAINT = 'complaint';
export const EMAIL_STATUS_FAILED = 'failed';
export const EMAIL_STATUS_OPEN = 'open';
export const EMAIL_STATUS_READY = 'ready';

export const EMAIL_STATUS_CODE_SENT = 3;
export const EMAIL_STATUS_CODE_BOUNCED = 5;
export const EMAIL_STATUS_CODE_COMPLAINT = 6;
export const EMAIL_STATUS_CODE_FAILED = 4;
export const EMAIL_STATUS_CODE_OPEN = 7;

export const EMAIL_STATUS_CODE_MAP = {
    [EMAIL_STATUS_CODE_SENT]: EMAIL_STATUS_SENT,
    [EMAIL_STATUS_CODE_BOUNCED]: EMAIL_STATUS_BOUNCED,
    [EMAIL_STATUS_CODE_COMPLAINT]: EMAIL_STATUS_COMPLAINT,
    [EMAIL_STATUS_CODE_FAILED]: EMAIL_STATUS_FAILED,
    [EMAIL_STATUS_CODE_OPEN]: EMAIL_STATUS_OPEN
};

export const SMS_STATUS_SENT = 'sent';
export const SMS_STATUS_FAILED = 'failed';

export const SCREENER_STUDY_ONLY = 0;
export const SCREENER_ANYONE = 1;
export const SCREENER_INTERNAL_ONLY = 2;
export const SCREENER_INACTIVE = 3;

export let SCREENER_WHO_CAN_TAKE = {};
SCREENER_WHO_CAN_TAKE[SCREENER_STUDY_ONLY] = 'Only people added to this study';
SCREENER_WHO_CAN_TAKE[SCREENER_ANYONE] = 'Anyone (even people outside my panel)';
SCREENER_WHO_CAN_TAKE[SCREENER_INTERNAL_ONLY] = 'Private (internal-facing screener)';
SCREENER_WHO_CAN_TAKE[SCREENER_INACTIVE] = 'Nobody (screener inactive)';

export const SCREENER_QUESTION_ERRORS_CLASSNAME = 'question-errors';
export const VALIDATION_TYPE_EMAIL = 'email';
export const VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL = 'continuous_sum_min_total';
// keep value "continuous_sum_total"  so its backwards compatible
export const VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL = 'continuous_sum_total';
export const VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL = 'continuous_sum_exact_total';
export const VALIDATION_TYPE_MATRIX_TEXT_NUMERIC = 'matrix_text_numeric';
export const VALIDATION_TYPE_REGEX = 'regex';
export const VALIDATION_REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const VALIDATION_MINIMUM = 'minimum';
export const VALIDATION_EXACT = 'exact';
export const VALIDATION_MAXIMUM = 'maximum';
export const MERGE_CODE_URL_REGEX = /\{url\(\"[a-zA-Z0-9_]+\"\)\}/g;

export const TAG_TYPE_PERSON = 'Person';
export const TAG_TYPE_STUDY = 'Study';
export const TAG_TYPES = [TAG_TYPE_PERSON, TAG_TYPE_STUDY];

export const PANEL_FILTER_TYPES = {
    panelfox_tag: 'Panelist Tag',
    panelfox_tag_exclude: 'Exclude Panelist Tag',
    panelfox_tag_study: 'Study Tag',
    panelfox_tag_study_exclude: 'Exclude Study Tag',
    email_status: 'Email Status'
};

export const PERSON_KEY_TYPE_STRING = 'string';
export const PERSON_KEY_TYPE_DATE = 'date';
export const PERSON_KEY_TYPE_BOOLEAN = 'boolean';
export const PERSON_KEY_TYPE_NUMBER = 'number';
export const PERSON_KEY_TYPE_DROPDOWN = 'dropdown';
export const PERSON_KEY_TYPE_CHECKBOX = 'checkbox';
export const PERSON_KEY_TYPE_TEXT = 'text';

export const PERSON_KEY_TYPES = [
    { type: PERSON_KEY_TYPE_STRING, title: 'Text' },
    { type: PERSON_KEY_TYPE_TEXT, title: 'Long text' },
    { type: PERSON_KEY_TYPE_DATE, title: 'Date' },
    { type: PERSON_KEY_TYPE_BOOLEAN, title: 'True / False' },
    { type: PERSON_KEY_TYPE_NUMBER, title: 'Number' },
    { type: PERSON_KEY_TYPE_DROPDOWN, title: 'Single-select (Dropdown)' },
    { type: PERSON_KEY_TYPE_CHECKBOX, title: 'Multi-select (Checkbox)' }
];

export const SP_STATUS_KEY_COMPLETED = 1;
export const SP_STATUS_KEY_CANCELLED = 2;
export const SP_STATUS_KEY_NO_SHOW = 3;
export const SP_STATUS_KEY_PAID_SENT = 4;
export const SP_STATUS_KEY_SCHEDULED = 5;
export const SP_STATUS_KEY_HOLD = 6;
export const SP_STATUS_KEY_CANCELLED_PARTICIPANT = 7;
export const SP_STATUS_KEY_PAYMENT_FAILED = 8;
let temp_SP_STATUS_KEY_TITLE = {
    0: ''
};
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_PAID_SENT] = 'Paid & Sent';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_COMPLETED] = 'Completed';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_SCHEDULED] = 'Scheduled';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_NO_SHOW] = 'No Show';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_CANCELLED] = 'Cancelled';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_CANCELLED_PARTICIPANT] = 'Dropped Out';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_HOLD] = 'Hold';
temp_SP_STATUS_KEY_TITLE[SP_STATUS_KEY_PAYMENT_FAILED] = 'Payment Failed';
export const SP_STATUS_KEY_TITLE = temp_SP_STATUS_KEY_TITLE;

export const STUDY_STATUS = [
    { value: 'all', title: 'All' },
    { value: 'active', title: 'Active' },
    { value: 'paused', title: 'Paused' },
    { value: 'complete', title: 'Complete' }
];
let ssm = {};
STUDY_STATUS.forEach(ss => {
    ssm[ss.value] = ss.title;
});
export const STUDY_STATUS_MAP = ssm;

export const STANDARD_FILTERS = [
    { id: 'to_be_invited', title: 'To Be Invited' },
    { id: 'invited', title: 'Invite Sent' },
    { id: 'any_screener', title: 'Any screener answered' },
    { id: 'no_screener', title: 'Screener not answered' }
    //{ id: 'scheduled', title: 'Scheduled' },
    //{ id: 'paid', title: 'Paid' }
];

export const COMPARE_FILTERS = {
    TEXT: [
        { id: 'contains', title: 'contains' },
        { id: 'does_not_contain', title: 'does not contain' },
        { id: 'is', title: 'is' },
        { id: 'is_not', title: 'is not' },
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' },
        { id: 'greater_than', title: 'greater than' },
        { id: 'less_than', title: 'less than' },
        { id: 'between', title: 'is between' }
    ],
    MULTI_SELECT: [
        { id: 'is_one_of', title: 'contains any of' },
        { id: 'is_all_of', title: 'contains all of' },
        { id: 'is_none_of', title: 'contains none of' },
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' }
    ],
    SINGLE_SELECT: [
        { id: 'is_one_of', title: 'contains any of' },
        { id: 'is_none_of', title: 'contains none of' },
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' }
    ],
    NUMBER: [
        { id: 'is', title: 'is' },
        { id: 'is_not', title: 'is not' },
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' },
        { id: 'greater_than', title: 'greater than' },
        { id: 'less_than', title: 'less than' },
        { id: 'between', title: 'is between' }
    ],
    DATE: [
        { id: 'on_date', title: 'on' },
        //{ id: 'is_not', title: 'is not' },
        { id: 'before_date', title: 'earlier than' },
        { id: 'after_date', title: 'later than' },
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' }
    ],
    BOOLEAN: [
        { id: 'is_known', title: 'has any value' },
        { id: 'is_unknown', title: 'is unknown' }
    ]
};

export const CURRENCY_USD = 'USD';
export const CURRENCY_EUR = 'EUR';
export const CURRENCY_GBP = 'GBP';
export const CURRENCY_AUD = 'AUD';
export const CURRENCY_SGD = 'SGD';
export const CURRENCY_POINTS = 'Points';

export const ACCOUNT_CURRENCIES = [CURRENCY_USD, CURRENCY_EUR, CURRENCY_GBP, CURRENCY_AUD, CURRENCY_SGD];
export const CURRENCIES = [...ACCOUNT_CURRENCIES, CURRENCY_POINTS];

export let CURRENCY_SYMBOL = {};
CURRENCY_SYMBOL[CURRENCY_USD] = '$';
CURRENCY_SYMBOL[CURRENCY_EUR] = '€';
CURRENCY_SYMBOL[CURRENCY_GBP] = '£';
CURRENCY_SYMBOL[CURRENCY_AUD] = '$';
CURRENCY_SYMBOL[CURRENCY_SGD] = '$';
CURRENCY_SYMBOL[CURRENCY_POINTS] = 'Points';

export const SCREENER_QDQS_ACCEPT = 'Accept';
export const SCREENER_QDQS_REJECT = 'Reject';

export const SCREENER_LOGIC_EQUAL = 'equal';
export const SCREENER_LOGIC_NOT_EQUAL = 'not_equal';
export const SCREENER_LOGIC_LESS_THAN = 'less_than';
export const SCREENER_LOGIC_GREATER_THAN = 'greater_than';
export const SCREENER_LOGIC_DATE_BEFORE = 'before_date';
export const SCREENER_LOGIC_DATE_AFTER = 'after_date';
export const SCREENER_LOGIC_ANSWERED = 'is_answered';
export const SCREENER_LOGIC_NOT_ANSWERED = 'is_not_answered';
export const SCREENER_LOGIC_IN_LIST = 'in_list'; // comma separated list (e.g. 1,2,3,4)
export const SCREENER_LOGIC_NOT_IN_LIST = 'not_in_list'; // comma separated list (e.g. 1,2,3,4)
export const SCREENER_LOGIC_CONTAINS_ANY_OF = 'contains_any_of'; // array of values (e.g. [1, 2, 3, 4])
export const SCREENER_LOGIC_NOT_CONTAINS_ANY_OF = 'not_contains_any_of'; // array of values (e.g. [1, 2, 3, 4])

export const SCREENER_LOGIC_COMPARE = {
    TEXT: [
        { id: SCREENER_LOGIC_EQUAL, title: 'equal to' },
        { id: SCREENER_LOGIC_NOT_EQUAL, title: 'NOT equal to' },
        { id: SCREENER_LOGIC_GREATER_THAN, title: 'greater than' },
        { id: SCREENER_LOGIC_LESS_THAN, title: 'less than' },
        { id: SCREENER_LOGIC_ANSWERED, title: 'is answered' },
        { id: SCREENER_LOGIC_NOT_ANSWERED, title: 'is NOT answered' },
        { id: SCREENER_LOGIC_IN_LIST, title: 'in comma-separated list' },
        { id: SCREENER_LOGIC_NOT_IN_LIST, title: 'NOT in comma-separated list' }
    ],
    WITH_OPTIONS: [
        { id: SCREENER_LOGIC_EQUAL, title: 'equal to' },
        { id: SCREENER_LOGIC_NOT_EQUAL, title: 'NOT equal to' },
        { id: SCREENER_LOGIC_CONTAINS_ANY_OF, title: 'contains any of' },
        { id: SCREENER_LOGIC_NOT_CONTAINS_ANY_OF, title: 'contains NONE of' },
        { id: SCREENER_LOGIC_ANSWERED, title: 'is answered' },
        { id: SCREENER_LOGIC_NOT_ANSWERED, title: 'is NOT answered' }
    ],
    DATE: [
        { id: SCREENER_LOGIC_DATE_BEFORE, title: 'before' },
        { id: SCREENER_LOGIC_DATE_AFTER, title: 'after' },
        { id: SCREENER_LOGIC_ANSWERED, title: 'is answered' },
        { id: SCREENER_LOGIC_NOT_ANSWERED, title: 'is NOT answered' }
    ]
};

export const SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY = 'disqualify';
export const SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED = 'proceed';

export const SCREENER_PAGE_LOGIC_STANDARD = 'standard';
export const SCREENER_PAGE_LOGIC_QUALIFY = 'qualify';
export const SCREENER_PAGE_LOGIC_DISQUALIFY = 'disqualify';
export const SCREENER_PAGE_LOGIC_SHOW = 'show';
export const SCREENER_PAGE_LOGIC_HIDE = 'hide';

export const EVENT_API_DOWN = 'xhr.api_down';
export const EVENT_API_LOGGED_OUT = 'xhr.logged_out';

export const MESSAGE_API_DOWN =
    'We are in maintenance mode, or the server is temporarily down. The service will be back soon.';

export const SP_PARTICIPANT_STATUS = 'participant_status';
export const SP_PARTICIPANT_SESSION = 'participant_session';
export const SP_PARTICIPANT_LOCATION = 'participant_location';

export const SCREENER_Q_DQ_REDIRECT_PREFIX = 'redirect_page_number_';

export const MOMENT_HUMAN_DATE = 'MMM Do, YYYY [at] h:mma (z)';
export const MOMENT_HUMAN_DATE_NOTZ = 'MMM Do, YYYY [at] h:mma';
export const MOMENT_HUMAN_DATE_NO_TZ = 'MMM Do, YYYY [at] h:mm A';
export const MOMENT_HUMAN_DATE_NO_TZ_SHORT = 'MM-DD-YYYY, h:mm A';

export const STUDY_TABLE_COLUMN_ASSIGNED = { id: 'assigned', title: 'Assigned' };
export const STUDY_TABLE_COLUMN_SPOT = { id: 'spot', title: 'Session' };
export const STUDY_TABLE_COLUMN_CONFIRMED = { id: 'confirmed', title: 'Confirmed' };
export const STUDY_TABLE_COLUMN_LOCATION = { id: 'location', title: 'Location' };
export const STUDY_TABLE_COLUMN_PAID = { id: 'paid', title: 'Paid' };
export const STUDY_TABLE_COLUMN_LAST_CONTACTED = { id: 'last_contacted_at', title: COL_LAST_CONTACTED_AT };

export const MAX_EMAIL_ATTACHMENTS_SIZE = 35000000; // 35 MB

export const ANY_ALL_TO_OR_AND = {
    all: 'and',
    any: 'or'
};

export const PANELIST_TYPE_PURCHASED_LIST = 'purchased';
export const PANELIST_TYPE_CLIENT_LIST = 'client-list';
export const PANELIST_TYPE_OPTED_IN = 'opted-in';

/* FYI `table_order` instead of `order`, because `order` is used for sorting
    properties on the panelist profile page
    and `table_order` is used to sort columns in the Panel table
*/
export const PROPERTY_TABLE_ORDER = 'table_order';

export const OPTION_ORDER_OFF = { id: 'regular', value: 0, title: 'Regular' };
export const OPTION_ORDER_RADOMIZE = { id: 'randomize', value: 1, title: 'Randomize' };
export const OPTION_ORDER_FLIP = { id: 'flip', value: 2, title: 'Flip' };

export const TIMETRAVEL_UNDO = { type: 'undo', label: 'Undo' };
export const TIMETRAVEL_REDO = { type: 'redo', label: 'Redo' };

export const QUILL_MODULES = {
    toolbar: [
        { header: [1, 2, 3, false] },
        'bold',
        'italic',
        'underline',
        'strike',
        'blockquote',
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' },
        {
            color: [
                '#001233',
                '#e60000',
                '#ff9900',
                '#ffff00',
                '#008a00',
                '#0066cc',
                '#9933ff',
                '#ffffff',
                '#facccc',
                '#ffebcc',
                '#ffffcc',
                '#cce8cc',
                '#cce0f5',
                '#ebd6ff',
                '#D4D9DD',
                '#f06666',
                '#ffc266',
                '#ffff66',
                '#66b966',
                '#66a3e0',
                '#c285ff',
                '#929DB0',
                '#a10000',
                '#b26b00',
                '#b2b200',
                '#006100',
                '#0047b2',
                '#6b24b2',
                '#606E85',
                '#5c0000',
                '#663d00',
                '#666600',
                '#003700',
                '#002966',
                '#3d1466'
            ]
        },
        'link',
        'clean',
        'video'
    ]
};

export const countryListData = [
    { label: 'Afghanistan', value: 'AF' },
    { label: 'Albania', value: 'AL' },
    { label: 'Algeria', value: 'DZ' },
    { label: 'American Samoa', value: 'AS' },
    { label: 'Andorra', value: 'AD' },
    { label: 'Angola', value: 'AO' },
    { label: 'Anguilla', value: 'AI' },
    { label: 'Antarctica', value: 'AQ' },
    { label: 'Antigua and Barbuda', value: 'AG' },
    { label: 'Argentina', value: 'AR' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Aruba', value: 'AW' },
    { label: 'Australia', value: 'AU' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaijan', value: 'AZ' },
    { label: 'Bahamas', value: 'BS' },
    { label: 'Bahrain', value: 'BH' },
    { label: 'Bangladesh', value: 'BD' },
    { label: 'Barbados', value: 'BB' },
    { label: 'Belarus', value: 'BY' },
    { label: 'Belgium', value: 'BE' },
    { label: 'Belize', value: 'BZ' },
    { label: 'Benin', value: 'BJ' },
    { label: 'Bermuda', value: 'BM' },
    { label: 'Bhutan', value: 'BT' },
    { label: 'Bolivia (Plurinational State of)', value: 'BO' },
    { label: 'Bonaire, Sint Eustatius and Saba', value: 'BQ' },
    { label: 'Bosnia and Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bouvet Island', value: 'BV' },
    { label: 'Brazil', value: 'BR' },
    { label: 'British Indian Ocean Territory', value: 'IO' },
    { label: 'Brunei Darussalam', value: 'BN' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Burkina Faso', value: 'BF' },
    { label: 'Burundi', value: 'BI' },
    { label: 'Cabo Verde', value: 'CV' },
    { label: 'Cambodia', value: 'KH' },
    { label: 'Cameroon', value: 'CM' },
    { label: 'Canada', value: 'CA' },
    { label: 'Cayman Islands', value: 'KY' },
    { label: 'Central African Republic', value: 'CF' },
    { label: 'Chad', value: 'TD' },
    { label: 'Chile', value: 'CL' },
    { label: 'China', value: 'CN' },
    { label: 'Christmas Island', value: 'CX' },
    { label: 'Cocos (Keeling) Islands', value: 'CC' },
    { label: 'Colombia', value: 'CO' },
    { label: 'Comoros', value: 'KM' },
    { label: 'Congo', value: 'CG' },
    { label: 'Congo, Democratic Republic of the', value: 'CD' },
    { label: 'Cook Islands', value: 'CK' },
    { label: 'Costa Rica', value: 'CR' },
    { label: 'Croatia', value: 'HR' },
    { label: 'Cuba', value: 'CU' },
    { label: 'Cura\u00e7ao', value: 'CW' },
    { label: 'Cyprus', value: 'CY' },
    { label: 'Czechia', value: 'CZ' },
    { label: "C\u00f4te d'Ivoire", value: 'CI' },
    { label: 'Denmark', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Dominica', value: 'DM' },
    { label: 'Dominican Republic', value: 'DO' },
    { label: 'Ecuador', value: 'EC' },
    { label: 'Egypt', value: 'EG' },
    { label: 'El Salvador', value: 'SV' },
    { label: 'Equatorial Guinea', value: 'GQ' },
    { label: 'Eritrea', value: 'ER' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Eswatini', value: 'SZ' },
    { label: 'Ethiopia', value: 'ET' },
    { label: 'Falkland Islands (Malvinas)', value: 'FK' },
    { label: 'Faroe Islands', value: 'FO' },
    { label: 'Fiji', value: 'FJ' },
    { label: 'Finland', value: 'FI' },
    { label: 'France', value: 'FR' },
    { label: 'French Guiana', value: 'GF' },
    { label: 'French Polynesia', value: 'PF' },
    { label: 'French Southern Territories', value: 'TF' },
    { label: 'Gabon', value: 'GA' },
    { label: 'Gambia', value: 'GM' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Germany', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Gibraltar', value: 'GI' },
    { label: 'Great Britain', value: 'GB' },
    { label: 'Greece', value: 'GR' },
    { label: 'Greenland', value: 'GL' },
    { label: 'Grenada', value: 'GD' },
    { label: 'Guadeloupe', value: 'GP' },
    { label: 'Guam', value: 'GU' },
    { label: 'Guatemala', value: 'GT' },
    { label: 'Guernsey', value: 'GG' },
    { label: 'Guinea', value: 'GN' },
    { label: 'Guinea-Bissau', value: 'GW' },
    { label: 'Guyana', value: 'GY' },
    { label: 'Haiti', value: 'HT' },
    { label: 'Heard Island and McDonald Islands', value: 'HM' },
    { label: 'Holy See', value: 'VA' },
    { label: 'Honduras', value: 'HN' },
    { label: 'Hong Kong', value: 'HK' },
    { label: 'Hungary', value: 'HU' },
    { label: 'Iceland', value: 'IS' },
    { label: 'India', value: 'IN' },
    { label: 'Indonesia', value: 'ID' },
    { label: 'Iran (Islamic Republic of)', value: 'IR' },
    { label: 'Iraq', value: 'IQ' },
    { label: 'Ireland', value: 'IE' },
    { label: 'Isle of Man', value: 'IM' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italy', value: 'IT' },
    { label: 'Jamaica', value: 'JM' },
    { label: 'Japan', value: 'JP' },
    { label: 'Jersey', value: 'JE' },
    { label: 'Jordan', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenya', value: 'KE' },
    { label: 'Kiribati', value: 'KI' },
    { label: "Korea (Democratic People's Republic of)", value: 'KP' },
    { label: 'Korea, Republic of', value: 'KR' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kyrgyzstan', value: 'KG' },
    { label: "Lao People's Democratic Republic", value: 'LA' },
    { label: 'Latvia', value: 'LV' },
    { label: 'Lebanon', value: 'LB' },
    { label: 'Lesotho', value: 'LS' },
    { label: 'Liberia', value: 'LR' },
    { label: 'Libya', value: 'LY' },
    { label: 'Liechtenstein', value: 'LI' },
    { label: 'Lithuania', value: 'LT' },
    { label: 'Luxembourg', value: 'LU' },
    { label: 'Macao', value: 'MO' },
    { label: 'Madagascar', value: 'MG' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Malaysia', value: 'MY' },
    { label: 'Maldives', value: 'MV' },
    { label: 'Mali', value: 'ML' },
    { label: 'Malta', value: 'MT' },
    { label: 'Marshall Islands', value: 'MH' },
    { label: 'Martinique', value: 'MQ' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauritius', value: 'MU' },
    { label: 'Mayotte', value: 'YT' },
    { label: 'Mexico', value: 'MX' },
    { label: 'Micronesia (Federated States of)', value: 'FM' },
    { label: 'Moldova, Republic of', value: 'MD' },
    { label: 'Monaco', value: 'MC' },
    { label: 'Mongolia', value: 'MN' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Montserrat', value: 'MS' },
    { label: 'Morocco', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Myanmar', value: 'MM' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Nauru', value: 'NR' },
    { label: 'Nepal', value: 'NP' },
    { label: 'Netherlands', value: 'NL' },
    { label: 'New Caledonia', value: 'NC' },
    { label: 'New Zealand', value: 'NZ' },
    { label: 'Nicaragua', value: 'NI' },
    { label: 'Niger', value: 'NE' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Niue', value: 'NU' },
    { label: 'Norfolk Island', value: 'NF' },
    { label: 'North Macedonia', value: 'MK' },
    { label: 'Northern Mariana Islands', value: 'MP' },
    { label: 'Norway', value: 'NO' },
    { label: 'Oman', value: 'OM' },
    { label: 'Pakistan', value: 'PK' },
    { label: 'Palau', value: 'PW' },
    { label: 'Palestine, State of', value: 'PS' },
    { label: 'Panama', value: 'PA' },
    { label: 'Papua New Guinea', value: 'PG' },
    { label: 'Paraguay', value: 'PY' },
    { label: 'Peru', value: 'PE' },
    { label: 'Philippines', value: 'PH' },
    { label: 'Pitcairn', value: 'PN' },
    { label: 'Poland', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Qatar', value: 'QA' },
    { label: 'Romania', value: 'RO' },
    { label: 'Russian Federation', value: 'RU' },
    { label: 'Rwanda', value: 'RW' },
    { label: 'R\u00e9union', value: 'RE' },
    { label: 'Saint Barth\u00e9lemy', value: 'BL' },
    { label: 'Saint Helena, Ascension and Tristan da Cunha', value: 'SH' },
    { label: 'Saint Kitts and Nevis', value: 'KN' },
    { label: 'Saint Lucia', value: 'LC' },
    { label: 'Saint Martin (French part)', value: 'MF' },
    { label: 'Saint Pierre and Miquelon', value: 'PM' },
    { label: 'Saint Vincent and the Grenadines', value: 'VC' },
    { label: 'Samoa', value: 'WS' },
    { label: 'San Marino', value: 'SM' },
    { label: 'Sao Tome and Principe', value: 'ST' },
    { label: 'Saudi Arabia', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Sierra Leone', value: 'SL' },
    { label: 'Singapore', value: 'SG' },
    { label: 'Sint Maarten (Dutch part)', value: 'SX' },
    { label: 'Slovakia', value: 'SK' },
    { label: 'Slovenia', value: 'SI' },
    { label: 'Solomon Islands', value: 'SB' },
    { label: 'Somalia', value: 'SO' },
    { label: 'South Africa', value: 'ZA' },
    { label: 'South Georgia and the South Sandwich Islands', value: 'GS' },
    { label: 'South Sudan', value: 'SS' },
    { label: 'Spain', value: 'ES' },
    { label: 'Sri Lanka', value: 'LK' },
    { label: 'Sudan', value: 'SD' },
    { label: 'Suriname', value: 'SR' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ' },
    { label: 'Sweden', value: 'SE' },
    { label: 'Switzerland', value: 'CH' },
    { label: 'Syrian Arab Republic', value: 'SY' },
    { label: 'Taiwan, Province of China', value: 'TW' },
    { label: 'Tajikistan', value: 'TJ' },
    { label: 'Tanzania, United Republic of', value: 'TZ' },
    { label: 'Thailand', value: 'TH' },
    { label: 'Timor-Leste', value: 'TL' },
    { label: 'Togo', value: 'TG' },
    { label: 'Tokelau', value: 'TK' },
    { label: 'Tonga', value: 'TO' },
    { label: 'Trinidad and Tobago', value: 'TT' },
    { label: 'Tunisia', value: 'TN' },
    { label: 'Turkmenistan', value: 'TM' },
    { label: 'Turks and Caicos Islands', value: 'TC' },
    { label: 'Tuvalu', value: 'TV' },
    { label: 'T\u00fcrkiye', value: 'TR' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ukraine', value: 'UA' },
    { label: 'United Arab Emirates', value: 'AE' },
    { label: 'United States Minor Outlying Islands', value: 'UM' },
    { label: 'United States of America', value: 'US' },
    { label: 'Uruguay', value: 'UY' },
    { label: 'Uzbekistan', value: 'UZ' },
    { label: 'Vanuatu', value: 'VU' },
    { label: 'Venezuela (Bolivarian Republic of)', value: 'VE' },
    { label: 'Viet Nam', value: 'VN' },
    { label: 'Virgin Islands (British)', value: 'VG' },
    { label: 'Virgin Islands (U.S.)', value: 'VI' },
    { label: 'Wallis and Futuna', value: 'WF' },
    { label: 'Western Sahara', value: 'EH' },
    { label: 'Yemen', value: 'YE' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabwe', value: 'ZW' }
];

export const SCREENER_QUOTA_TYPE_VALUE_ORQ = 'orq';
export const SCREENER_QUOTA_TYPE_VALUE_LQ = 'lq';

export const SCREENER_QUOTA_TYPES = [
    {
        label: 'Overall Response Quota',
        value: SCREENER_QUOTA_TYPE_VALUE_ORQ,
        description:
            'Limit the total number of completed responses for your survey. Once that quota is met, your respondents will no longer be permitted to submit a response. Example: 100 maximum responses.',
        enabled: true
    },
    {
        label: 'Logic Quota',
        value: SCREENER_QUOTA_TYPE_VALUE_LQ,
        description:
            'Collect a limited set of responses for people who match a specific set of logic. Example: 10 males, and 10 females.',
        enabled: true
    }
];

export const SURVEY_QUOTA_FILTER_MAX_RESPONSES = 'max_responses';

export const SURVEY_QUOTA_MSG_MAX = 'Sorry, the maximum number of responses has been met. Thanks for your time!';

export const INTEGRATION_DSCOUT = 4;
export const INTEGRATION_SALESFORCE = 1;

export const INTEGRATION_DIRECTION_IN = 0;
export const INTEGRATION_DIRECTION_OUT = 1;
export const INTEGRATION_DIRECTION_BOTH = 2;

export const SCHEDULING_SESSION_CHANGE_NOT_ALLOWED = 0;
export const SCHEDULING_SESSION_CHANGE_ALLOWED = 1;

export const PAID_FEATURE = Object.freeze({
    EMAILING: 'emailing',
    SMS_MASS: 'sms-mass',
    SURVEY_VIDEO: 'survey-video',
    DSCOUT_INTEGRATION: 'dscout-integration',
    MULTIPLE_PANELS: 'multiple-panels',
    API_ACCESS: 'api-access',
    FIRST_PARTY_INCENIVES: 'first-party-incentives'
});

export const FORM_TYPE_STANDARD = { id: 1, title: 'Standard' };
export const FORM_TYPE_CONSENT = { id: 2, title: 'Consent' };
export const FORM_TYPES = [FORM_TYPE_STANDARD, FORM_TYPE_CONSENT];

export const EMAIL_TEMPLATE_TYPE = Object.freeze({
    PLAIN: 0,
    BLOCKS: 1,
    HTML: 2
});

export const FALSY_FORM_VALUES = ['No', 'False', 'no', 'false', '0', 0];
export const TRUTHY_FORM_VALUES = ['Yes', 'True', 'yes', 'true', '1', 1];
export const BOOLEAN_FORM_VALUES = [...FALSY_FORM_VALUES, ...TRUTHY_FORM_VALUES];

export const ALLOWED_SALESFORCE_MAPPERS = {
    picklist: [PERSON_KEY_TYPE_DROPDOWN],
    reference: [PERSON_KEY_TYPE_DROPDOWN],
    multipicklist: [PERSON_KEY_TYPE_CHECKBOX],
    boolean: [PERSON_KEY_TYPE_BOOLEAN],
    double: [PERSON_KEY_TYPE_STRING],
    int: [PERSON_KEY_TYPE_NUMBER],
    string: [PERSON_KEY_TYPE_STRING],
    textarea: [PERSON_KEY_TYPE_TEXT],
    datetime: [PERSON_KEY_TYPE_DATE],
    date: [PERSON_KEY_TYPE_DATE],
    id: [PERSON_KEY_TYPE_STRING],
    address: [PERSON_KEY_TYPE_STRING],
    phone: [PERSON_KEY_TYPE_STRING],
    email: [PERSON_KEY_TYPE_STRING],
    url: [PERSON_KEY_TYPE_STRING]
};

export const PAGINATION_TYPE = Object.freeze({
    LOAD_MORE_BUTTON: 'LOAD_MORE_BUTTON',
    NUMBERED: 'NUMBERED'
});
export const FEATURE_CONFIG = {
    PAGINATION_TYPE: PAGINATION_TYPE.NUMBERED
};

export const TWO_WAY_SYNC_INPUT_TYPE_COMPATIBILITY = Object.freeze({
    TextArea: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER],
    TextInput: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER],
    HiddenValue: [
        PERSON_KEY_TYPE_STRING,
        PERSON_KEY_TYPE_TEXT,
        PERSON_KEY_TYPE_NUMBER,
        PERSON_KEY_TYPE_DATE,
        PERSON_KEY_TYPE_BOOLEAN
    ],
    DatePicker: [PERSON_KEY_TYPE_DATE, PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT],
    Dropdown: [PERSON_KEY_TYPE_DROPDOWN, PERSON_KEY_TYPE_BOOLEAN],
    RadioButtons: [PERSON_KEY_TYPE_DROPDOWN, PERSON_KEY_TYPE_BOOLEAN],
    NumberInput: [PERSON_KEY_TYPE_NUMBER, PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT],
    Checkboxes: [PERSON_KEY_TYPE_CHECKBOX],
    RangeElement: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER],
    Rating: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER],
    Camera: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT],
    Captcha: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER, PERSON_KEY_TYPE_BOOLEAN],
    FileUpload: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT],
    AddPipeInput: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT]
});

export const INCENTIVE_ACTION = Object.freeze({
    ADD: 1,
    REDEEM: 2
});

export const INCENTIVE_PAYMENT_METHOD = Object.freeze({
    MARK_AS_PAID: 'mark_as_paid',
    TREMENDOUS: 'tremendous',
    FIRST_PARTY: 'first_party'
});

export const INCENTIVE_PAYMENT_METHOD_LABEL = Object.freeze({
    MARK_AS_PAID: 'Mark as paid (no incentive is sent)',
    MARK_AS_PAID_BUTTON: 'Mark as paid',
    TREMENDOUS: 'Tremendous',
    TREMENDOUS_BUTTON: 'Pay with Tremendous',
    TREMENDOUS_MODAL_TITLE: 'Pay Incentive with Tremendous',
    FIRST_PARTY: 'Incentive balance',
    FIRST_PARTY_BUTTON: 'Pay using Incentive balance',
    FIRST_PARTY_MODAL_TITLE: 'Pay Incentive'
});

export const USER_ROLE = Object.freeze({
    ADMIN: 'admin',
    USER: 'user',
    VIEW: 'view'
});

export const SMS_SUBSCRIPTION_DEFAULT = 0; // we charge customers for SMS usage
export const SMS_SUBSCRIPTION_UNLIMITED = 1; // SMS is free and included in the plan

export const NOTIFICATION_TITLE_DISABLED_EMAILING = 'Email sending is not enabled for your account';
export const NOTIFICATION_CONTENT_DISABLED_EMAILING = (
    <>
        Please contact <a href="mailto:support@panelfox.io">support@panelfox.io</a> to enable email sending.
    </>
);

export const NOTIFICATION_TITLE_HTML_NOT_ALLOWED =
    'HTML Template sending is not allowed without a verified email domain';
export const NOTIFICATION_CONTENT_HTML_NOT_ALLOWED = (
    <>
        A verified email domain is required to send HTML Templates. Follow the guide{' '}
        <a href="https://docs.panelfox.io/article/118-email-whitelabeling-domains" target="_blank">
            here
        </a>
        .
    </>
);

export const SESSION_HISTORY_STATUS = Object.freeze({
    STATUS_SCHEDULED: 0,
    STATUS_RESCHEDULED: 1,
    STATUS_CANCELLED: 2,
    STATUS_NOSHOW: 3
});

export const ACTIVITY_TYPE = Object.freeze({
    SMS: 'SMS',
    EMAIL: 'EMAIL',
    SESSION: 'SESSION'
});

export const ANNUAL_EMAIL_USAGE = 1;
export const NON_ANNUAL_EMAIL_USAGE = 0;
export const PANEL_DASHBOARD_TYPE_STOCK = 1; // Basic dashboard statistics
export const PANEL_DASHBOARD_TYPE_PDK = 2; // Personal Data Key

export const SCREENER_TYPE = Object.freeze({
    PANELFOX: 'panelfox',
    QUALTRICS: 'qualtrics'
});

export const STUDY_LOCATION_TYPE = Object.freeze({
    ZOOM: 'zoom',
    MEET: 'meet',
    MSTEAMS: 'msteams',
    CUSTOM: 'custom'
});

export const QUOTA_WHEN_OPTIONS = Object.freeze({
    IS_SCHEDULED: 'is_scheduled',
    SCREENER_ANSWERED: 'screener_response'
});

export const QUOTA_WHEN_OPTION_LABELS = Object.freeze({
    IS_SCHEDULED: 'Anyone is Scheduled',
    SCREENER_ANSWERED: 'Screener is Answered'
});

export const STUDY_AVAILABILITY_TYPES = Object.freeze({
    SESSIONS: 'sessions',
    RANGES: 'ranges'
});

export const PANEL_DELETE_PANELISTS = Object.freeze({
    NONE: 0,
    CURRENT: 1,
    ALL: 2
});

export const IS_QUALIFIED = Object.freeze({
    NO: 0,
    YES: 1,
    OVER_QUOTA: 2
});
