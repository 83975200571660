import React from 'react';
import ReactDOM from 'react-dom';
import EventEmitter from 'utils/events';
import { cloneDeep, shuffle } from 'lodash';
import { Button, Box } from 'rebass';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import classNames from 'classnames';
import {
    SCREENER_PAGE_LOGIC_QUALIFY,
    SCREENER_PAGE_LOGIC_DISQUALIFY,
    SCREENER_PAGE_LOGIC_STANDARD,
    SCREENER_PAGE_LOGIC_SHOW,
    VALIDATION_TYPE_EMAIL,
    VALIDATION_REGEX_EMAIL,
    VALIDATION_TYPE_REGEX,
    VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL,
    VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL,
    VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL,
    VALIDATION_TYPE_MATRIX_TEXT_NUMERIC,
    SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED,
    SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY,
    SCREENER_Q_DQ_REDIRECT_PREFIX,
    COL_FIRST_NAME,
    COL_LAST_NAME,
    COL_EMAIL,
    OPTION_ORDER_RADOMIZE,
    OPTION_ORDER_FLIP,
    VALIDATION_MINIMUM,
    VALIDATION_MAXIMUM,
    VALIDATION_EXACT,
    MERGE_CODE_URL_REGEX,
    IS_QUALIFIED
} from 'utils/constants';
import FormValidator from './form-validator';
import FormElements from './form-elements';
import { FiGitMerge } from 'react-icons/fi';
import Badge from 'components/Badge';
const { Image, Checkboxes, Matrix, Signature, Download, Camera, RadioButtons, RangeElement, Captcha } = FormElements;

export default class ReactForm extends React.Component {
    form;

    inputs = {};

    answerData;

    /**
     * @param {Object} props
     * @param {boolean} props.[isOnePage]
     * @param {boolean} props.[show_qualification]
     * @param {boolean} props.[read_only]
     * @param {boolean} props.[hide_actions]
     */
    constructor(props) {
        super(props);
        this.answerData = this._convert(props.answer_data);
        //this.emitter = new EventEmitter();
        this.isCurrentPageValid = this.isCurrentPageValid.bind(this);
        this.validateForm = this.validateForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.goToNextPage = this.goToNextPage.bind(this);

        let current_page = 1;
        if (this.props.is_preview && this.props.is_preview_start_page) {
            current_page = this.props.is_preview_start_page;
        }

        this.state = {
            current_page: current_page,
            answers_by_form_element_name: this.props.answers_by_form_element_name
                ? this.props.answers_by_form_element_name
                : {},
            pageData: this.props.data,
            errorMsgs: {}
        };
    }

    componentDidMount() {
        EventEmitter.subscribe('screener.getItemValue', data => {
            //console.log(data);
            data.eventCallback(this._collect(data.item));
            //EventEmitter.dispatch('screener.getItemValue.answer', { value:  });
        });

        // this re-renders the component after it's first render,
        // so that the "show/hide" question-level logic gets re-executed,
        // since its based on finding answers via DOM, and the first render
        // doesn't allow these questions to be found
        this.setState({});

        const sortedPageData = this.state.pageData;
        sortedPageData.map(page_items => {
            page_items.map(item => {
                if (item.option_order !== undefined) item.options = this.sortOptions(item.options, item.option_order);
            });
        });

        this.setState({ pageData: sortedPageData });
    }

    _convert(answer_pages) {
        //console.log('answer_pages', answer_pages)
        if (Array.isArray(answer_pages)) {
            const result = {};
            answer_pages.forEach(page_answers => {
                if (Array.isArray(page_answers)) {
                    page_answers.forEach(x => {
                        if (x.name.indexOf('tags_') > -1) {
                            result[x.name] = x.value.map(y => y.value);
                        } else {
                            result[x.name] = x.value;
                        }

                        if (x.value_add_other) {
                            result[x.name + '_add_other'] = x.value_add_other;
                        }
                    });
                }
            });

            return result;
        }
        return answer_pages || {};
    }

    // use case: panelist exists, but they can change their email/first name/last name
    // solution: this function prevents changing 3 panelist properties if they are already set
    _checkShouldBeDisabled(item) {
        let should_be_disabled = false;
        if (
            item.save_to_panel &&
            item.personCustomDataId &&
            item.personCustomDataId > 0 &&
            this.props.person_defaults
        ) {
            // look for what type this is
            if (this.props.person && this.props.person.custom_data_keys) {
                const found_cdk = this.props.person.custom_data_keys.find(cdk => cdk.id == item.personCustomDataId);
                if (found_cdk) {
                    if ([COL_EMAIL, COL_FIRST_NAME, COL_LAST_NAME].includes(found_cdk.title)) {
                        const panelist_value = this._getDefaultValue(item);
                        if (panelist_value) {
                            // if its an an email & its invalid, allow change
                            if (
                                COL_EMAIL == found_cdk.title &&
                                !helpersScreener.validateRegex(panelist_value, VALIDATION_REGEX_EMAIL)
                            ) {
                                should_be_disabled = false;
                            } else {
                                should_be_disabled = true;
                            }
                        } else {
                            // allow them to edit a blank value
                        }
                    }
                }
            }
        }
        return should_be_disabled;
    }

    _getDefaultValue(item) {
        if (this.answerData && this.answerData[item.field_name]) {
            return this.answerData[item.field_name];
        }

        if (
            item.save_to_panel &&
            item.personCustomDataId &&
            item.personCustomDataId > 0 &&
            this.props.person_defaults
        ) {
            const hasDefault = this.props.person_defaults.hasOwnProperty(item.personCustomDataId);
            let found_cdv = undefined;

            if (hasDefault) {
                found_cdv = this.props.person_defaults[item.personCustomDataId];
            }

            if (hasDefault && item.element === 'HiddenValue') {
                const isBlankValue = item.default_value === '' || item.default_value == null;

                // if the default value is not blank, then we should use value defined in a screener
                if (!isBlankValue) {
                    found_cdv = item.default_value;
                }
            }

            return found_cdv;
        }
    }

    _optionsDefaultValue(item) {
        const defaultValue = this._getDefaultValue(item);
        if (defaultValue) {
            return defaultValue;
        }

        const defaultChecked = [];
        item.options.forEach(option => {
            if (this.answerData[`option_${option.key}`]) {
                defaultChecked.push(option.key);
            }
        });
        return defaultChecked;
    }

    _optionsDefaultValueOther(item) {
        return this.answerData[item.field_name + '_add_other'];
    }

    _getItemValue(item, ref) {
        let $item = {
            element: item.element,
            value: ''
        };
        //console.log('REF', ref.inputField.current);
        if (ref && ref.inputField.current) {
            if (item.element === 'Rating') {
                $item.value = ref.inputField.current.state.rating;
            } else if (item.element === 'Tags') {
                $item.value = ref.inputField.current.state.value;
            } /* else if (item.element === 'RangeElement') {
                console.log('RANGE ref.inputField.current', ref.inputField.current)
                $item.value = ref.inputField.current.state.value;
            } */ else if (
                item.element === 'DatePicker'
            ) {
                $item.value = ref.state.value;
            } else if (item.element === 'Camera') {
                $item.value = ref.state.img ? ref.state.img.replace('data:image/png;base64,', '') : '';
            } else if (ref && ref.inputField) {
                $item = ReactDOM.findDOMNode(ref.inputField.current);
                if (typeof $item.value === 'string') {
                    $item.value = $item.value.trim();
                }
            }
        }
        return $item;
    }

    _isIncorrect(item) {
        let incorrect = false;
        if (item.canHaveAnswer) {
            const ref = this.inputs[item.field_name];

            if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
                item.options.forEach(option => {
                    const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
                    if (
                        option.hasOwnProperty('correct') &&
                        !$option.checked
                        //|| (!option.hasOwnProperty('correct') && $option.checked)
                    ) {
                        incorrect = true;
                    }
                });
            } else if (item.element === 'Dropdown') {
                const item_value = this._getItemValue(item, ref).value;

                //console.log('VALIDATING DD', item, item_value);

                let found_correct_answer = false;
                item.options.forEach(option => {
                    if (option.hasOwnProperty('correct') && item_value == option.value) {
                        found_correct_answer = true;
                    }
                });

                incorrect = !found_correct_answer;
            } else {
                const $item = this._getItemValue(item, ref);

                if (item.element === 'Rating') {
                    if ($item.value.toString() !== item.correct) {
                        incorrect = true;
                    }
                } else if ($item.value.toLowerCase() !== item.correct.trim().toLowerCase()) {
                    incorrect = true;
                }
            }
        }
        return incorrect;
    }

    _isValidFailed(item) {
        let invalid = false;
        const page_number = this.state.current_page;
        const page_index = page_number - 1;
        const pages = this.props.data;
        const should_show = helpersScreener.checkQuestionLogic(
            item,
            pages,
            page_index,
            this.state.answers_by_form_element_name
        );

        let ref;
        if (this.inputs && item && item.field_name) {
            ref = this.inputs[item.field_name];
        }

        const clonedErrorMsgs = this.state.errorMsgs;
        // check validation!
        if (item.validation === true && !item.static && should_show) {
            if (helpersScreener.shouldShowValidationOptions(item)) {
                if (item.validation_rules) {
                    //console.log('item.validation_rules', item.validation_rules);

                    const collect = this._collect(item, ref);
                    const item_value = collect.value;
                    /*console.log(collect);
                    const $item = this._getItemValue(item, ref);
                    const item_value = $item.value;*/

                    //console.log('validation value', item_value)
                    if (item_value) {
                        item.validation_rules.forEach(v_rule => {
                            // initialize invalid flag variable for individual rule, v_rule (not validation_rules)
                            // can be used later to show individual rule failure
                            let v_rule_invalid = false;
                            let validation_msg = 'Invalid';
                            if (v_rule.validation_type == VALIDATION_TYPE_EMAIL) {
                                const regex_matches = helpersScreener.validateRegex(item_value, VALIDATION_REGEX_EMAIL);
                                if (!regex_matches) {
                                    invalid = true;
                                    v_rule_invalid = true;
                                    validation_msg = 'Must be a valid email';
                                }
                            } else if (v_rule.validation_type == VALIDATION_TYPE_REGEX) {
                                if (v_rule.validation_value) {
                                    const regex_matches = helpersScreener.validateRegex(
                                        item_value,
                                        new RegExp(v_rule.validation_value)
                                    );
                                    if (!regex_matches) {
                                        invalid = true;
                                        v_rule_invalid = true;
                                        validation_msg = 'Invalid format';
                                    }
                                }
                            } else if (v_rule.validation_type == VALIDATION_TYPE_CONTINUOUS_SUM_MIN_TOTAL) {
                                if (v_rule.validation_value) {
                                    //alert(`does it add up to ${v_rule.validation_value}?`)
                                    validation_msg = 'Invalid sum';
                                    if (item_value && item_value.length) {
                                        // get the columns
                                        const columns = item.options.filter(o => o.type == 'column');
                                        if (columns && columns.length) {
                                            columns.forEach(c => {
                                                // check if item is in column
                                                let user_input_sum = 0;

                                                // get values for the column
                                                item_value.forEach(inpt => {
                                                    if (inpt.key.includes(c.key)) {
                                                        if (inpt.value && helpers.isNumber(inpt.value)) {
                                                            user_input_sum += parseInt(inpt.value);
                                                        }
                                                    }
                                                });

                                                if (user_input_sum < v_rule.validation_value) {
                                                    invalid = true;
                                                    v_rule_invalid = true;
                                                }
                                            });
                                        }
                                    }
                                }
                            } else if (v_rule.validation_type == VALIDATION_TYPE_CONTINUOUS_SUM_MAX_TOTAL) {
                                if (v_rule.validation_value) {
                                    validation_msg = 'Invalid sum';
                                    if (item_value && item_value.length) {
                                        // get the columns
                                        const columns = item.options.filter(o => o.type == 'column');
                                        if (columns && columns.length) {
                                            columns.forEach(c => {
                                                // check if item is in column
                                                let user_input_sum = 0;

                                                // get values for the column
                                                item_value.forEach(inpt => {
                                                    if (inpt.key.includes(c.key)) {
                                                        if (inpt.value && helpers.isNumber(inpt.value)) {
                                                            user_input_sum += parseInt(inpt.value);
                                                        }
                                                    }
                                                });

                                                if (user_input_sum > v_rule.validation_value) {
                                                    invalid = true;
                                                    v_rule_invalid = true;
                                                }
                                            });
                                        }
                                    }
                                }
                            } else if (v_rule.validation_type == VALIDATION_TYPE_CONTINUOUS_SUM_EXACT_TOTAL) {
                                if (v_rule.validation_value) {
                                    validation_msg = 'Invalid sum';
                                    if (item_value && item_value.length) {
                                        // get the columns
                                        const columns = item.options.filter(o => o.type == 'column');
                                        if (columns && columns.length) {
                                            columns.forEach(c => {
                                                // check if item is in column
                                                let user_input_sum = 0;

                                                // get values for the column
                                                item_value.forEach(inpt => {
                                                    if (inpt.key.includes(c.key)) {
                                                        if (inpt.value && helpers.isNumber(inpt.value)) {
                                                            user_input_sum += parseInt(inpt.value);
                                                        }
                                                    }
                                                });

                                                if (user_input_sum != v_rule.validation_value) {
                                                    invalid = true;
                                                    v_rule_invalid = true;
                                                }
                                            });
                                        }
                                    }
                                }
                            } else if (v_rule.validation_type == VALIDATION_TYPE_MATRIX_TEXT_NUMERIC) {
                                validation_msg = 'All values must be numeric';
                                let user_input_sum = 0;
                                if (item_value && item_value.length) {
                                    const columns = item.options.filter(o => o.type == 'column');
                                    if (columns && columns.length) {
                                        columns.forEach(c => {
                                            // check if item is in column
                                            let user_input_sum = 0;
                                            // get values for the column
                                            item_value.forEach(inpt => {
                                                if (inpt.key.includes(c.key)) {
                                                    let trimmed_val = inpt.value.trim();
                                                    if (trimmed_val && !helpers.isNumber(trimmed_val)) {
                                                        invalid = true;
                                                        v_rule_invalid = true;
                                                    }
                                                }
                                            });
                                        });
                                    }
                                }
                            } else if (
                                [VALIDATION_MINIMUM, VALIDATION_MAXIMUM, VALIDATION_EXACT].includes(
                                    v_rule.validation_type
                                )
                            ) {
                                validation_msg = `${item.label} must select `;
                                const vt = v_rule.validation_type;
                                // const answers = JSON.parse(JSON.stringify(this.state.answers_by_form_element_name));
                                const { length } = item_value;
                                if (v_rule.validation_value) {
                                    if (vt === VALIDATION_MINIMUM && length < Number(v_rule.validation_value)) {
                                        invalid = true;
                                        v_rule_invalid = true;
                                        validation_msg += `at least ${v_rule.validation_value} answers`;
                                        clonedErrorMsgs[item.field_name] = {
                                            msg: validation_msg,
                                            invalid
                                        };
                                    } else if (vt === VALIDATION_MAXIMUM && length > Number(v_rule.validation_value)) {
                                        invalid = true;
                                        v_rule_invalid = true;
                                        validation_msg += `maximum ${v_rule.validation_value} answers`;
                                        clonedErrorMsgs[item.field_name] = {
                                            msg: validation_msg,
                                            invalid
                                        };
                                    } else if (vt === VALIDATION_EXACT && length != Number(v_rule.validation_value)) {
                                        invalid = true;
                                        v_rule_invalid = true;
                                        validation_msg += `exactly ${v_rule.validation_value} answers`;
                                        clonedErrorMsgs[item.field_name] = {
                                            msg: validation_msg,
                                            invalid
                                        };
                                    }
                                }
                                this.setState({ errorMsgs: clonedErrorMsgs });
                                //console.log("validation_msg out of the box", validation_msg);
                            }

                            if (invalid && v_rule_invalid && item.field_name) {
                                if (v_rule && v_rule.validation_message) {
                                    validation_msg = v_rule.validation_message;
                                }
                                helpersScreener.setQuestionError(item.field_name, validation_msg);
                            }
                        });
                    }
                }
            }
        }

        return invalid;
    }

    _isRequiredFailed(item) {
        let invalid = false;
        const page_number = this.state.current_page;
        const page_index = page_number - 1;
        const pages = this.props.data;
        const should_show = helpersScreener.checkQuestionLogic(
            item,
            pages,
            page_index,
            this.state.answers_by_form_element_name
        );

        const all_pages = this.props.data;

        let ref;
        if (this.inputs && item && item.field_name) {
            ref = this.inputs[item.field_name];
        }

        if (item.required === true && !item.static && item.element != 'HiddenValue' && should_show) {
            //console.log('dis here', ref, this.inputs, item.field_name);
            if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
                let checked_options = 0;

                let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
                    item,
                    all_pages,
                    this.state.answers_by_form_element_name
                );
                //console.log('item.options', item.label, all_options, ref.options)
                all_options.forEach(option => {
                    const ref_options = ref.options[`child_ref_${option.key}`];
                    if (ref_options) {
                        //console.log('ref_options', ref_options)
                        const $option = ReactDOM.findDOMNode(ref_options);
                        if ($option && $option.checked) {
                            if (option.is_add_other) {
                                const option_other_value = this.getValueByName('input_' + option.key);
                                if (option_other_value) {
                                    checked_options += 1;
                                }
                            } else {
                                checked_options += 1;
                            }
                        } else {
                            console.log('Option not found');
                        }
                    } else {
                        console.log('ref_options not found');
                    }
                });
                if (checked_options < 1) {
                    // errors.push(item.label + ' is required!');
                    invalid = true;
                }
            } else if (item.element === 'Matrix') {
                let all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
                    item,
                    all_pages,
                    this.state.answers_by_form_element_name
                );
                const rows = all_options.filter(o => o.type == 'row');
                const columns = all_options.filter(o => o.type == 'column');
                let checked_options = 0;
                const matrix_with_text_inputs = item.matrix_allow_multiple == 2 || item.matrix_allow_multiple == '2';
                rows.forEach(row => {
                    columns.forEach(column => {
                        const $row = ReactDOM.findDOMNode(ref.options[`child_ref_${row.key}_${column.key}`]);
                        if (matrix_with_text_inputs) {
                            if ($row && $row.value && $row.value.trim()) {
                                checked_options += 1;
                            }
                        } else {
                            if ($row && $row.checked) {
                                checked_options += 1;
                            }
                        }
                    });
                });
                if (matrix_with_text_inputs) {
                    if (checked_options < rows.length * columns.length) {
                        invalid = true;
                    }
                } else {
                    if (checked_options < rows.length) {
                        // errors.push(item.label + ' is required!');
                        invalid = true;
                    }
                }
            } else {
                const $item = this._getItemValue(item, ref);
                //console.log($item);
                if (item.element === 'Rating') {
                    if ($item.value === 0) {
                        invalid = true;
                    }
                } else if ($item.value === undefined || $item.value.length < 1) {
                    invalid = true;
                }
            }
        }
        return invalid;
    }

    getValueByName(name) {
        const el = document.getElementsByName(name);
        if (el && el[0]) {
            return document.getElementsByName(name)[0].value;
        }
    }

    keyForValueAddOther(item) {
        return item.field_name + '_value_add_other';
    }

    _collect(item) {
        //console.log('_collect', item, this.inputs)
        //console.log('answers_by_form_element_name', this.state.answers_by_form_element_name)
        if (!item.field_name) {
            return;
        }

        const itemData = { name: item.field_name, not_found: false };
        const ref = this.inputs[item.field_name];

        let all_options = [];
        if (helpersScreener.getElementNamesWithOptions().includes(item.element)) {
            all_options = helpersScreener.getOriginalAndPipedAnswerOptions(
                item,
                this.props.data,
                this.state.answers_by_form_element_name
            );
        }

        if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
            let checked_options = [];
            all_options.forEach(option => {
                if (!ref || !ref.options) {
                    // let's find this answer in the state?
                    if (this.state.answers_by_form_element_name[item.field_name]) {
                        /*console.log(
                            'inside _collect... looking at state..',
                            item.field_name,
                            this.state.answers_by_form_element_name[item.field_name]
                        );*/
                        checked_options = cloneDeep(this.state.answers_by_form_element_name[item.field_name]);

                        try {
                            // if there is an "Other" option with a value inside
                            if (option.is_add_other) {
                                //console.log('should look in state for is_add_other')
                                const inputval_other = this.state.answers_by_form_element_name[
                                    this.keyForValueAddOther(item)
                                ];
                                //console.log('inputval_other', inputval_other)
                                itemData.value_add_other = inputval_other;
                            }
                        } catch (e) {
                            helpers.trackError(e);
                        }
                    } else {
                        itemData.not_found = true;
                        /*console.log(
                            'Answer NOT found for checkbox/dropdown ',
                            item,
                            this.state.answers_by_form_element_name
                        );*/
                    }
                } else {
                    const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${option.key}`]);
                    if ($option && $option.checked) {
                        checked_options.push(option.key);
                        //console.log($option);
                        if (option.is_add_other) {
                            //itemData.value_add_other = this.state.answers_by_form_element_name[option.field_name];
                            let inputval_other = this.getValueByName('input_' + option.key);
                            itemData.value_add_other = inputval_other;
                        }
                    }
                }
            });
            itemData.value = checked_options;
            //console.log('itemData', itemData)
        } else if (item.element === 'Matrix') {
            const rows = all_options.filter(o => o.type == 'row');
            const columns = all_options.filter(o => o.type == 'column');

            let checked_options = [];
            rows.forEach(row => {
                columns.forEach(column => {
                    if (!ref || !ref.options) {
                        // let's find this answer in the state?
                        if (this.state.answers_by_form_element_name[item.field_name]) {
                            /*console.log(
                                'inside _collect... looking at state..',
                                item.field_name,
                                this.state.answers_by_form_element_name[item.field_name]
                            );*/
                            checked_options = this.state.answers_by_form_element_name[item.field_name];
                        } else {
                            itemData.not_found = true;
                            /*console.log(
                                'Answer NOT found for checkbox/dropdown ',
                                item,
                                this.state.answers_by_form_element_name
                            );*/
                        }
                    } else {
                        const $option = ReactDOM.findDOMNode(ref.options[`child_ref_${row.key}_${column.key}`]);

                        if ($option && $option.type == 'text') {
                            checked_options.push({
                                key: $option.id,
                                value: $option.value
                            });
                        } else {
                            if ($option && $option.checked) {
                                checked_options.push(row.key + '_' + column.key);
                            }
                        }
                    }
                });
            });
            itemData.value = checked_options;
        } else if (item.element === 'Signature') {
            if (!ref) {
                if (this.state.answers_by_form_element_name[item.field_name]) {
                    itemData.value = this.state.answers_by_form_element_name[item.field_name];
                }
            } else {
                itemData.value = this._getSignatureImg(item);
            }
        } else {
            if (!ref) {
                if (!item.static) {
                    // let's find this answer in the state?
                    if (this.state.answers_by_form_element_name[item.field_name]) {
                        //console.log('...collecting here?', this.state.answers_by_form_element_name[item.field_name])
                        itemData.value = this.state.answers_by_form_element_name[item.field_name];
                    } else {
                        //console.log('Answer NOT found for easy input ', item, this.state.answers_by_form_element_name);
                        //console.log('...collecting not found...', this.state.answers_by_form_element_name)
                        itemData.value = null;
                        itemData.not_found = true;
                    }
                }
            } else {
                itemData.value = this._getItemValue(item, ref).value;
                //console.log('here', itemData.value)
            }
        }

        //console.log('itemData', itemData);

        return itemData;
    }

    _collectFormData(pages_data) {
        let formData = [];

        pages_data.forEach(page_items => {
            let pageData = [];
            page_items.forEach(item => {
                const item_data = this._collect(item);
                if (item_data) {
                    pageData.push(item_data);
                }
            });
            formData.push(pageData);
        });
        return formData;
    }

    _getSignatureImg(item) {
        const ref = this.inputs[item.field_name];
        if (ref && ref.canvas) {
            const $canvas_sig = ref.canvas.current;
            if ($canvas_sig) {
                const base64 = $canvas_sig.toDataURL().replace('data:image/png;base64,', '');
                const isEmpty = $canvas_sig.isEmpty();
                const $input_sig = ReactDOM.findDOMNode(ref.inputField.current);
                if (isEmpty) {
                    $input_sig.value = '';
                } else {
                    $input_sig.value = base64;
                }
                return $input_sig.value;
            }
        } else {
            console.log('_getSignatureImg ref not found');
        }
    }

    // have to change this to "redirect" or "action"
    checkLogicRadioQuestion(itemAnswerKey, options) {
        let action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;
        options.forEach(option => {
            if (option.key == itemAnswerKey) {
                if (option.qualification == 'Reject') {
                    action = SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY;
                } else {
                    if (option.qualification) {
                        // check if qualification contains redirect
                        const redirect_prefix_location = option.qualification.indexOf(SCREENER_Q_DQ_REDIRECT_PREFIX);
                        if (redirect_prefix_location != -1) {
                            const lenth_of_prefix = SCREENER_Q_DQ_REDIRECT_PREFIX.length;
                            const redirect_to_page_number = option.qualification.substring(lenth_of_prefix);
                            action = parseInt(redirect_to_page_number);
                        }
                    }
                }
            }
        });
        return action;
    }

    checkLogicCheckboxQuestion(itemAnswerKeys, options) {
        let action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;

        let questionHasMustSelect = false;
        let questionCountMustSelect = 0;
        let questionHasMaySelect = false;
        let questionHasReject = false;
        let answerHasMustSelect = false;
        let answerCountMustSelect = 0;
        let answerHasMaySelect = false;
        let answerHasReject = false;
        let answerSkipToSelected = false;

        options.forEach(option => {
            if (option.qualification == 'Must Select') {
                questionHasMustSelect = true;
                questionCountMustSelect++;

                if (itemAnswerKeys.includes(option.key)) {
                    answerHasMustSelect = true;
                    answerCountMustSelect++;
                }
            } else if (option.qualification == 'May Select') {
                questionHasMaySelect = true;

                if (itemAnswerKeys.includes(option.key)) {
                    answerHasMaySelect = true;
                }
            } else if (option.qualification == 'Reject') {
                questionHasReject = true;

                if (itemAnswerKeys.includes(option.key)) {
                    answerHasReject = true;
                }
            } else {
                if (itemAnswerKeys.includes(option.key)) {
                    if (option.qualification) {
                        // check if qualification contains redirect
                        const redirect_prefix_location = option.qualification.indexOf(SCREENER_Q_DQ_REDIRECT_PREFIX);
                        //console.log('option.qualification', option.qualification);
                        if (redirect_prefix_location != -1) {
                            if (!answerSkipToSelected) {
                                const lenth_of_prefix = SCREENER_Q_DQ_REDIRECT_PREFIX.length;
                                const redirect_to_page_number = option.qualification.substring(lenth_of_prefix);
                                answerSkipToSelected = parseInt(redirect_to_page_number);
                            }
                        }
                    }
                }
            }
        });

        //console.log('answerSkipToSelected', answerSkipToSelected)

        if (answerHasReject) {
            action = SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY;
        } else if (questionCountMustSelect > 0 && questionCountMustSelect > answerCountMustSelect) {
            action = SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY;
        } else {
            if (answerSkipToSelected) {
                action = answerSkipToSelected;
            } else {
                action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;
            }
        }

        //console.log('action', action)

        return action;
    }

    checkLogicDropdownQuestion(itemAnswerValue, options) {
        let action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;

        options.forEach(option => {
            //console.log('checkLogicDropdownQuestion', option.text, itemAnswerValue);
            if (option.value == itemAnswerValue) {
                if (option.qualification == 'Reject') {
                    action = SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY;
                } else {
                    // check if qualification contains redirect
                    if (option.qualification) {
                        const redirect_prefix_location = option.qualification.indexOf(SCREENER_Q_DQ_REDIRECT_PREFIX);
                        if (redirect_prefix_location != -1) {
                            const lenth_of_prefix = SCREENER_Q_DQ_REDIRECT_PREFIX.length;
                            const redirect_to_page_number = option.qualification.substring(lenth_of_prefix);
                            action = parseInt(redirect_to_page_number);
                        }
                    }
                }
            }
        });

        return action;
    }

    // This is "within-a-question" qual/disqual logic
    checkQualificationLogicPerQuestion() {
        let action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;
        let possible_actions = [];

        try {
            let page_number = this.state.current_page;
            let page_items = this.props.data;

            let action_per_question;
            page_items.forEach((data_items, page_index) => {
                // do not validate an "out of bounds" page number
                if (page_number == page_index + 1) {
                    //console.log(`trying to qualify page_number ${page_number}, looping over page_index ${page_index}`);

                    // loop over the page's questions
                    data_items.forEach(item => {
                        // check if question should be hidden based on show/hide logic
                        let should_show = helpersScreener.checkQuestionLogic(
                            item,
                            page_items,
                            page_index,
                            this.state.answers_by_form_element_name
                        );
                        //console.log('checkQualificationLogicPerQuestion', 'item', item, should_show)
                        if (!should_show) {
                            // skip this question's q/dq logic if question is hidden
                            return;
                        }

                        //console.log(action);
                        // use the action from the first question
                        //if (action == ACTION_IS_NOT_SET) {
                        if (item.element === 'RadioButtons') {
                            // get users's answer
                            let itemAnswer = this._collect(item);
                            if (itemAnswer && itemAnswer.value) {
                                let itemAnswerKey = itemAnswer.value[0];

                                if (item.qual_disqual == true) {
                                    // this can either disqualify, redirect, or proceed
                                    action_per_question = this.checkLogicRadioQuestion(itemAnswerKey, item.options);

                                    //action = action_per_question;
                                    possible_actions.push(action_per_question);
                                }
                            }
                        } else if (item.element === 'Dropdown') {
                            // get users's answer
                            let itemAnswer = this._collect(item);

                            if (itemAnswer && itemAnswer.value) {
                                let itemAnswerValue = itemAnswer.value;

                                if (item.qual_disqual == true) {
                                    // this can either disqualify, redirect, or proceed
                                    action_per_question = this.checkLogicDropdownQuestion(
                                        itemAnswerValue,
                                        item.options
                                    );

                                    //action = action_per_question;
                                    possible_actions.push(action_per_question);
                                }
                            }
                        } else if (item.element === 'Checkboxes') {
                            // get users's answer
                            let itemAnswer = this._collect(item);
                            if (itemAnswer && itemAnswer.value) {
                                let itemAnswerKeys = itemAnswer.value;

                                if (item.qual_disqual == true) {
                                    // this can either disqualify, redirect, or proceed
                                    action_per_question = this.checkLogicCheckboxQuestion(itemAnswerKeys, item.options);

                                    //action = action_per_question;
                                    possible_actions.push(action_per_question);
                                }
                            }
                        }
                        //}
                    });
                }
            });
        } catch (e) {
            helpers.trackError(e);
        }

        /*if (action == ACTION_IS_NOT_SET) {
            action = SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED;
        }*/

        //console.log('possible_actions', possible_actions)

        // check if there's a disqualifier
        if (possible_actions.includes(SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY)) {
            //console.log('possible_actions CONTAINS', SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY)
            action = SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY;
        } else {
            //check if there are any page skips/redirects
            let redirect_to_page_number = false;
            possible_actions.forEach(a => {
                if (a && Number.isInteger(a)) {
                    redirect_to_page_number = a;
                }
            });
            if (redirect_to_page_number) {
                // check if page number is available
                const num_total_pages = this.props.data.length;
                if (redirect_to_page_number <= num_total_pages) {
                    action = redirect_to_page_number;
                }
            }
        }

        return action;
    }

    checkSkipQDQPageLogic(pg_index) {
        //console.log('checking page logic pg_index', pg_index)
        let action = SCREENER_PAGE_LOGIC_STANDARD;
        let found_true_evaluation = false;
        if (this.props.page_logic && this.props.page_logic[pg_index] && this.props.page_logic[pg_index].length) {
            this.props.page_logic[pg_index].forEach(logic_rule => {
                // don't check show/hide logic only skip logic
                if (logic_rule.setup.action != SCREENER_PAGE_LOGIC_SHOW) {
                    // find the first "true" evaluation (if exists), and skip the rest
                    if (!found_true_evaluation) {
                        let evaluates_true = helpersScreener.checkPageLogic(
                            logic_rule,
                            this.props.data,
                            this.state.answers_by_form_element_name
                        );
                        //console.log('found logic for page index', pg_index, evaluates_true)
                        if (evaluates_true) {
                            //console.log('logic action', specific_page_logic.setup);
                            action = logic_rule.setup.action;
                            found_true_evaluation = true;
                        }
                    }
                }
            });
        } else {
            //console.log(' NOT found logic for page index', pg_index)
        }

        return action;
    }

    // evaluates only the show/hide page logic
    checkPageLogicShouldShow(pg_index) {
        //console.log('checking page logic SHOW/HIDE pg_index', pg_index)
        let should_show = true;
        let found_true_evaluation = false;
        if (this.props.page_logic && this.props.page_logic[pg_index] && this.props.page_logic[pg_index].length) {
            let num_show_logic = 0;
            let num_show_logic_success = 0;
            this.props.page_logic[pg_index].forEach(logic_rule => {
                if (logic_rule.setup.action == SCREENER_PAGE_LOGIC_SHOW) {
                    num_show_logic++;
                    // all SHOW must evaluate to true
                    let evaluates_true = helpersScreener.checkPageLogic(
                        logic_rule,
                        this.props.data,
                        this.state.answers_by_form_element_name
                    );
                    //console.log('found logic for page index', pg_index, evaluates_true)
                    if (evaluates_true) {
                        num_show_logic_success++;
                    }
                }
            });

            if (num_show_logic != num_show_logic_success) {
                should_show = false;
            }
        } else {
            //console.log(' NOT found logic for page index', pg_index)
        }

        return should_show;
    }

    isCurrentPageValid() {
        try {
            //e.preventDefault();
            let pg = this.state.current_page;
            let pg_index = pg - 1;
            let pg_next = parseInt(pg) + 1;

            //console.log('this.props.page_logic', this.props.page_logic)

            let errors = [];
            if (!this.props.skip_validations) {
                errors = this.validateForm(pg);
                // Publish errors, if any.
                EventEmitter.dispatch('formValidation', errors);
            }

            // Only submit if there are no errors.
            if (errors.length < 1) {
                const NEXT_STEP_DISQUALIFY = 'submit_disqualify';
                const NEXT_STEP_QUALIFY = 'submit_qualify';
                const NEXT_STEP_JUMP = 'jump_to';

                let next_step;
                let skip_to_page_number;

                const question_Q_DQ_R_action = this.checkQualificationLogicPerQuestion();

                let check_page_logic = true;
                // handle "per-question" logic before the page logic
                // so if there is a disqualifying or redirect action, skip page logic
                /*
                    the options are:
                        accept, which means (check page logic or..) move on to the next page (SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED)
                        disqualify, which means redirect to disqualify (SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY)
                        redirect, which means redirect to a specific page number

                */
                if (question_Q_DQ_R_action == SCREENER_QUESTION_Q_DQ_LOGIC_PROCEED) {
                    // we good, proceed
                }
                // disqualify respondent, skip page logic
                else if (question_Q_DQ_R_action == SCREENER_QUESTION_Q_DQ_LOGIC_DISQUALIFY) {
                    check_page_logic = false;
                    next_step = NEXT_STEP_DISQUALIFY;
                }
                // redirect to page, and skip page logic
                else if (helpers.isNumber(question_Q_DQ_R_action)) {
                    check_page_logic = false;

                    // redirect
                    next_step = NEXT_STEP_JUMP;

                    // page to redirect to
                    skip_to_page_number = question_Q_DQ_R_action;
                }

                //console.log('check_page_logic', check_page_logic)

                if (check_page_logic) {
                    //check page logic for this page
                    const page_logic_action = this.checkSkipQDQPageLogic(pg_index);

                    //console.log('page_logic_action', page_logic_action);

                    if (page_logic_action == SCREENER_PAGE_LOGIC_QUALIFY) {
                        next_step = NEXT_STEP_QUALIFY;
                    } else if (page_logic_action == SCREENER_PAGE_LOGIC_DISQUALIFY) {
                        next_step = NEXT_STEP_DISQUALIFY;
                    } else if (helpers.isNumber(page_logic_action)) {
                        next_step = NEXT_STEP_JUMP;
                        skip_to_page_number = page_logic_action;
                    }
                    // this is the "no logic matched" logic result
                    else if (page_logic_action == SCREENER_PAGE_LOGIC_STANDARD) {
                        // if last page (or if the screener only has 1 page)
                        if (pg == this.props.data.length) {
                            next_step = NEXT_STEP_QUALIFY;
                        } else {
                            next_step = NEXT_STEP_JUMP;
                            skip_to_page_number = pg_next;
                            //console.log('should go to next page', skip_to_page_number)
                        }
                    } else {
                        const logic_action_error = `checkLogic page_logic_action type not found "${page_logic_action}"`;
                        helpers.trackError(logic_action_error);
                    }
                }

                let isQualified;
                if (next_step == NEXT_STEP_QUALIFY) {
                    isQualified = IS_QUALIFIED.YES;
                    this.handleSubmit(null, isQualified);
                } else if (next_step == NEXT_STEP_DISQUALIFY) {
                    isQualified = IS_QUALIFIED.NO;
                    this.handleSubmit(null, isQualified);
                } else if (next_step == NEXT_STEP_JUMP) {
                    const num_total_pages = this.props.data.length;

                    // this is a bad configuration state
                    // 10/18/22 update: instead of submitting, just go to next page
                    if (skip_to_page_number > num_total_pages) {
                        const skip_log_error = `screener logic trying to skip to ${skip_to_page_number}; total ${num_total_pages}; current page ${pg}`;
                        console.log(skip_log_error);
                        //isQualified = IS_QUALIFIED.YES;
                        // just qualify them in this case
                        //this.handleSubmit(null, isQualified);
                        skip_to_page_number = pg_next;
                    }

                    //this.setState({ current_page: skip_to_page_number });
                    this.goToNextPage(skip_to_page_number);

                    helpers.scrollToTop();
                }
            } else {
                helpers.scrollToTop();
                return false;
            }
        } catch (e) {
            helpers.trackError(e);
        }
    }

    goToNextPage(skip_to_page_number) {
        //console.log("******* running goToNextPage:", skip_to_page_number)
        const that = this;
        skip_to_page_number = parseInt(skip_to_page_number);

        // check that the next page has "shown" questions
        let data_pages = this.props.data;
        const total_pages = data_pages.length;

        let page_items_total = 0;
        let page_items_showing = 0;
        let page_items_hidden_values = 0;

        // loop over pages to check if there are any elements to show here
        data_pages.forEach((page_items, index) => {
            if (skip_to_page_number == index + 1 || this.props.isOnePage) {
                page_items_total = page_items.length;
                page_items_showing = 0;

                let page_items_as_elements = page_items.map(item => {
                    if (!item) return null;

                    if (item.element == 'HiddenValue') {
                        page_items_hidden_values++;
                    }

                    const should_show_check = helpersScreener.checkQuestionLogic(
                        item,
                        this.props.data,
                        index,
                        this.state.answers_by_form_element_name
                    );
                    if (should_show_check) {
                        page_items_showing++;
                    }
                });
            }
        });

        //console.log('page_items_total', page_items_total, page_items_showing, page_items_hidden_values)

        // check if the next page is the last page, in which case don't skip it!
        const next_page_is_last_page = total_pages == skip_to_page_number ? true : false;
        //console.log('next_page_is_last_page', next_page_is_last_page)

        // this would be good place to check show logic
        // if next page has SHOW logic and it evaluates to
        //    true.. proceed
        //    false.. skip to next page

        const skip_to_page_index = skip_to_page_number - 1;
        const page_logic_show_next_page = this.checkPageLogicShouldShow(skip_to_page_index);

        // if there are 0 elements on next page (which we would usually skip),
        // but next_page_is_last_page == true,
        // then still show this new page b/c it contains the Submit button
        if (page_items_showing == 0 && !next_page_is_last_page) {
            // if we're ok to skip, do it
            this.goToNextPage(skip_to_page_number + 1);

            // @note theres is an opprotunity to submit this page, just like in the
            // next conditional. i don't remember why i didnt do this originally
        } else if (page_logic_show_next_page == false) {
            //console.log('using page logic SHOW/HIDE to skip page')
            if (next_page_is_last_page) {
                //console.log('have to submit here....')
                const local_isQualified = IS_QUALIFIED.YES;
                this.handleSubmit(null, local_isQualified);
            } else {
                this.goToNextPage(skip_to_page_number + 1);
            }
        } else {
            this.executeOnNextPageCallback();
            this.setState({ current_page: skip_to_page_number });

            // this re-renders the component after it's page changes,
            // so that the "show/hide" question-level logic gets re-executed,
            // since its based on finding answers via DOM, and the first render
            // doesn't allow these questions to be found
            setTimeout(() => {
                this.setState({});
                //console.log('delayed state load');
                if (page_items_hidden_values > 0 && page_items_showing == page_items_hidden_values) {
                    that.isCurrentPageValid();
                }
            }, 200);
        }
    }

    handleSubmit(e, isQualified) {
        if (e) e.preventDefault();

        //console.log('handleSubmit - is qualified?', isQualified);
        const { onSubmit } = this.props;
        const data = this._collectFormData(this.props.data);
        // console.log('handleSubmit - data', data);
        onSubmit(data, isQualified);
    }

    validateForm(page_number) {
        const errors = [];
        const customValidationTypes = ['Checkboxes'];

        let page_items = this.props.data;

        page_items.forEach((data_items, page_index) => {
            //console.log(`trying to validate page_number ${page_number}, looping over page_index ${page_index}`);
            // if we're trying to validate an "out of bounds" page number
            if (page_number != page_index + 1) {
                return;
            }

            if (this.props.display_short) {
                data_items = this.props.data.filter(i => i.alternateForm === true);
            }

            //console.log('IS REQUIRED', this.props.is_required_name);

            const isRequiredName = this.props.is_required_name ? this.props.is_required_name : 'is required';

            let error_message_required;
            data_items.forEach(item => {
                // remove errors!
                if (item.field_name) {
                    helpersScreener.setQuestionError(item.field_name, '');
                }

                if (item.element === 'Signature') {
                    this._getSignatureImg(item);
                }

                if (this._isRequiredFailed(item)) {
                    error_message_required = `${item.label} ${isRequiredName}`;
                    //helpersScreener.setQuestionError(item.field_name, error_message_required);
                    errors.push(error_message_required);
                }

                try {
                    if (this._isValidFailed(item)) {
                        if (customValidationTypes.includes(item.element))
                            errors.push(this.state.errorMsgs[item.field_name].msg);
                        else errors.push(`${item.label} failed validation`);
                    }
                } catch (e) {
                    helpers.trackError(e);
                }

                // feature that came with the resource, ignore it
                /*if (this.props.validateForCorrectness && this._isIncorrect(item)) {
                    errors.push(`${item.label} was answered incorrectly!`);
                }*/

                /*
                    this code saves data from previous pages to the "state",
                    since the DOM is no longer in view
                */

                if (item.field_name) {
                    let ansRaw = this.state.answers_by_form_element_name;

                    // this gets data
                    let itemData = this._collect(item);
                    if (itemData) {
                        //console.log(`trying to save into ${item.field_name}`, itemData.value);
                        ansRaw[item.field_name] = itemData.value;

                        // if has the "Other" answer
                        if (item.add_other && itemData.value_add_other) {
                            ansRaw[this.keyForValueAddOther(item)] = itemData.value_add_other;
                        }

                        //console.log('updating answers to', ansRaw);
                        this.setState({ answers_by_form_element_name: ansRaw });
                    }
                }
            });
        });

        //console.log('errors', errors);
        return errors;
    }

    executeOnChangeCallback() {
        //console.log('executeOnChangeCallback')
        if (this.props.onChangeWithTimeout) {
            const formAnswers = this._collectFormData(this.props.data);
            const action = 'change';
            this.props.onChangeWithTimeout(this.state, formAnswers, action);
        }
    }

    executeOnNextPageCallback() {
        //console.log('executeOnNextPageCallback')
        if (this.props.onChangeWithTimeout) {
            const formAnswers = this._collectFormData(this.props.data);
            const action = 'next';
            this.props.onChangeWithTimeout(this.state, formAnswers, action);
        }
    }

    handleChange(e) {
        //console.log('handleChange!!')
        //this.forceUpdate();
        this.setState(this.state);
        if (this.props.onChange) {
            this.props.onChange(this.state);
        }

        /*
        // stop using this in favor of "next" action
        try {
            const save_interval_miliseconds = 10000;
            const save_interval_seconds = save_interval_miliseconds / 1000;
            //console.log(save_interval_miliseconds, save_interval_seconds)
            // if callback is passed from parent
            // and not a preview
            if (this.props.onChangeWithTimeout && !this.props.is_preview) {

                if (!this.onchangeCallbackTimerStartSeconds) {
                    this.onchangeCallbackTimerStartSeconds = Math.round((new Date()).getTime() / 1000);
                }

                // if we already have a timeout running
                if (this.onChangeCallbackDelay) {
                    // if the timer is older than N seconds, let the previous timeout execute, and reset the time
                    const seconds_now = Math.round((new Date()).getTime() / 1000);
                    const seconds_diff = seconds_now - this.onchangeCallbackTimerStartSeconds;
                    //console.log('seconds_now diff', seconds_diff)
                    if (seconds_diff >= save_interval_seconds) {
                        this.onchangeCallbackTimerStartSeconds = null;
                        // previous callback will get cleared, so let's manually send this 5 second save
                        this.executeOnChangeCallback();
                    }

                    clearTimeout(this.onChangeCallbackDelay);

                }

                this.onChangeCallbackDelay = setTimeout(function () {
                    this.executeOnChangeCallback();
                    this.onchangeCallbackTimerStartSeconds = null;
                }.bind(this), save_interval_miliseconds);

            }
        } catch (e) {
            helpers.trackError(e);
        }*/
    }

    setDefaultValueFromMergeCode = item => {
        try {
            if (item.element === 'HiddenValue') {
                const params = new URLSearchParams(window.location.search);
                const mergeCodes = item.default_value.match(MERGE_CODE_URL_REGEX);
                let defaultValue = item.default_value;
                const regex = /\"(.*)\"/; // Regex for extract param from merge_code.
                if (mergeCodes && mergeCodes.length > 0)
                    mergeCodes.map(mergeCode => {
                        const param = mergeCode.match(regex);
                        defaultValue = defaultValue.replace(mergeCode, params.get(param[1]) || '');
                    });
                item.default_value = defaultValue;
            }
        } catch (error) {
            helpers.trackError(error);
        }
    };

    getInputElement(item, page_index) {
        const FormInputElement = FormElements[item.element];
        this.setDefaultValueFromMergeCode(item);
        return (
            <FormInputElement
                handleChange={this.handleChange}
                ref={c => (this.inputs[item.field_name] = c)}
                mutable={true}
                key={`form_${item.id}`}
                data={item}
                read_only={this.props.read_only}
                show_qualification={this.props.show_qualification}
                isQuestionLogic={item.question_logic}
                defaultValue={this._getDefaultValue(item)}
                is_disabled={this._checkShouldBeDisabled(item)}
                all_questions={this.props.data}
                page_index={page_index}
                all_refs={this.inputs}
                answers_by_form_element_name={this.state.answers_by_form_element_name}
                addpipe_token={this.props.addpipe_token}
                addpipe_eid={this.props.addpipe_eid}
                isPopup={this.props.isPopup}
            />
        );
    }

    getSimpleElement(item, page_index) {
        const Element = FormElements[item.element];
        return (
            <Element
                mutable={true}
                key={`form_${item.id}`}
                data={item}
                isQuestionLogic={item.question_logic}
                all_questions={this.props.data}
                page_index={page_index}
                isPopup={this.props.isPopup}
            />
        );
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    sortOptions(options, optionOrder) {
        const sortableOptions = [];
        const unSortableOptions = [];
        let sortedOptions = [];

        options.map((option, idx) => {
            if (!option.is_none_of_the_above && !option.is_add_other && option.type !== 'column')
                sortableOptions.push(option);
            else unSortableOptions.push({ idx, data: option });
        });

        sortedOptions = [...sortableOptions];
        if (optionOrder.value === OPTION_ORDER_RADOMIZE.value) sortedOptions = shuffle(sortableOptions);
        else if (optionOrder.value === OPTION_ORDER_FLIP.value)
            sortedOptions = Math.random() >= 0.5 ? [...sortableOptions.reverse()] : sortableOptions;
        unSortableOptions.map(option => sortedOptions.splice(option.idx, 0, option.data));

        return sortedOptions;
    }

    /**
     * @returns {number[]} Array of page indexes that have page logic enabled
     */
    get pagesWithLogicEnabled() {
        if (!this.props.page_logic) return [];

        return this.props.page_logic.reduce((acc, pageLogic, index) => {
            if (pageLogic) {
                acc.push(index);
            }

            return acc;
        }, []);
    }

    render() {
        let data_pages = this.state.pageData;

        let render_pages = [];

        data_pages.forEach((page_items, index) => {
            if (this.state.current_page == index + 1 || this.props.isOnePage) {
                const actionName = this.props.action_name ? this.props.action_name : 'Submit';
                const backName = this.props.back_name ? this.props.back_name : 'Cancel';
                const nextName = this.props.next_name ? this.props.next_name : 'Next';

                // If the form is a one page form, we want to divide the form into sections
                if (this.props.isOnePage && index !== 0) {
                    render_pages.push(<hr style={{ margin: '16px 0 8px' }} key={`page-divider-${index}`} />);
                }

                // show page logic badge
                if (this.props.show_qualification && this.pagesWithLogicEnabled.includes(index)) {
                    render_pages.push(
                        <Badge type="pure" key={`page-logic-key-${index}`}>
                            <FiGitMerge /> Page logic enabled
                        </Badge>
                    );
                }

                page_items.forEach(item => {
                    if (item && item.readOnly && item.variableKey && this.props.variables[item.variableKey]) {
                        this.answerData[item.field_name] = this.props.variables[item.variableKey];
                    }
                });

                let page_items_as_elements = page_items.map(item => {
                    if (!item) return null;

                    switch (item.element) {
                        case 'HiddenValue':
                        case 'TextInput':
                        case 'NumberInput':
                        case 'TextArea':
                        case 'Dropdown':
                        case 'DatePicker':
                        case 'Rating':
                        case 'Tags':
                        case 'AddPipeInput':
                        case 'FileUpload':
                        case 'Captcha':
                            return this.getInputElement(item, index);
                        case 'RangeElement':
                            return (
                                <RangeElement
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only}
                                    show_qualification={this.props.show_qualification}
                                    handleChange={this.handleChange}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._getDefaultValue(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Signature':
                            return (
                                <Signature
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only || item.readOnly}
                                    show_qualification={this.props.show_qualification}
                                    mutable={true}
                                    handleChange={this.handleChange}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._getDefaultValue(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'RadioButtons':
                            //console.log('this._optionsDefaultValue(item)', this._optionsDefaultValue(item))
                            return (
                                <RadioButtons
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only}
                                    show_qualification={this.props.show_qualification}
                                    handleChange={this.handleChange}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._optionsDefaultValue(item)}
                                    defaultValueOther={this._optionsDefaultValueOther(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Checkboxes':
                            //console.log('this._optionsDefaultValue(item)', this._optionsDefaultValue(item))
                            return (
                                <Checkboxes
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only}
                                    show_qualification={this.props.show_qualification}
                                    handleChange={this.handleChange}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._optionsDefaultValue(item)}
                                    defaultValueOther={this._optionsDefaultValueOther(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Matrix':
                            return (
                                <Matrix
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only}
                                    show_qualification={this.props.show_qualification}
                                    handleChange={this.handleChange}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._optionsDefaultValue(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Image':
                            return (
                                <Image
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    handleChange={this.handleChange}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._getDefaultValue(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Download':
                            return (
                                <Download
                                    download_path={this.props.download_path}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        case 'Camera':
                            return (
                                <Camera
                                    ref={c => (this.inputs[item.field_name] = c)}
                                    read_only={this.props.read_only || item.readOnly}
                                    mutable={true}
                                    key={`form_${item.id}`}
                                    data={item}
                                    isQuestionLogic={item.question_logic}
                                    defaultValue={this._getDefaultValue(item)}
                                    all_questions={this.props.data}
                                    page_index={index}
                                    all_refs={this.inputs}
                                    answers_by_form_element_name={this.state.answers_by_form_element_name}
                                    isPopup={this.props.isPopup}
                                />
                            );
                        default:
                            return this.getSimpleElement(item);
                    }
                });

                render_pages = render_pages.concat(page_items_as_elements);

                const buttons = (
                    <div className="btn-toolbar" key={`button-container-render-form-${index}`}>
                        {!this.props.hide_actions && (
                            <Button
                                m="32px 0 20px 0"
                                className="big"
                                type="button"
                                variant="primary"
                                onClick={() => {
                                    this.isCurrentPageValid();
                                }}
                                disabled={this.props.is_submitting}
                                style={
                                    this.props.branding && this.props.branding.primary
                                        ? { background: this.props.branding.primary }
                                        : {}
                                }
                            >
                                {this.props.data.length > index + 1 ? nextName : actionName}
                            </Button>
                        )}
                        {!this.props.hide_actions && this.props.back_action && (
                            <a href={this.props.back_action} className="btn btn-default btn-cancel btn-big">
                                {backName}
                            </a>
                        )}
                    </div>
                );
                render_pages = render_pages.concat(buttons);
            }
        });

        //console.log('alll elements', render_pages);

        const formTokenStyle = {
            display: 'none'
        };

        /*
         *
         *
         *   this renders the public facing form
         *
         */

        return (
            <div>
                <Box as="p" textAlign="right" mt={1} mb={2} className="fs-accent-14 text-pure">
                    <span className="text-danger">*</span> Required field
                </Box>
                <FormValidator dismiss_name={this.props.dismiss_name} emitter={EventEmitter} />
                <div
                    className={classNames(
                        'react-form-builder-form',
                        this.props.isPopup && 'react-form-builder-form-popup'
                    )}
                >
                    <form
                        encType="multipart/form-data"
                        ref={c => (this.form = c)}
                        action={this.props.form_action}
                        /* onSubmit was CHANGED b/c we want the "next" or "submit" to handle this */
                        onSubmit={e => {
                            e.preventDefault();
                        }}
                        method={this.props.form_method}
                        autoComplete="on"
                    >
                        {this.props.authenticity_token && (
                            <div style={formTokenStyle}>
                                <input name="utf8" type="hidden" value="&#x2713;" />
                                <input name="authenticity_token" type="hidden" value={this.props.authenticity_token} />
                                <input name="task_id" type="hidden" value={this.props.task_id} />
                            </div>
                        )}
                        {render_pages}
                    </form>
                </div>
            </div>
        );
    }
}

ReactForm.defaultProps = { validateForCorrectness: false };
