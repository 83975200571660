import React from 'react';
import helpers from 'utils/helpers';
import xhr from 'xhr.js';
import config from 'config';
import auth from 'services/auth';
import services from 'services/services';
import DatePicker from 'react-datepicker';
import moment from 'moment-timezone';
import helpersStudy from 'utils/helpers-study';
import {
    COL_OPT_IN,
    SCREENER_LOGIC_COMPARE,
    SCREENER_LOGIC_EQUAL,
    SCREENER_LOGIC_NOT_EQUAL,
    SCREENER_LOGIC_GREATER_THAN,
    SCREENER_LOGIC_LESS_THAN,
    SCREENER_LOGIC_DATE_BEFORE,
    SCREENER_LOGIC_DATE_AFTER,
    SCREENER_LOGIC_ANSWERED,
    SCREENER_LOGIC_NOT_ANSWERED,
    SCREENER_LOGIC_IN_LIST,
    SCREENER_LOGIC_NOT_IN_LIST,
    SCREENER_LOGIC_CONTAINS_ANY_OF,
    SCREENER_LOGIC_NOT_CONTAINS_ANY_OF,
    SCREENER_QUESTION_ERRORS_CLASSNAME
} from 'utils/constants';
import { Select, Input } from '@rebass/forms';
import { FiCalendar } from 'react-icons/fi';
import cloneDeep from 'lodash/cloneDeep';

const helpersScreener = {
    getAllOptionsForPiping(q, screenersArray) {
        let all_options = cloneDeep(q.options);
        //console.log('getAllOptionsForPiping', q)
        if (q.pipe_options_from) {
            let question_piped_from;
            screenersArray.forEach(sa => {
                const el_found = sa.elements.find(item => item.field_name == q.pipe_options_from);
                if (el_found) {
                    question_piped_from = el_found;
                }
            });

            let piped_answer_options = [];

            if (question_piped_from && question_piped_from.options) {
                piped_answer_options = this.getAllOptionsForPiping(question_piped_from, screenersArray);
                //console.log('q.pipe_options_from', q.label, piped_answer_options)

                if (question_piped_from.element == 'Matrix') {
                    piped_answer_options.forEach(o => {
                        o.type = 'row';
                    });
                }

                all_options = all_options.concat(piped_answer_options);
            }
        }
        return all_options;
    },
    getOriginalAndPipedAnswerOptions(item, all_questions, answers_by_form_element_name) {
        let all_options = [];
        let piped_options = [];
        let piped_answer_options = [];
        if (item) {
            all_options = cloneDeep(item.options);

            if (item.pipe_options_from) {
                // if answers exist
                if (answers_by_form_element_name) {
                    let piping_question;
                    all_questions.forEach(page_q => {
                        page_q.forEach(q => {
                            if (q.field_name == item.pipe_options_from) {
                                piping_question = cloneDeep(q);
                            }
                        });
                    });

                    if (piping_question) {
                        piping_question.options = this.getOriginalAndPipedAnswerOptions(
                            piping_question,
                            all_questions,
                            answers_by_form_element_name
                        );
                        piped_answer_options = helpersScreener.getPipedAnswerOptions(
                            piping_question,
                            answers_by_form_element_name
                        );

                        // if matrix, mark as row
                        if (item.element == 'Matrix') {
                            piped_answer_options.forEach(o => {
                                o.type = 'row';
                            });
                        }
                    }
                }
            }
        }
        //console.log('getOriginalAndPipedAnswerOptions', item)

        all_options = all_options.concat(piped_answer_options);
        return all_options;
    },
    showQuestionNumber(all_questions, element) {
        let which_page_number = null;
        let which_question_number = null;
        try {
            all_questions.forEach((page_questions, page_index) => {
                const q_index = page_questions.findIndex(pq => pq.id == element.id);
                if (q_index >= 0) {
                    which_page_number = page_index + 1;
                    which_question_number = q_index + 1;
                }
            });
        } catch (e) {
            helpers.trackError(e);
        }

        return (
            <div className="public-question-number">
                P{which_page_number}.Q{which_question_number}
            </div>
        );
    },
    getVideoLinkFromScreenerAnswer(value) {
        let video_link;
        //console.log('value', value)
        if (value) {
            let path;
            if (Array.isArray(value)) {
                path = value[0];
            } else {
                path = value;
            }

            console.log('path', path);

            if (path) {
                if (path.includes('addpipe')) {
                    video_link = path;
                } else {
                    video_link = config.API_URL + '/screener-get-video-recording?path=' + path;
                }
            }
        }
        //console.log('video_link in helper', video_link)

        return video_link;
    },
    questionAllowsPiping(question) {
        //console.log(question.element)
        const allowedForPiping = [
            'TextInput',
            'TextArea',
            'NumberInput',
            'Rating',
            'RangeElement',
            'Dropdown',
            'Checkboxes',
            'RadioButtons',
            'DatePicker'
        ];
        if (allowedForPiping.includes(question.element)) {
            return true;
        }
    },
    questionDisabledInLogic(question) {
        const disabledInLogic = ['Captcha'];
        return disabledInLogic.includes(question.element);
    },
    getScreenerBuilderInputHtmlId(id) {
        return `screener-input-for-id-${id}`;
    },
    setQuestionError(input_element_name, error) {
        const actual_input_el_results = document.getElementsByName(input_element_name);
        if (actual_input_el_results && actual_input_el_results[0]) {
            const actual_input_el = actual_input_el_results[0];
            if (error) {
                actual_input_el.classList.add('error');
            } else {
                actual_input_el.classList.remove('error');
            }
        }

        const dom_error_element = helpersScreener.getInputErrorClassElement(input_element_name);
        //console.log(input_element_name, dom_error_element);
        if (dom_error_element) {
            if (error) {
                var node = document.createElement('div');
                node.classList = 'screener-error-message';
                node.innerHTML = error;
                dom_error_element.appendChild(node);
            } else {
                dom_error_element.innerHTML = '';
            }
        }
    },
    getInputParentClassName(input_element_name) {
        return `container_${input_element_name}`;
    },
    getInputErrorClassElement(input_element_name) {
        const parent_container_class = this.getInputParentClassName(input_element_name);
        let dom_results_error_elements = document.querySelector(
            `.${parent_container_class} .${SCREENER_QUESTION_ERRORS_CLASSNAME}`
        );
        return dom_results_error_elements;
    },
    getScreenerCustomLink(screener, account, { defaultDomain } = {}) {
        if (screener.qualtrics_survey_id && account.qualtrics_organization_id) {
            return `https://${account.qualtrics_organization_id}.qualtrics.com/jfe/form/${screener.qualtrics_survey_id}`;
        }

        const account_ui_domain = defaultDomain ? config.UI_URL : config.getAccountPrefixUrl(account);
        if (screener.links && screener.links.length > 0) {
            return `${account_ui_domain}/s/${screener.links[0].slug}`;
        } else {
            return `${account_ui_domain}/s/${screener.uuid}`;
        }
    },
    getExternalScreenerResponsesLink(screener) {
        if (screener.qualtrics_survey_id && screener.account.qualtrics_organization_id) {
            return `https://${screener.account.qualtrics_organization_id}.az1.qualtrics.com/responses/#/surveys/${screener.qualtrics_survey_id}`;
        }
        return '';
    },
    getPanelSignupLink(account, links, panel_form_id, { defaultDomain } = {}) {
        const account_ui_domain = defaultDomain ? config.UI_URL : config.getAccountPrefixUrl(account);
        let publicUrl;
        if (links && links.length) {
            publicUrl = account_ui_domain + '/p/' + links[0].slug;
        } else {
            publicUrl = account_ui_domain + '/panel-profile/' + account.uuid + '/' + panel_form_id;
        }
        return publicUrl;
    },
    getScreenerLink(screener_uuid, account, { defaultDomain } = {}) {
        const account_ui_domain = defaultDomain ? config.UI_URL : config.getAccountPrefixUrl(account);
        return `${account_ui_domain}/screen/${screener_uuid}`;
    },
    getScreenerLinkNew(screener_uuid, account, { defaultDomain } = {}) {
        const account_ui_domain = defaultDomain ? config.UI_URL : config.getAccountPrefixUrl(account);
        return `${account_ui_domain}/s/${screener_uuid}`;
    },
    getScreenerLinkNewForPerson(screener_uuid, account, studyPerson, options) {
        return `${this.getScreenerLinkNew(screener_uuid, account, options)}?sp_uuid=${
            studyPerson ? studyPerson.uuid : ''
        }`;
    },
    getQuestionLogicRulesDefault() {
        return { setup: { action: 'show', match: 'any' }, conditionals: [] };
    },
    getPageLogicRulesDefault() {
        return { setup: { action: 'disqualify', match: 'any' }, conditionals: [] };
    },
    getQuestionLogicConditionalDefault(is_group) {
        if (is_group) {
            const group_defaults = this.getQuestionLogicRulesDefault();
            group_defaults['type'] = 'group';
            return group_defaults;
        } else {
            return { question_id: null, compare: SCREENER_LOGIC_EQUAL, value: null };
        }
    },
    getElementNamesWithOptions() {
        return ['Checkboxes', 'RadioButtons', 'Dropdown', 'Matrix', 'Captcha'];
    },
    getElementNamesWithMultiSelect() {
        return ['Checkboxes', 'Matrix'];
    },
    getElementNamesWithSingleSelect() {
        return ['RadioButtons', 'Dropdown', 'Captcha'];
    },
    getElementNamesBasicInputs() {
        return ['TextInput', 'TextArea'];
    },
    getElementNamesNumericInputs() {
        return ['NumberInput', 'Rating', 'RangeElement'];
    },
    getElementNamesTextOrNumericInputs() {
        return ['TextInput', 'TextArea', 'NumberInput', 'Rating', 'RangeElement', 'HiddenValue'];
    },
    getElementNamesDates() {
        return ['DatePicker'];
    },
    getElementNamesStatic() {
        return ['Header', 'Paragraph'];
    },
    getElementNamesWithValidation() {
        return ['TextInput', 'TextArea', 'NumberInput'];
    },
    shouldShowValidationOptions(question) {
        //console.log('validation type', question.element);
        const els = helpersScreener.getElementNamesWithValidation();
        let show_validation = false;
        if (els.includes(question.element)) {
            show_validation = true;
        } else if (this.isElementTextMatrix(question) || question.element === 'Checkboxes') {
            show_validation = true;
        }
        return show_validation;
    },
    evaluateAndOrConditionalResults(item_data_match, conditional_evaluations) {
        let do_conditions_match = false;
        if (item_data_match == 'all') {
            // all have to come out TRUE
            if (conditional_evaluations.filter(bool => bool == true).length == conditional_evaluations.length) {
                do_conditions_match = true;
            }
        } else {
            // need at least ONE to be true
            if (conditional_evaluations.filter(bool => bool == true).length) {
                do_conditions_match = true;
            }
        }
        return do_conditions_match;
    },
    checkQuestionLogic(item_data, all_questions, page_index, answers_by_form_element_name, showQualification = false) {
        let should_show = true;

        if (showQualification) return should_show;

        // console.log('answers_by_form_element_name', answers_by_form_element_name)

        // check question logic.. to see if to show or not
        // console.log('this.props', this.props);

        if (item_data && item_data.question_logic == true) {
            // console.log('item_data.question_logic', item_data.question_logic, item_data.question_logic_rules)
            if (
                item_data.question_logic_rules &&
                item_data.question_logic_rules.setup &&
                item_data.question_logic_rules.conditionals.length
            ) {
                let conditional_evaluations = [];
                item_data.question_logic_rules.conditionals.forEach(conditional => {
                    //console.log(conditional);
                    conditional_evaluations = helpersScreener.screenerEvaluateConditional(
                        conditional_evaluations,
                        all_questions,
                        conditional,
                        answers_by_form_element_name
                    );
                });
                // all = "AND"
                // any ="OR"
                //console.log('master_conditional_evaluations', conditional_evaluations)
                const do_conditions_match = this.evaluateAndOrConditionalResults(
                    item_data.question_logic_rules.setup.match,
                    conditional_evaluations
                );
                //console.log('master_do_conditions_match', do_conditions_match)
                // reset default
                if (item_data.question_logic_rules.setup.action == 'show') {
                    should_show = !!do_conditions_match;
                }
                // this means "HIDE" if things are true
                else {
                    should_show = !do_conditions_match;
                }
            }
        }

        // console.log('should_show', should_show, item_data.label)

        return should_show;
    },

    checkPageLogic(page_logic, all_questions, answers_by_form_element_name) {
        let do_conditions_match = false;

        // console.log('answers_by_form_element_name', answers_by_form_element_name)

        // check question logic.. to see if to show or not
        // console.log('this.props', this.props);

        if (page_logic && page_logic.setup && page_logic.conditionals && page_logic.conditionals.length) {
            let conditional_evaluations = [];
            page_logic.conditionals.forEach(conditional => {
                conditional_evaluations = helpersScreener.screenerEvaluateConditional(
                    conditional_evaluations,
                    all_questions,
                    conditional,
                    answers_by_form_element_name
                );
            });

            // all = "AND"
            // any ="OR"
            do_conditions_match = this.evaluateAndOrConditionalResults(page_logic.setup.match, conditional_evaluations);
        }

        return do_conditions_match;
    },
    screenerEvaluateConditional(conditional_evaluations, all_questions, conditional, answers_by_form_element_name) {
        // conditional.question_id
        // conditional.compare
        // conditional.value

        try {
            //console.log('hi screenerEvaluateConditional')
            // is this a group?
            if (conditional.type == 'group') {
                //console.log('group!', conditional)
                const conditional_group = conditional;

                let conditional_evaluations_group = [];
                conditional_group.conditionals.forEach(conditional_in_group => {
                    //console.log(conditional);
                    conditional_evaluations_group = this.screenerEvaluateConditional(
                        conditional_evaluations_group,
                        all_questions,
                        conditional_in_group,
                        answers_by_form_element_name
                    );
                });
                //console.log('conditional_evaluations_group',conditional_evaluations_group)
                const do_conditions_match = this.evaluateAndOrConditionalResults(
                    conditional_group.setup.match,
                    conditional_evaluations_group
                );
                //console.log('do_conditions_match', do_conditions_match)
                conditional_evaluations.push(do_conditions_match);
            } else {
                if (all_questions) {
                    let pq;
                    all_questions.forEach(page_questions => {
                        page_questions.forEach(pq_loop => {
                            // check for "question id"
                            if (pq_loop.id == conditional.question_id) {
                                pq = pq_loop;
                            }
                        });
                    });

                    if (pq) {
                        let item_value_as_array = this.getItemValue(pq, answers_by_form_element_name);
                        let item_value_flat;
                        if (Array.isArray(item_value_as_array)) {
                            if (helpersScreener.isElementTextMatrix(pq)) {
                                // if its a text matrix.. we need to likely find the "child question" response
                                //console.log('conditional.question_id_child', conditional.question_id_child)
                                if (conditional.question_id_child) {
                                    const ans_found = item_value_as_array.filter(
                                        el => el.key == conditional.question_id_child
                                    );
                                    if (ans_found && ans_found.length) {
                                        //console.log('ans_found', ans_found)
                                        item_value_flat = ans_found[0].value;
                                        item_value_as_array = [item_value_flat];
                                    }
                                }
                            } else {
                                item_value_flat = item_value_as_array[0];
                            }
                        } else {
                            item_value_flat = item_value_as_array;
                        }

                        //console.log('logic eval', conditional.compare, item_value_as_array, conditional.value, item_value_flat)
                        //console.log('comp logic', conditional, item_value_as_array, item_value_flat)

                        // this is preparation if logic used for comma-separated list
                        let logic_comma_separated_list = [];
                        if ([SCREENER_LOGIC_IN_LIST, SCREENER_LOGIC_NOT_IN_LIST].includes(conditional.compare)) {
                            try {
                                logic_comma_separated_list = conditional.value.split(',').map(function(item) {
                                    return item.trim().toLowerCase();
                                });
                            } catch (e) {
                                helpers.trackError(e);
                            }
                        }

                        const matchesContainsAnyOfLogic = (conditional_value, item_value_as_array) => {
                            // check if any of the answers match any of the conditionals
                            let doesContain = false;
                            conditional_value.forEach(cv => {
                                if (item_value_as_array.includes(cv)) {
                                    doesContain = true;
                                }
                            });

                            return doesContain;
                        };

                        const matchesNotContainsAnyOfLogic = (conditional_value, item_value_as_array) => {
                            // check if any of the answers match any of the conditionals
                            let doesContain = false;
                            conditional_value.forEach(cv => {
                                if (item_value_as_array.includes(cv)) {
                                    doesContain = true;
                                }
                            });

                            // return opposite
                            return !doesContain;
                        };

                        //console.log(logic_comma_separated_list)

                        // get its value & compare it
                        if (
                            (conditional.compare == SCREENER_LOGIC_EQUAL &&
                                item_value_as_array.includes(conditional.value)) ||
                            (conditional.compare == SCREENER_LOGIC_NOT_EQUAL &&
                                item_value_as_array.includes(conditional.value) == false) ||
                            (conditional.compare == SCREENER_LOGIC_GREATER_THAN &&
                                parseInt(item_value_flat) > parseInt(conditional.value)) ||
                            (conditional.compare == SCREENER_LOGIC_LESS_THAN &&
                                parseInt(item_value_flat) < parseInt(conditional.value)) ||
                            // this is used for text-only (e.g. is number in list (1,2,3))
                            // answer value will be compared as lower case
                            (conditional.compare == SCREENER_LOGIC_IN_LIST &&
                                logic_comma_separated_list.includes(item_value_flat.toLowerCase())) ||
                            // this is used for text-only (e.g. is number NOT in list (1,2,3))
                            // answer value will be compared as lower case
                            (conditional.compare == SCREENER_LOGIC_NOT_IN_LIST &&
                                logic_comma_separated_list.includes(item_value_flat.toLowerCase()) == false) ||
                            // this is used for text-only (e.g. is number in list (1,2,3))
                            (conditional.compare == SCREENER_LOGIC_CONTAINS_ANY_OF &&
                                matchesContainsAnyOfLogic(conditional.value, item_value_as_array)) ||
                            // this is used for text-only (e.g. is number NOT in list (1,2,3))
                            (conditional.compare == SCREENER_LOGIC_NOT_CONTAINS_ANY_OF &&
                                matchesNotContainsAnyOfLogic(conditional.value, item_value_as_array))
                        ) {
                            conditional_evaluations.push(true);
                        }
                        // if we're comparing dates
                        else if (
                            [SCREENER_LOGIC_DATE_BEFORE, SCREENER_LOGIC_DATE_AFTER].includes(conditional.compare)
                        ) {
                            let item_value_as_date = null;
                            let conditional_value_as_date = null;
                            if (item_value_flat) {
                                //console.log('datee for conditional' ,existing_value, moment(existing_value).toDate())
                                let existing_date = moment(item_value_flat);
                                if (existing_date.isValid()) {
                                    item_value_as_date = moment(item_value_flat).toDate();
                                }
                            }

                            if (conditional.value) {
                                //console.log('datee for conditional' ,existing_value, moment(existing_value).toDate())
                                let existing_date_cond = moment(conditional.value);
                                if (existing_date_cond.isValid()) {
                                    conditional_value_as_date = moment(conditional.value).toDate();
                                }
                            }

                            //console.log(item_value_as_date, conditional_value_as_date)

                            if (
                                // this means "screener answer" is in the past
                                (conditional.compare == SCREENER_LOGIC_DATE_BEFORE &&
                                    item_value_as_date < conditional_value_as_date) ||
                                // this means "screener answer" is in the future
                                (conditional.compare == SCREENER_LOGIC_DATE_AFTER &&
                                    item_value_as_date > conditional_value_as_date)
                            ) {
                                conditional_evaluations.push(true);
                            } else {
                                conditional_evaluations.push(false);
                            }
                        } else if (
                            [SCREENER_LOGIC_ANSWERED, SCREENER_LOGIC_NOT_ANSWERED].includes(conditional.compare)
                        ) {
                            let number_valid_answers = 0;
                            item_value_as_array.forEach(ans => {
                                //console.log(ans);
                                if (ans && ans.length) {
                                    number_valid_answers++;
                                }
                            });
                            //console.log('is/not answered?', item_value_as_array, conditional.value, number_valid_answers);
                            if (number_valid_answers > 0) {
                                if (conditional.compare == SCREENER_LOGIC_ANSWERED) {
                                    conditional_evaluations.push(true);
                                } else if (conditional.compare == SCREENER_LOGIC_NOT_ANSWERED) {
                                    conditional_evaluations.push(false);
                                }
                            } else {
                                if (conditional.compare == SCREENER_LOGIC_ANSWERED) {
                                    conditional_evaluations.push(false);
                                } else if (conditional.compare == SCREENER_LOGIC_NOT_ANSWERED) {
                                    conditional_evaluations.push(true);
                                }
                            }
                        } else {
                            // console.log('does not match....')
                            conditional_evaluations.push(false);
                        }
                    }
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }

        //console.log(conditional_evaluations);

        return conditional_evaluations;
    },
    getValueByName(name) {
        const el = document.getElementsByName(name);
        if (el && el[0]) {
            return document.getElementsByName(name)[0].value;
        }
    },
    getBasicItemValue(item) {
        let value;
        /* if (item.element === 'Rating') {
            item.value = ref.inputField.current.state.rating;
        } else if (item.element === 'Tags') {
            $item.value = ref.inputField.current.state.value;
        } else */
        if (item.element === 'DatePicker') {
            value = ref.state.value;
        } /* else if (item.element === 'Camera') {
            $item.value = ref.state.img ? ref.state.img.replace('data:image/png;base64,', '') : '';
        } */ else if (
            ref &&
            ref.inputField
        ) {
            $item = ReactDOM.findDOMNode(ref.inputField.current);
            if (typeof $item.value === 'string') {
                $item.value = $item.value.trim();
            }
        }
        return $item;
    },
    getMatrixValues(item) {
        const rows = item.options.filter(o => o.type == 'row');
        const columns = item.options.filter(o => o.type == 'column');

        let checked_options = [];
        rows.forEach(row => {
            columns.forEach(column => {
                const name = `${row.key}_${column.key}`;
                //console.log(name);
                const el = document.getElementById(name);
                //console.log(name, el)
                if (el) {
                    if (this.isElementTextMatrix(item)) {
                        checked_options.push({
                            key: name,
                            value: el.value
                        });
                    } else {
                        if (el.checked) {
                            checked_options.push(name);
                        }
                    }
                }
            });
        });

        return checked_options;
    },
    /*
     *     warning!!!!! this isnt the true "value"
             for radio/checkbox it returns the KEY
             for dropdown it returns the VALUE
     */
    getItemValue(item, answers_by_form_element_name) {
        let value_array = [];
        if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
            // console.log(item.element, item.field_name);

            if (answers_by_form_element_name[item.field_name]) {
                value_array = answers_by_form_element_name[item.field_name];
            } else {
                item.options.forEach(option => {
                    const option_dom = document.getElementById(option.key);
                    // console.log('option..', option.key, option_dom);
                    if (option_dom && option_dom.checked) {
                        value_array.push(option.key);
                    }
                    // this.getValueByName('input_'+option.key)
                });
            }
        } else if (item.element === 'Dropdown') {
            if (answers_by_form_element_name[item.field_name]) {
                value_array.push(answers_by_form_element_name[item.field_name]);
            } else {
                const val = this.getValueByName(item.field_name);
                // console.log('Dropdown', item, val);

                item.options.forEach(option => {
                    if (option && option.value == val) {
                        value_array.push(option.value);
                    }
                });
            }
        } else if (item.element === 'Matrix') {
            if (answers_by_form_element_name[item.field_name]) {
                value_array = answers_by_form_element_name[item.field_name];
            } else {
                value_array = this.getMatrixValues(item);
            }
        } else if (answers_by_form_element_name[item.field_name]) {
            value_array.push(answers_by_form_element_name[item.field_name]);
        } else {
            // const $item = this.getBasicItemValue(item);
            const val = this.getValueByName(item.field_name);
            // console.log('regular', val);
            value_array.push(val);
        }

        return value_array;
    },
    getOptionsByKey(item, item_options, option_key) {
        const answers = [];
        if (item_options) {
            if (option_key.constructor === Array) {
                //console.log('arr', item_options, option_key)
                option_key.forEach(o_k_single => {
                    const found = item_options.find(option => option.key == o_k_single);
                    if (found) {
                        answers.push(found);
                    }
                });
            } else {
                //console.log('not array', item, option_key);
                let found;
                if (item.element == 'Dropdown') {
                    //console.log('dd', item_options, option_key)
                    found = item_options.find(option => option.value == option_key);
                } else {
                    found = item_options.find(option => option.key == option_key);
                }

                if (found) {
                    answers.push(found);
                }
            }
        }
        return answers;
    },
    getItemValueHumanReadable(item, answers_by_form_element_name, all_questions_flat) {
        const value_array = [];
        if (item.element === 'Checkboxes' || item.element === 'RadioButtons' || item.element === 'Captcha') {
            // console.log(item.element, item.field_name);

            if (answers_by_form_element_name[item.field_name]) {
                const opts = this.getOptionsByKey(item, item.options, answers_by_form_element_name[item.field_name]);
                // console.log('human readable?', answers_by_form_element_name[item.field_name], opts)
                if (opts) {
                    opts.forEach(o => {
                        value_array.push(o.text);
                    });
                }
            } else {
                item.options.forEach(option => {
                    const option_dom = document.getElementById(option.key);
                    if (option_dom && option_dom.checked) {
                        value_array.push(option.text);
                    }
                });
            }
        } else if (item.element === 'Dropdown') {
            let item_options = cloneDeep(item.options);
            if (item.pipe_options_from && all_questions_flat) {
                let question_piped_from = all_questions_flat.find(q => q.field_name == item.pipe_options_from);
                if (question_piped_from && question_piped_from.options) {
                    item_options = item_options.concat(question_piped_from.options);
                }
            }

            if (answers_by_form_element_name[item.field_name]) {
                const opts = this.getOptionsByKey(item, item_options, answers_by_form_element_name[item.field_name]);
                // console.log('human readable?', answers_by_form_element_name[item.field_name], opts)
                if (opts) {
                    opts.forEach(o => {
                        value_array.push(o.text);
                    });
                }
            } else {
                const val = this.getValueByName(item.field_name);
                // console.log('Dropdown', item, val);

                item_options.forEach(option => {
                    if (option && option.value == val) {
                        value_array.push(option.text);
                    }
                });
            }
        } else if (answers_by_form_element_name[item.field_name]) {
            //console.log('human readable?', answers_by_form_element_name[item.field_name]);
            value_array.push(answers_by_form_element_name[item.field_name]);
        } else {
            // const $item = this.getBasicItemValue(item);
            const val = this.getValueByName(item.field_name);
            // console.log('regular', val);
            value_array.push(val);
        }

        return value_array;
    },
    imageUploadButton(element_id, callbackUploading, callbackDone) {
        // console.log('......hi', element_id);
        document.getElementById(element_id).click();
        document.getElementById(element_id).onchange = e => {
            callbackUploading();

            const formData = new FormData();
            formData.append('image', e.target.files[0]);

            const account_id = auth.getAccountId();

            xhr.post(`/study-images?account_id=${account_id}`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(uploaded_image_response => {
                    callbackDone(false, uploaded_image_response.data);
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    callbackDone(true, errorText);
                });
        };
    },
    imageRemove(image_url, callback) {
        const account_id = auth.getAccountId();
        xhr.delete(`/study-images?image_url=${image_url}&account_id=${account_id}`, {
            withCredentials: true
        })
            .then(removed_image_response => {
                callback(false);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                callback(true, errorText);
            });
    },
    getQuestionByNumber(all_questions, page_id, question_id) {
        return all_questions[parseInt(page_id) - 1][parseInt(question_id) - 1];
    },
    getQuestionAnswerByNumber(all_questions, page_id, question_id, answers_by_form_element_name) {
        const item = this.getQuestionByNumber(all_questions, page_id, question_id);
        if (item) {
            return helpersScreener.getItemValueHumanReadable(item, answers_by_form_element_name);
        }
    },
    // accepts p=N,q=N
    // or {p=N,q=N}
    getPipingPageIndexQuestionIndex(pipe_value) {
        let page_id, question_id;

        if (pipe_value) {
            pipe_value = pipe_value.replace('{', '').replace('}', '');

            const pipe_trimmed = pipe_value.trim();
            const two_pieces = pipe_trimmed.split(',');
            //console.log('two_pieces', two_pieces)
            if (two_pieces.length == 2) {
                const page_id_config = two_pieces[0];
                const question_id_config = two_pieces[1];

                // console.log(page_id_config, question_id_config)

                const index_page_id = page_id_config.indexOf('pg=');
                if (index_page_id != -1) {
                    page_id = page_id_config.substring(3);
                }

                const index_question_id = question_id_config.indexOf('q=');
                if (index_question_id != -1) {
                    question_id = question_id_config.substring(2);
                }
            }
        }

        return { page_id, question_id };
    },
    getPipedAnswerOptions(question_piped_from, answers_by_form_element_name) {
        //console.log('getPipedAnswerOptions', pipe_options_from, all_questions, answers_by_form_element_name)
        let piped_answer_options = [];

        //console.log('piping q', question_piped_from, answers_by_form_element_name);

        if (question_piped_from) {
            const piping_question_answers = this.getItemValue(question_piped_from, answers_by_form_element_name);
            //console.log('piping_question_answers', piping_question_answers)

            piping_question_answers.forEach(pqa_key => {
                let pqa_option;
                //console.log('pqa_key', pqa_key)
                if (question_piped_from.element == 'Dropdown') {
                    pqa_option = question_piped_from.options.find(o => o.value == pqa_key);
                } else {
                    pqa_option = question_piped_from.options.find(o => o.key == pqa_key);
                }
                //console.log(pqa_option);
                if (pqa_option) {
                    piped_answer_options.push(pqa_option);
                }
            });
        }

        //console.log('piped_answer_options', piped_answer_options)
        return piped_answer_options;
    },
    insertPiping(string, all_questions, answers_by_form_element_name) {
        // console.log(props.answers_by_form_element_name);

        // detect if question has piping logic

        try {
            const found = []; // an array to collect the strings that are found
            const rxp = /{([^}]+)}/g;
            let curMatch;

            while ((curMatch = rxp.exec(string))) {
                found.push(curMatch[1]);
            }

            if (found.length) {
                found.forEach(pipe => {
                    let { page_id, question_id } = this.getPipingPageIndexQuestionIndex(pipe);

                    if (page_id && question_id) {
                        const answer = this.getQuestionAnswerByNumber(
                            all_questions,
                            page_id,
                            question_id,
                            answers_by_form_element_name
                        );
                        // console.log('got answer', answer)
                        string = string.replace(`{${pipe}}`, answer);
                    }
                });
            }

            return string;
        } catch (e) {
            helpers.trackError(e);
            return string;
        }
    },
    getQuestionByFieldName(all_questions, field_name) {
        let found = false;
        // console.log('all_questions', all_questions)
        if (all_questions) {
            found = all_questions.find(q => q && q.field_name && q.field_name == field_name);
        }
        return found;
    },
    getQuestionById(all_questions, question_id) {
        let found = false;
        if (all_questions) {
            const all_questions_flat = [].concat.apply([], all_questions);
            found = all_questions_flat.find(q => q && q.id && q.id == question_id);
        }
        return found;
    },
    getQuestionTypeById(all_questions, question_id) {
        let found_type = false;
        if (all_questions) {
            const all_questions_flat = [].concat.apply([], all_questions);
            const found = all_questions_flat.find(q => q && q.id && q.id == question_id);
            if (found) {
                found_type = found.element;
            }
        }
        return found_type;
    },
    /**
     *
     * @param {*} all_questions
     * @param {*} question_id
     * @param {*} conditional_compare_type
     * @param {*} existing_value
     * @param {*} conditional_index
     * @param {*} editConditionalValueFunction
     * @param {*} rule_index
     * @param {number|string} hostElementId - the id of the container element, this is used to make sure the element is unique
     */
    getQuestionValuesForLogic(
        all_questions,
        question_id,
        conditional_compare_type,
        existing_value,
        conditional_index,
        editConditionalValueFunction,
        rule_index,
        hostElementId
    ) {
        let element;

        //console.log('getQuestionValuesForLogic', existing_value)

        const text_or_numeric_element = (
            <Input
                className="theme-input theme-input-simple"
                sx={{ width: '200px' }}
                mr={2}
                type="text"
                value={existing_value}
                onChange={e => editConditionalValueFunction(conditional_index, e, rule_index)}
            />
        );

        if ([SCREENER_LOGIC_NOT_ANSWERED, SCREENER_LOGIC_ANSWERED].includes(conditional_compare_type)) {
            // this doesn't require anything to render!
        } else {
            all_questions.forEach(page_questions => {
                page_questions.forEach(pq => {
                    if (!pq.static && pq.id == question_id) {
                        if (helpersScreener.isElementTextMatrix(pq)) {
                            element = text_or_numeric_element;
                        } else if (helpersScreener.getElementNamesWithOptions().includes(pq.element)) {
                            let available_options = [];
                            //let options = [];
                            // blank
                            //options.push(<option></option>);

                            if (pq.element == 'Matrix') {
                                let matrix_options = helpersStudy.generateMatrixAnswerCombinations(pq.options);
                                //console.log('matrix_options', matrix_options)
                                if (matrix_options) {
                                    //console.log(matrix_options);
                                    matrix_options.forEach(option => {
                                        available_options.push({
                                            value: option.row_col_key,
                                            text: option.row_col_text
                                        });
                                        //options.push(<option value={option.row_col_key}>{option.row_col_text}</option>);
                                    });
                                }
                            } else {
                                pq.options.forEach(option => {
                                    let option_text_readable = option.text;
                                    if (option.is_add_other) {
                                        option_text_readable = 'Other';
                                    } else if (option.is_none_of_the_above) {
                                        option_text_readable = 'None of the above';
                                    }

                                    if (pq.element == 'Dropdown') {
                                        available_options.push({
                                            value: option.value,
                                            text: option_text_readable
                                        });
                                        //options.push(<option value={option.value}>{option_text_readable}</option>);
                                    } else {
                                        available_options.push({
                                            value: option.key,
                                            text: option_text_readable
                                        });
                                        //options.push(<option value={option.key}>{option_text_readable}</option>);
                                    }
                                });
                            }

                            if (
                                [SCREENER_LOGIC_CONTAINS_ANY_OF, SCREENER_LOGIC_NOT_CONTAINS_ANY_OF].includes(
                                    conditional_compare_type
                                )
                            ) {
                                const onChangeLogicCheckbox = (conditional_index, e, rule_index) => {
                                    //console.log(conditional_index, e.target.checked, rule_index)
                                    //console.log();
                                    let elementName = `${question_id}_${conditional_index}_${rule_index}_${hostElementId}`;
                                    let checkboxArrayOfValues = [];
                                    document.getElementsByName(elementName).forEach(cn => {
                                        if (cn.checked) {
                                            checkboxArrayOfValues.push(cn.value);
                                            //console.log(cn.value, cn.checked)
                                        }
                                    });

                                    editConditionalValueFunction(
                                        conditional_index,
                                        { target: { name: elementName, value: checkboxArrayOfValues } },
                                        rule_index
                                    );
                                };
                                element = (
                                    <>
                                        {available_options.map(ao => {
                                            return (
                                                <div>
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            value={ao.value}
                                                            name={`${question_id}_${conditional_index}_${rule_index}_${hostElementId}`}
                                                            checked={
                                                                Array.isArray(existing_value) &&
                                                                existing_value.includes(ao.value)
                                                            }
                                                            onChange={e =>
                                                                onChangeLogicCheckbox(conditional_index, e, rule_index)
                                                            }
                                                        />{' '}
                                                        {ao.text}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </>
                                );
                            } else {
                                element = (
                                    <Select
                                        className="theme-input theme-input-simple"
                                        sx={{ width: '200px' }}
                                        mr={2}
                                        value={existing_value}
                                        onChange={e => editConditionalValueFunction(conditional_index, e, rule_index)}
                                    >
                                        <option></option>
                                        {/* add blank option so nothing is pre-selected*/}
                                        {available_options.map(ao => {
                                            return <option value={ao.value}>{ao.text}</option>;
                                        })}
                                    </Select>
                                );
                            }
                        } else if (helpersScreener.getElementNamesTextOrNumericInputs().includes(pq.element)) {
                            element = text_or_numeric_element;
                        } else if (helpersScreener.getElementNamesDates().includes(pq.element)) {
                            //console.log('datee for conditional' ,existing_value, moment(existing_value).toDate())
                            let existing_date = moment(existing_value);
                            if (existing_date.isValid()) {
                                existing_date = moment(existing_value).toDate();
                            } else {
                                existing_date = '';
                            }
                            element = (
                                <>
                                    <DatePicker
                                        className="theme-input"
                                        selected={existing_date}
                                        onChange={date => {
                                            const event = { target: { name: 'date', value: date } };
                                            editConditionalValueFunction(conditional_index, event, rule_index);
                                        }}
                                        showYearDropdown
                                        dropdownMode="select"
                                        showMonthDropdown
                                    />
                                </>
                            );
                        }
                    }
                });
            });
        }

        return element;
    },
    renderScreenerLogicOptionsByQuestion(question) {
        const question_type = question ? question.element : null;
        let options = [];
        let render_elements = [];

        try {
            if (helpersScreener.isElementTextMatrix(question)) {
                options = SCREENER_LOGIC_COMPARE['TEXT'];
            } else if (helpersScreener.getElementNamesWithOptions().includes(question_type)) {
                options = SCREENER_LOGIC_COMPARE['WITH_OPTIONS'];
            } else if (helpersScreener.getElementNamesDates().includes(question_type)) {
                options = SCREENER_LOGIC_COMPARE['DATE'];
            } else {
                options = SCREENER_LOGIC_COMPARE['TEXT'];
            }

            render_elements.push(<option value={''}></option>);
            options.forEach(o => {
                render_elements.push(<option value={o.id}>{o.title}</option>);
            });
        } catch (e) {
            helpers.trackError(e);
        }

        return render_elements;
    },
    validateRegex(string, regex) {
        try {
            if (string && regex) {
                return regex.test(String(string).toLowerCase());
            }
        } catch (e) {
            helpers.trackError(e);
        }
    },
    isElementTextMatrix(element) {
        if (element && element.element == 'Matrix' && this.getMatrixType(element) == 'text') {
            return true;
        } else {
            return false;
        }
    },
    getMatrixType(element) {
        let type;
        switch (element.matrix_allow_multiple) {
            case 2:
            case '2':
                type = 'text';
                break;
            case 1:
            case '1':
            case true: // this is to be backwards compatible
                type = 'checkbox';
                break;
            case 0:
            case '0':
            default:
                type = 'radio';
                break;
        }

        return type;
    },
    getCaptchaValueHumanReadable(value) {
        switch (value) {
            case '0':
                return 'Captcha failed';
            case '1':
                return 'Captcha OK';
            default:
                return 'Unknown captcha value';
        }
    },
    isExternalSurveyConnected(screener) {
        return !!screener.qualtrics_survey_id;
    },
    getScreenerIcon(screener) {
        let icon = null;

        if (screener.qualtrics_survey_id) {
            icon = <img src="/qualtrics-16x16.svg" alt="Qualtrics" style={{ marginRight: '4px' }} />;
        }

        return icon;
    },
    /**
     * Normalize value to be used as a key.
     *
     * @param {string} value Value to normalize
     * @returns {string} Normalized value
     * @example
     * const value = 'Hello World';
     * console.log(normalizeValue(value)); // hello_world
     */
    normalizeValue(value) {
        return value.replace(/[^A-Z0-9]+/gi, '_').toLowerCase();
    }
};

export default helpersScreener;
