import React, { useMemo, useState } from 'react';
import config from 'config';
import { Helmet } from 'react-helmet';
import { Box, Button, Flex } from 'rebass';
import { Label, Input, Select, Textarea, Switch } from '@rebass/forms';
import {
    FiXCircle,
    FiUserPlus,
    FiX,
    FiFilter,
    FiEdit,
    FiChevronDown,
    FiCopy,
    FiRefreshCw,
    FiMoreVertical,
    FiMove,
    FiUsers,
    FiExternalLink,
    FiDownloadCloud,
    FiBookOpen,
    FiPlus
} from 'react-icons/fi';
import { useToasts, withToastManager } from 'react-toast-notifications';
import cloneDeep from 'lodash/cloneDeep';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ReactTooltip from 'react-tooltip';
import services from 'services/services';
import service_studies from 'services/studies';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import StudyFilters from 'components/Study/StudyFilters';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import NiceDropdown from 'components/NiceDropdown';
import SideOverlay from 'components/SideOverlay';
import { List, arrayMove } from 'react-movable';
import NiceModal from 'components/NiceModal';
import { ActionButton } from 'components/Button/ActionButton';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import studyService from 'services/studies';
import cache from 'utils/cache';
import CSVClipboard from 'components/CSVClipboard';
import useQuery from 'hooks/useQuery';
import QuotaWhenSelect from 'components/Quota/QuotaWhenSelect';

class StudyQuotasPage extends React.Component {
    constructor(props) {
        super(props);
        const studyId = this.props.match.params.id;
        const study = cache.get(`study.${studyId}`);

        this.state = {
            allQuotasLoading: true,
            study: {
                title: '',
                description: '',
                ...study
            },
            isStudyLoading: !study,
            quotas: [],
            panel_columns: [],
            screenerFilters: [],
            showScreenerFilters: false,
            editingQuotaId: null,
            editingRuleId: null,
            editingQuotaRuleObject: {},
            quotaIdLoading: null,
            currentQuotaId: null,
            showBulkModal: false,
            bulkQuotaRules: '',
            respondentsModalQuotaTitle: '',
            respondentsModalRuleTitle: '',
            quotaId: null,
            ruleId: null
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onEditQuotaRule = this.onEditQuotaRule.bind(this);
        this.onDeleteQuotaRule = this.onDeleteQuotaRule.bind(this);
        this.onCreateQuotaGroup = this.onCreateQuotaGroup.bind(this);
        this.onEditQuotaGroup = this.onEditQuotaGroup.bind(this);
        this.onSaveQuotaRuleObject = this.onSaveQuotaRuleObject.bind(this);
        this.onEditQuotaRuleMetadata = this.onEditQuotaRuleMetadata.bind(this);
        this.onAddBulkQuotaRules = this.onAddBulkQuotaRules.bind(this);
    }

    async getStudy() {
        const { toastManager } = this.props;
        const studyId = this.props.match.params.id;

        try {
            const study = await studyService.getStudy(studyId);
            this.setState({ study });
            cache.set(`study.${studyId}`, study);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        } finally {
            this.setState({ isStudyLoading: false });
        }
    }

    componentDidMount() {
        const { toastManager } = this.props;

        this.getStudy();

        service_studies
            .getStudyQuotas(this.props.match.params.id)
            .then(quotas => {
                this.setState({ quotas: quotas, allQuotasLoading: false });
            })
            .catch(error => {
                console.log(error);

                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });

        this.getCustomDataColumnsXHR();
    }

    createQuotaRuleObject(title) {
        title = title ? title : '';
        return {
            id: helpers.generateUID(),
            min: 1,
            max: 5,
            filters: [],
            when: {},
            title: title
        };
    }

    getCustomDataColumnsXHR() {
        services
            .getCustomDataColumnsXHR()
            .then(columns_xhr => {
                //console.log('GOT NEW COLUMNS', columns_xhr);
                this.setState({ panel_columns: columns_xhr });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                console.log(errorText);
            });
    }

    calculateQuota(quota_id) {
        const { toastManager } = this.props;

        this.setState({ quotaIdLoading: quota_id });

        service_studies
            .calculateStudyQuota(this.state.study.id, quota_id)
            .then(quota => {
                //console.log(quota);
                let { quotas } = this.state;
                let quotaIndex = quotas.findIndex(q => q.id == quota.id);
                quotas[quotaIndex] = quota;
                this.setState({ quotas: quotas, quotaIdLoading: null });

                toastManager.add('Quota calculation updated', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                this.setState({ quotaIdLoading: null });

                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onFormSubmit(e) {
        e.preventDefault();

        const study = { ...this.state.study };
        this.saveStudyXHR(study);
    }

    onFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const study = { ...this.state.study };
        study[e.target.name] = e.target.value;

        this.setState({ study });

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(
            function() {
                this.saveStudyXHR(study);
            }.bind(this),
            500
        );
    }
    onRenameQuotaGroup(quota_id) {
        let { quotas } = this.state;
        const quotaIndex = quotas.findIndex(q => q.id == quota_id);

        if (quotaIndex >= 0) {
            let title = prompt('What is the title?', quotas[quotaIndex].title);
            title = title ? title.trim() : '';
            if (!title) {
                return;
            }
            quotas[quotaIndex].title = title;
            this.onEditQuotaGroup(quotas[quotaIndex]);
            this.setState({ quotas });
        }
    }

    onCreateQuotaGroup(quotaToDuplicate) {
        const { toastManager } = this.props;

        let title = prompt('What is the title?');
        title = title ? title.trim() : '';
        if (!title) {
            return;
        }

        let quotaToCreate = {};
        if (quotaToDuplicate) {
            quotaToCreate = cloneDeep(quotaToDuplicate);

            // change the quota rule IDs, if they exist
            if (quotaToCreate.rules) {
                quotaToCreate.rules.forEach(rule => {
                    rule.id = helpers.generateUID();
                });
            }
        }
        quotaToCreate.title = title;

        service_studies
            .studyQuotaCreate(this.state.study.id, quotaToCreate)
            .then(quota_xhr => {
                let { quotas } = this.state;
                quotas.push(quota_xhr);
                this.setState({ quotas });

                toastManager.add('Quota group added', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSaveQuotaRuleObject(e) {
        e.preventDefault();

        let { editingQuotaRuleObject, editingQuotaId } = this.state;

        let { quotas } = this.state;
        let quotaIndex = quotas.findIndex(q => q.id == this.state.editingQuotaId);
        let quota = quotas[quotaIndex];

        let ruleIndex = quota.rules.findIndex(r => r.id == editingQuotaRuleObject.id);
        quota.rules[ruleIndex] = editingQuotaRuleObject;

        this.onEditQuotaGroup(quota);
        this.setState({ showModalEditRule: false });
    }

    onEditQuotaRuleMetadata(e) {
        let { editingQuotaRuleObject } = this.state;
        editingQuotaRuleObject[e.target.name] = e.target.value;
        this.setState({ editingQuotaRuleObject });
    }

    onEditQuotaGroup(quota) {
        //console.log(quota);

        const { toastManager } = this.props;

        service_studies
            .studyQuotaEdit(this.state.study.id, quota)
            .then(quota_xhr => {
                //let { quotas } = this.state;
                //quotas.push(quota_xhr);

                toastManager.add('Quota group updated', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onDeleteQuotaGroup(quota_id) {
        // xhr
        if (confirm('Are you sure you want to delete this Quota Group?')) {
            const { toastManager } = this.props;

            this.setState({ quotaIdLoading: quota_id });

            service_studies
                .studyQuotaDelete(this.state.study.id, quota_id)
                .then(quota_xhr => {
                    toastManager.add('Quota group deleted', {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    let { quotas } = this.state;
                    quotas.splice(
                        quotas.findIndex(q => q.id == quota_id),
                        1
                    );
                    this.setState({ quotas });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    onCreateQuotaRule(quota_id) {
        let title = prompt('What is the title?');
        title = title ? title.trim() : '';
        if (!title) {
            return;
        }

        let { quotas } = this.state;
        const quotaIndex = quotas.findIndex(q => q.id == quota_id);
        //console.log(quotaIndex, quotas[quotaIndex])
        quotas[quotaIndex].rules.push(this.createQuotaRuleObject(title));
        this.setState({ quotas });

        this.onEditQuotaGroup(quotas[quotaIndex]);
    }

    onEditQuotaRule() {}

    onDeleteQuotaRule(quota_id, rule_id) {
        if (!confirm('Are you sure?')) {
            return;
        }
        let { quotas } = this.state;
        console.log(quota_id, rule_id);
        const quotaIndex = quotas.findIndex(q => q.id == quota_id);
        //console.log(quotaIndex, quotas[quotaIndex])
        const ruleIndex = quotas[quotaIndex].rules.findIndex(r => r.id == rule_id);
        quotas[quotaIndex].rules.splice(ruleIndex, 1);
        this.setState({ quotas });

        this.onEditQuotaGroup(quotas[quotaIndex]);
    }

    renderQuotaRuleCount(counts, rule) {
        let className;
        let number = 0;
        if (counts[rule.id]) {
            number = counts[rule.id];
        }

        /*if (number >= rule.min && number <= rule.max) {
            className = 'error';
        }*/
        if (number >= rule.max) {
            className = 'error';
        } else if (number >= rule.min) {
            className = 'orange';
        }

        return <span className={className}>{number}</span>;
    }

    renderScreenerFilterIndividual(sf) {
        //console.log(sf);
        return helpersStudy.renderScreenerFilterIndividual(sf, this.state.study, this.state.panel_columns);
    }

    rearrangeQuotaRules(quota, oldIndex, newIndex) {
        let { quotas } = this.state;
        let quotaIndex = quotas.findIndex(q => q.id == quota.id);

        quota.rules = arrayMove(quota.rules, oldIndex, newIndex);

        quotas[quotaIndex] = quota;

        this.onEditQuotaGroup(quotas[quotaIndex]);

        this.setState({ quotas });
    }

    handleBulkQuotaRulesModal(quotaId) {
        this.setState({ showBulkModal: true });
        this.setState({ currentQuotaId: quotaId });
    }

    onAddBulkQuotaRules(e) {
        e.preventDefault();
        this.setState({ showBulkModal: false });

        const { quotas } = this.state;
        const quotaIndex = quotas.findIndex(q => q.id === this.state.currentQuotaId);
        const new_options = [];

        if (this.state.bulkQuotaRules) {
            const arr = this.state.bulkQuotaRules.split(/\r?\n/);
            if (arr.length) {
                arr.forEach(text_line => {
                    text_line = text_line.trim();
                    if (text_line && text_line.length > 0) {
                        new_options.push(this.createQuotaRuleObject(text_line));
                    }
                });

                quotas[quotaIndex].rules = [...quotas[quotaIndex].rules, ...new_options];
                this.setState({ quotas });
            }
        }

        this.setState({ bulkQuotaRules: '' });
        this.onEditQuotaGroup(quotas[quotaIndex]);
    }

    /**
     * Handle click on the "View" or "All" buttons in the Quota Rule
     *
     * @param {number} quotaId Quota ID
     * @param {string} [ruleId] Rule ID
     * @returns {void}
     */
    handleClickShowModalRespondents(quotaId, ruleId) {
        const { quotas } = this.state;
        const quota = quotas.find(q => q.id === quotaId);

        const loadingState = {
            respondentsModalQuotaTitle: quota.title,
            respondentsModalRuleTitle: null,
            quotaId,
            ruleId
        };

        // Set the rule title if ruleId is provided
        if (ruleId) {
            const rule = quota.rules.find(r => r.id === ruleId);
            loadingState.respondentsModalRuleTitle = rule.title;
        }

        this.setState(loadingState);
    }

    handleExport() {
        return service_studies.getStudyQuotasExportLink(this.props.match.params.id);
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title} Settings</title>
                    </Helmet>
                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                            <BreadcrumbTitle isLoading={this.state.isStudyLoading} title={this.state.study.title} />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <Flex>
                        <StudyPageSubNav study_id={this.props.match.params.id} study={this.state.study} />
                    </Flex>
                    <AppPageWrapperSectionHeader className="experience">
                        <Box mt={3}>
                            <H1>Quota Grids</H1>
                            <Box className="experience-description">
                                See if you've reached the minimum or maximum number of recruits for a quota. This can be
                                used for a single project or multiple focus groups.
                                <a href="#" data-beacon-article="6370a602ba34be51af419514">
                                    <FiBookOpen /> How to use Quota Grids
                                </a>
                            </Box>
                        </Box>
                        <Box mt={3}>
                            <ActionButton
                                title="New Quota Group"
                                action={() => this.onCreateQuotaGroup()}
                                keyLabel="N"
                                mr={0}
                            />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <AppPageWrapperSectionBody>
                        {this.state.allQuotasLoading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <Box mx={4} mt={3} mb={4} sx={{ width: '100%', minHeight: '600px' }}>
                                <Flex sx={{ width: '100%', gridGap: 3 }} mt={3} mb={'24px'}>
                                    <Box style={{ color: 'black', fontSize: '18px' }}>
                                        {!this.state.allQuotasLoading && (
                                            <strong>
                                                {this.state.quotas.length}{' '}
                                                {this.state.quotas.length == 1 ? 'quota group' : 'quota groups'}
                                            </strong>
                                        )}
                                    </Box>
                                    <Flex sx={{ flexDirection: 'row' }}>
                                        {this.state.quotas.length > 0 && (
                                            <a href={this.handleExport()}>
                                                <Button type="button" variant="secondary-gray">
                                                    <FiDownloadCloud /> Export all groups
                                                </Button>
                                            </a>
                                        )}
                                    </Flex>
                                </Flex>
                                {this.state.quotas &&
                                    this.state.quotas.map(quota => {
                                        return (
                                            <Box
                                                className="border nice-boxshadow-light"
                                                mb={4}
                                                sx={{
                                                    width: '100%',
                                                    background: '#fff',
                                                    maxWidth: '900px',
                                                    opacity: this.state.quotaIdLoading == quota.id ? '0.5' : '1',
                                                    borderRadius: '10px'
                                                }}
                                                fontSize={1}
                                            >
                                                <Flex justifyContent="space-between" margin={'24px 24px 16px 24px'}>
                                                    <Box mt={1}>
                                                        <Label className="bold text-primary" fontSize={2}>
                                                            {quota.title}
                                                        </Label>
                                                    </Box>
                                                    <Box sx={{ position: 'relative' }}>
                                                        <Button
                                                            variant="secondary-gray"
                                                            mr={3}
                                                            onClick={() => this.calculateQuota(quota.id)}
                                                        >
                                                            <FiRefreshCw /> Re-calculate
                                                        </Button>
                                                        <Button
                                                            variant="secondary-gray"
                                                            className="secondary-icon"
                                                            mr={0}
                                                            onClick={() => {
                                                                this.setState({
                                                                    showQuotaGroupEditDropdownId: quota.id
                                                                });
                                                            }}
                                                        >
                                                            <FiMoreVertical style={{ marginRight: 0 }} />
                                                        </Button>
                                                        {this.state.showQuotaGroupEditDropdownId == quota.id && (
                                                            <NiceDropdown
                                                                positionRight="0"
                                                                width="120px"
                                                                onClose={() => {
                                                                    this.setState({
                                                                        showQuotaGroupEditDropdownId: false
                                                                    });
                                                                }}
                                                                items={[
                                                                    {
                                                                        title: 'Rename',
                                                                        icon: <FiEdit />,
                                                                        onClick: () => {
                                                                            this.onRenameQuotaGroup(quota.id);
                                                                        }
                                                                    },
                                                                    {
                                                                        title: 'Duplicate',
                                                                        icon: <FiCopy />,
                                                                        onClick: () => {
                                                                            this.onCreateQuotaGroup(quota);
                                                                        }
                                                                    },
                                                                    {
                                                                        title: 'Delete',
                                                                        icon: <FiXCircle />,
                                                                        color: 'red',
                                                                        onClick: () => {
                                                                            this.onDeleteQuotaGroup(quota.id);
                                                                        }
                                                                    }
                                                                ]}
                                                            />
                                                        )}
                                                    </Box>
                                                </Flex>

                                                <Flex sx={{ gridGap: 1 }}>
                                                    <Box margin={'0 24px 0 24px'}>
                                                        <Box className="text-secondary fs-12">Total people</Box>
                                                        <Button
                                                            onClick={() =>
                                                                this.handleClickShowModalRespondents(quota.id)
                                                            }
                                                            style={{ height: '30px' }}
                                                            variant="transparent"
                                                            className="text-primary medium"
                                                        >
                                                            <FiUsers style={{ marginRight: '6px' }} />
                                                            {quota.counts && quota.counts.total
                                                                ? quota.counts.total
                                                                : 0}
                                                        </Button>
                                                    </Box>
                                                    <Box>
                                                        <Box className="text-secondary fs-12">Calculate when</Box>
                                                        {!this.state.isStudyLoading && (
                                                            <QuotaWhenSelect
                                                                study={this.state.study}
                                                                defaultValue={quota.when}
                                                                onChange={quota_when => {
                                                                    // update filters for this specific rule in a quota
                                                                    let { quotas } = this.state;
                                                                    let quotaIndex = quotas.findIndex(
                                                                        q => q.id == quota.id
                                                                    );
                                                                    quotas[quotaIndex].when = quota_when;
                                                                    this.onEditQuotaGroup(quotas[quotaIndex]);
                                                                }}
                                                                transparent={true}
                                                            />
                                                        )}
                                                    </Box>
                                                </Flex>

                                                <ItemWrapper
                                                    className="header-row fs-12"
                                                    style={{ padding: '10px 24px' }}
                                                >
                                                    <Box width={9 / 16}>Rule</Box>
                                                    {/*<Box width={1 / 16}>Filters</Box>*/}
                                                    <Box width={1 / 16}>
                                                        {quota.rules && quota.rules.length > 0 && 'Min'}
                                                    </Box>
                                                    <Box width={1 / 16}>
                                                        {quota.rules && quota.rules.length > 0 && 'Max'}
                                                    </Box>
                                                    <Box width={2 / 16}>
                                                        {quota.rules && quota.rules.length > 0 && 'Count'}
                                                    </Box>
                                                    <Box width={3 / 16}>
                                                        {quota.rules && quota.rules.length > 0 && 'Actions'}
                                                    </Box>
                                                </ItemWrapper>
                                                {quota.rules && quota.rules.length > 0 ? (
                                                    <List
                                                        values={quota.rules}
                                                        onChange={({ oldIndex, newIndex }) => {
                                                            //console.log(oldIndex, newIndex)
                                                            this.rearrangeQuotaRules(quota, oldIndex, newIndex);
                                                        }}
                                                        renderList={({ children, props }) => (
                                                            <div {...props}>{children}</div>
                                                        )}
                                                        renderItem={({
                                                            value,
                                                            index,
                                                            props,
                                                            isDragged,
                                                            isSelected
                                                        }) => {
                                                            let rule = value;

                                                            return (
                                                                <div {...props} id={`quota-rule-row-${rule.id}`}>
                                                                    <ItemWrapper
                                                                        className="lean"
                                                                        style={{
                                                                            paddingTop: '8px',
                                                                            paddingBottom: '8px',
                                                                            paddingLeft: '24px',
                                                                            paddingRight: '24px'
                                                                        }}
                                                                        key={rule.title}
                                                                    >
                                                                        <Flex
                                                                            sx={{ width: '100%' }}
                                                                            className="text-primary"
                                                                        >
                                                                            <Box
                                                                                width={9 / 16}
                                                                                className="flex-vcenter"
                                                                            >
                                                                                <Box
                                                                                    mr={2}
                                                                                    className="black ellipsis medium"
                                                                                >
                                                                                    <Button
                                                                                        data-movable-handle
                                                                                        type="button"
                                                                                        variant="transparent"
                                                                                        className="light"
                                                                                        tabIndex={-1}
                                                                                        mr={3}
                                                                                        style={{
                                                                                            cursor: isDragged
                                                                                                ? 'grabbing'
                                                                                                : 'grab'
                                                                                        }}
                                                                                    >
                                                                                        <FiMove />
                                                                                    </Button>
                                                                                    {rule.title}
                                                                                </Box>
                                                                            </Box>
                                                                            {/*<Box width={2/8}>{JSON.stringify(rule.when)}</Box>
                                                                            <Box
                                                                                width={1 / 16}
                                                                                className="flex-vcenter"
                                                                            >
                                                                                
                                                                                <span className="text-secondary">
                                                                                    {rule.filters.length}
                                                                                </span>
                                                                            </Box>*/}
                                                                            <Box
                                                                                width={1 / 16}
                                                                                className="flex-vcenter medium"
                                                                            >
                                                                                {rule.min}
                                                                                {/*<Input type='number' value={rule.min} name='min' onChange={(e) => {
                                                                            this.onEditQuotaRuleMetadata(quota.id, rule.id, 'min', e.target.value)
                                                                        }}/>*/}
                                                                            </Box>
                                                                            <Box
                                                                                width={1 / 16}
                                                                                className="flex-vcenter medium"
                                                                            >
                                                                                {rule.max}
                                                                                {/*<Input type='number' value={rule.max} name='max' onChange={(e) => {
                                                                            this.onEditQuotaRuleMetadata(quota.id, rule.id, 'max', e.target.value)
                                                                        }}/>*/}
                                                                            </Box>
                                                                            <Box
                                                                                width={2 / 16}
                                                                                className="flex-vcenter medium"
                                                                            >
                                                                                {this.renderQuotaRuleCount(
                                                                                    quota.counts,
                                                                                    rule
                                                                                )}
                                                                            </Box>
                                                                            <Box
                                                                                width={3 / 16}
                                                                                sx={{
                                                                                    textAlign: 'left',
                                                                                    position: 'relative'
                                                                                }}
                                                                            >
                                                                                <Button
                                                                                    variant="transparent"
                                                                                    //className='secondary-icon'
                                                                                    onClick={() =>
                                                                                        this.handleClickShowModalRespondents(
                                                                                            quota.id,
                                                                                            rule.id
                                                                                        )
                                                                                    }
                                                                                    mr={3}
                                                                                >
                                                                                    <FiUsers /> View all
                                                                                </Button>

                                                                                <Button
                                                                                    variant="transparent"
                                                                                    className="secondary-icon"
                                                                                    mr={0}
                                                                                    ml={2}
                                                                                    onClick={e => {
                                                                                        // set parent z-index to 1 b/c of drag-drop and dropdown not working smoothly
                                                                                        const qrr = document.getElementById(
                                                                                            `quota-rule-row-${rule.id}`
                                                                                        );
                                                                                        if (qrr) {
                                                                                            qrr.style.zIndex = 1;
                                                                                        }
                                                                                        this.setState({
                                                                                            showQuotaRuleEditDropdownId: `${quota.id}_${rule.id}`
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    <FiMoreVertical
                                                                                        style={{ marginRight: 0 }}
                                                                                    />
                                                                                </Button>
                                                                                {this.state
                                                                                    .showQuotaRuleEditDropdownId ==
                                                                                    `${quota.id}_${rule.id}` && (
                                                                                    <NiceDropdown
                                                                                        positionRight="0"
                                                                                        width="120px"
                                                                                        onClose={() => {
                                                                                            this.setState({
                                                                                                showQuotaRuleEditDropdownId: false
                                                                                            });
                                                                                            const qrr = document.getElementById(
                                                                                                `quota-rule-row-${rule.id}`
                                                                                            );
                                                                                            if (qrr) {
                                                                                                qrr.style.zIndex = 0;
                                                                                            }
                                                                                        }}
                                                                                        items={[
                                                                                            {
                                                                                                title: 'Edit Rule',
                                                                                                icon: <FiEdit />,
                                                                                                onClick: () => {
                                                                                                    this.setState({
                                                                                                        showModalEditRule: true,
                                                                                                        editingQuotaRuleObject: cloneDeep(
                                                                                                            rule
                                                                                                        ),
                                                                                                        editingQuotaId:
                                                                                                            quota.id
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                title: 'Edit Filters',
                                                                                                icon: <FiFilter />,
                                                                                                onClick: () => {
                                                                                                    this.setState({
                                                                                                        editingQuotaId:
                                                                                                            quota.id,
                                                                                                        editingRuleId:
                                                                                                            rule.id,
                                                                                                        screenerFilters:
                                                                                                            rule.filters,
                                                                                                        showScreenerFilters: true
                                                                                                    });
                                                                                                }
                                                                                            },
                                                                                            {
                                                                                                title: 'Delete',
                                                                                                icon: <FiXCircle />,
                                                                                                color: 'red',
                                                                                                onClick: () => {
                                                                                                    this.onDeleteQuotaRule(
                                                                                                        quota.id,
                                                                                                        rule.id
                                                                                                    );
                                                                                                }
                                                                                            }
                                                                                        ]}
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </Flex>
                                                                    </ItemWrapper>
                                                                </div>
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    <Box
                                                        margin={'16px 24px'}
                                                        fontSize={3}
                                                        className="text-secondary bold "
                                                    >
                                                        Add your first rule
                                                    </Box>
                                                )}
                                                <Flex margin={'16px 24px'} gap={3} justifyContent="left">
                                                    <Box mr={3} sx={{ textAlign: 'center' }}>
                                                        <Button
                                                            variant="secondary-gray"
                                                            type="button"
                                                            mr={0}
                                                            onClick={() => this.onCreateQuotaRule(quota.id)}
                                                        >
                                                            <FiPlus /> Add Quota Rule
                                                        </Button>
                                                    </Box>

                                                    <Box sx={{ textAlign: 'center' }}>
                                                        <Button
                                                            variant="secondary-gray"
                                                            type="button"
                                                            mr={0}
                                                            onClick={() => this.handleBulkQuotaRulesModal(quota.id)}
                                                        >
                                                            <FiPlus /> Add Bulk Quota Rules
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        );
                                    })}
                            </Box>
                        )}

                        {this.state.showScreenerFilters && (
                            <SideOverlay
                                onClose={() => {
                                    this.setState({ showScreenerFilters: false });
                                }}
                            >
                                <Box my={3} mx={4}>
                                    <H1>Edit Filters</H1>
                                    <StudyFilters
                                        disallowStandardFilters={true}
                                        disallowPanelFilters={true}
                                        study={this.state.study}
                                        panel_columns={this.state.panel_columns}
                                        screenerFilters={this.state.screenerFilters}
                                        onScreenerFiltersChange={sf => {
                                            // update filters for this specific rule in a quota
                                            let { quotas } = this.state;
                                            let quotaIndex = quotas.findIndex(q => q.id == this.state.editingQuotaId);
                                            let quota = quotas[quotaIndex];

                                            // find specific rule
                                            let ruleIndex = quota.rules.findIndex(
                                                r => r.id == this.state.editingRuleId
                                            );
                                            quota.rules[ruleIndex].filters = sf;

                                            this.onEditQuotaGroup(quota);
                                        }}
                                        onClose={() => {
                                            this.setState({
                                                showScreenerFilters: false
                                            });
                                        }}
                                    />
                                </Box>
                            </SideOverlay>
                        )}
                        <NiceModal
                            isOpen={this.state.showModalEditRule}
                            onRequestClose={() => {
                                this.setState({ showModalEditRule: false });
                            }}
                            title="Edit Rule"
                        >
                            <form onSubmit={this.onSaveQuotaRuleObject}>
                                <Label>Title</Label>
                                <Input
                                    autoFocus
                                    type="text"
                                    name="title"
                                    value={this.state.editingQuotaRuleObject.title}
                                    onChange={this.onEditQuotaRuleMetadata}
                                />
                                <Flex mt={3} sx={{ gridGap: 3, width: '100%' }}>
                                    <Box>
                                        <Label>Min</Label>
                                        <Input
                                            type="number"
                                            name="min"
                                            value={this.state.editingQuotaRuleObject.min}
                                            onChange={this.onEditQuotaRuleMetadata}
                                        />
                                    </Box>
                                    <Box>
                                        <Label>Max</Label>
                                        <Input
                                            type="number"
                                            name="max"
                                            value={this.state.editingQuotaRuleObject.max}
                                            onChange={this.onEditQuotaRuleMetadata}
                                        />
                                    </Box>
                                </Flex>
                                <Box className="modal-actions">
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        mr={3}
                                        onClick={() => {
                                            this.setState({ showModalEditRule: false });
                                        }}
                                    >
                                        Cancel
                                    </Button>

                                    <Button type="submit" variant="primary" className="modal-primary" mr={0}>
                                        Save Rule
                                    </Button>
                                </Box>
                            </form>
                        </NiceModal>

                        <NiceModal
                            isOpen={this.state.showBulkModal}
                            shouldCloseOnOverlayClick
                            onRequestClose={() => this.setState({ showBulkModal: false })}
                            title="Add quota rules"
                        >
                            <form onSubmit={this.onAddBulkQuotaRules}>
                                <Box>
                                    <Label>Use a new line for each quota rule title:</Label>
                                    <Textarea
                                        autoFocus
                                        value={this.state.bulkQuotaRules}
                                        onChange={e => this.setState({ bulkQuotaRules: e.target.value })}
                                        height="120px"
                                        required
                                        placeholder={`Gender: Female\nGender: Male`}
                                    />
                                </Box>
                                <Box className="modal-actions">
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        className="modal-primary"
                                        mr={3}
                                        onClick={() => this.setState({ showBulkModal: false })}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" variant="primary" className="modal-primary" mr={0}>
                                        Add quota rules
                                    </Button>
                                </Box>
                            </form>
                        </NiceModal>
                        {this.state.quotaId && (
                            <RespondentsModal
                                isOpen
                                onRequestClose={() => this.setState({ quotaId: null, ruleId: null })}
                                quotaTitle={this.state.respondentsModalQuotaTitle}
                                ruleTitle={this.state.respondentsModalRuleTitle}
                                quotaId={this.state.quotaId}
                                ruleId={this.state.ruleId}
                                studyId={this.props.match.params.id}
                                auth={this.props.auth}
                            />
                        )}
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

/**
 * Respondents component to display respondents in a quota rule.
 *
 * @param {Object} props - Component properties.
 * @param {string} props.quotaTitle - Title of the quota group.
 * @param {string} [props.ruleTitle] - Title of the quota rule.
 * @param {string} props.studyId - ID of the study.
 * @param {number} props.quotaId - ID of the quota group.
 * @param {number} [props.ruleId] - ID of the quota rule.
 * @param {Object} props.auth - Authentication object containing account information.
 * @returns {JSX.Element} The Respondents component.
 */
const Respondents = ({ quotaTitle, ruleTitle, studyId, auth, quotaId, ruleId }) => {
    const { addToast } = useToasts();
    const [excludedPeople, setExcludedPeople] = useState(false);
    const { data, isLoading } = useQuery({
        queryFn: ({ studyId, quotaId, ruleId, excludedPeople }) =>
            service_studies.getQuotaRespondents(studyId, quotaId, ruleId, Number(excludedPeople)),
        variables: {
            studyId,
            quotaId,
            ruleId,
            excludedPeople
        },
        onError: (_, errorText) => {
            addToast(errorText, { appearance: 'error', autoDismiss: true });
        },
        defaultData: []
    });
    const respondents = useMemo(() => {
        const result = data.map(respondent => ({
            id: respondent.id,
            fullName: helpers.personGetFullName(respondent),
            email: helpers.personGetEmail(respondent)
        }));

        // sort by full name (alphabetically, a -> z)
        result.sort((a, b) => a.fullName.localeCompare(b.fullName));

        return result;
    }, [data]);
    const copyData = useMemo(() => {
        return respondents.reduce(
            (acc, respondent) => {
                acc.push([respondent.fullName, respondent.email]);
                return acc;
            },
            [['Name', 'Email']]
        );
    }, [respondents]);

    if (isLoading) return <>Loading...</>;

    return (
        <Box mx={-4}>
            <Flex mx="32px" mb="8px" alignItems="end" justifyContent="space-between" style={{ gap: '16px' }}>
                <Box>
                    <Box as="p" className="text-secondary" mb="24px">
                        <span className="text-primary medium">Total {data.length} respondents</span>
                    </Box>
                    <Flex alignItems="center" style={{ gap: '8px ' }}>
                        <Switch
                            className="switch-small"
                            checked={excludedPeople}
                            onClick={() => setExcludedPeople(prev => !prev)}
                        />
                        <Box as="p">
                            {excludedPeople
                                ? 'Showing people who have been excluded'
                                : 'Show people who have been excluded'}
                        </Box>
                    </Flex>
                </Box>
                <CSVClipboard data={copyData} id="copy-respondents" />
            </Flex>
            {data.length ? (
                <>
                    <ItemWrapper className="header-row" style={{ padding: '10px 32px' }}>
                        <Box width={5 / 11}>Name</Box>
                        <Box width={6 / 11}>Email</Box>
                    </ItemWrapper>
                    {respondents.map(respondent => (
                        <ItemWrapper padding="0 32px" key={respondent.id}>
                            <Box width={5 / 11} className="text-primary medium">
                                {respondent.fullName}
                            </Box>
                            <Box width={5 / 11} className="text-secondary">
                                {respondent.email}
                            </Box>
                            <Box width={1 / 11} textAlign="right">
                                <a
                                    href={`${config.API_URL}/studies/${studyId}/redirect-to-sp/${respondent.id}?account_id=${auth.account.id}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FiExternalLink />
                                </a>
                            </Box>
                        </ItemWrapper>
                    ))}
                </>
            ) : (
                <Box style={{ padding: '32px 32px 10px' }}>0 respondents</Box>
            )}
        </Box>
    );
};

const withRespondentsModal = Component => ({ isOpen, onRequestClose, ruleTitle, quotaTitle, ...props }) => (
    <NiceModal
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={onRequestClose}
        title={ruleTitle || quotaTitle}
        style={{ content: { width: '640px' } }}
    >
        <Component {...props} />
    </NiceModal>
);

const RespondentsModal = withRespondentsModal(Respondents);

export default withToastManager(StudyQuotasPage);
