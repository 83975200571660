import React from 'react';
import { Box, Button, Flex } from 'rebass';
import { Switch, Textarea, Label, Select, Input, Checkbox } from '@rebass/forms';
import { Helmet } from 'react-helmet';
import ReactModal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'components/TooltipIcon';
import { withToastManager } from 'react-toast-notifications';
import moment from 'moment-timezone';
import classNames from 'classnames';
import {
    FiSend,
    FiPlayCircle,
    FiCalendar,
    FiPlus,
    FiSave,
    FiEdit,
    FiTrash2,
    FiMail,
    FiSettings,
    FiAward,
    FiVideo,
    FiCopy,
    FiEye,
    FiArrowRight,
    FiExternalLink
} from 'react-icons/fi';
import { produce } from 'immer';
import config from 'config';
const _ = require('lodash');
import Sticky from 'react-sticky-el';
import cloneDeep from 'lodash/cloneDeep';

import EmailTemplateTypeLabel from 'components/EmailTemplateTypeLabel';
import SimpleModalTextarea from 'components/SimpleModalTextarea';
import TextAreaAutosize from 'react-textarea-autosize';
import ReactModalActions from 'components/ReactModalActions';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import services from 'services/services';
import studyService from 'services/studies';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import H1 from 'components/H1';
import H2 from 'components/H2';
import SideNav from 'components/SideNav';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import EmailEditor from 'components/EmailTools/EmailEditor';
import LocationSettings from 'components/LocationSettings';
import DatePicker from 'react-datepicker';
import { CalendarTrack, CalendarTimeSlot } from 'components/CalendarTrack';
import NiceModal from '../../components/NiceModal';
import UpgradeButton from 'components/UpgradeButton';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import cache from 'utils/cache';

import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
    EMAIL_TEMPLATE_TYPE,
    PAID_FEATURE,
    QUILL_MODULES,
    STUDY_AVAILABILITY_TYPES,
    STUDY_LOCATION_TYPE
} from 'utils/constants';
import SaveEditEditor from 'components/SaveEditEditor';
import { ManageStudyMembersModal } from 'components/ManageStudyMembersModal';
import { Quill } from 'react-quill';
import { StudyUpdatedPopup } from 'components/StudyUpdatedPopup';

const HUMAN_TIME_FORMAT = 'h:mm a';

const Delta = Quill.import('delta');

function flattenNestedList(delta, listType) {
    const newDelta = new Delta();
    delta.ops.forEach(op => {
        if (op.attributes && op.attributes.list) {
            // Flatten nested lists by ensuring all are top-level
            newDelta.insert(op.insert, { list: listType });
        } else {
            newDelta.insert(op.insert, op.attributes || {});
        }
    });
    return newDelta;
}

/**
 * @type {Quill.QuillOptions['modules']}
 *
 * Don't allow nested lists in the editor!
 * Quill editor handles nested lists by adding a class to the list item, which is not supported by the external systems.
 */
const EXTERNAL_CALENDAR_RESTRICTIONS_MODULES = {
    ...QUILL_MODULES,
    toolbar: [
        { header: [1, 2, 3, false] },
        'bold',
        'italic',
        'underline',
        'strike',
        { list: 'ordered' },
        { list: 'bullet' },
        'link',
        'clean'
    ],
    keyboard: {
        ...(QUILL_MODULES.keyboard || {}),
        bindings: {
            ...(QUILL_MODULES.keyboard && QUILL_MODULES.keyboard.bindings ? QUILL_MODULES.keyboard.bindings : {}),
            indent: {
                key: 'Tab',
                format: ['blockquote', 'indent', 'list'],
                handler: () => {}
            }
        }
    },
    clipboard: {
        ...(QUILL_MODULES.clipboard || {}),
        matchers: [
            ...(QUILL_MODULES.clipboard && QUILL_MODULES.clipboard.matchers ? QUILL_MODULES.clipboard.matchers : []),
            // Matcher for preventing pasting of nested lists
            ['OL', (node, delta) => flattenNestedList(delta, 'ordered')],
            ['UL', (node, delta) => flattenNestedList(delta, 'bullet')]
        ]
    }
};

const SESSION_CONFIRMATION_MODULES = {
    ...QUILL_MODULES,
    toolbar: QUILL_MODULES.toolbar.filter(item => !['video'].includes(item))
};

/**
 * Fragment to render minimum scheduling notice input. Used in session and general availability.
 *
 * @param {{ label: string, value: string }[]} options
 * @param {string} value
 * @param {(e: React.ChangeEvent<HTMLSelectElement>) => void} onChange
 * @param {string} [className]
 * @param {React.CSSProperties} [style]
 */
const MinimumSchedulingNotice = ({ options, value, onChange, className, style }) => {
    return (
        <Flex sx={{ gridGap: '24px' }} style={style} className={classNames(className)}>
            <Box width="100%">
                <Box className="form-label">Minimum scheduling notice</Box>
                <Box>
                    <Select
                        name="availability_minimum_booking_notice"
                        value={value}
                        onChange={onChange}
                        sx={{ width: '100% !important' }}
                    >
                        {options.map(option => (
                            <option key={option.label} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                </Box>
            </Box>
        </Flex>
    );
};

/**
 * Fragment to render max scheduled participants per study.
 *
 * @param {number[]} options
 * @param {number} value
 * @param {(e: React.ChangeEvent<HTMLSelectElement>) => void} onChange
 * @param {React.CSSProperties} [style]
 */
const MaxScheduledParticipants = ({ options, value, onChange, style }) => {
    return (
        <Box style={style}>
            <Box className="form-label">Max Scheduled Participants</Box>
            <Box>
                <Select sx={{ width: '100% !important' }} name="participants" value={value} onChange={onChange}>
                    <option value={-1}>Unlimited</option>
                    {options.map(option => (
                        <option key={option} value={option}>
                            {option}
                        </option>
                    ))}
                </Select>
            </Box>
        </Box>
    );
};

/**
 * Fragment to render max sessions per day.
 *
 * @param {number[]} options
 * @param {number} value
 * @param {(e: React.ChangeEvent<HTMLSelectElement>) => void} onChange
 * @param {React.CSSProperties} [style]
 */
const MaxParticipantsPerDay = ({ value, onChange, options, style }) => {
    return (
        <Box style={style}>
            <Box className="form-label">Max Participants Per Day</Box>
            <Select sx={{ width: '100% !important' }} name="max_daily_sessions" value={value} onChange={onChange}>
                <option value={0}>Unlimited</option>
                {options.map(option => (
                    <option key={option} value={option}>
                        {option}
                    </option>
                ))}
            </Select>
        </Box>
    );
};

// eslint-disable-next-line react/no-multi-comp
class StudySchedulingPage extends React.Component {
    constructor(props) {
        super(props);

        const study_id = this.props.match.params.id;
        this.study_id = study_id;

        this.tab_calendar_setup = 'calendar-setup';
        this.tab_calendar_invite_email = 'calendar-invite-email';
        this.tab_calendar_confirmation_email = 'calendar-confirmation-email';
        this.tab_calendar_reminder_email = 'calendar-reminder-email';
        this.tab_calendar_setup_labels = [
            'calendar-setup-scheduling-configuration',
            'calendar-setup-sessions',
            'calendar-setup-general-availability',
            'calendar-setup-calendar-event',
            'calendar-setup-reminders'
        ];

        const study = cache.get(`study.${study_id}`);

        this.state = {
            updatePopupMode: null,
            loading: !study,
            study: {
                title: '',
                description: '',
                reminders: [],
                ...study
            },
            study_users: [],
            sendUpdateToParticipants: false,
            calendlyEventTypes: [],
            calendlyUsers: [],
            timezones: {},
            editorDataEmailSchedulingRequest: null,
            editorTypeEmailSchedulingRequest: null,
            editorDataEmailConfirmation: null,
            editorTypeEmailConfirmation: null,
            editorDataEmailReminder: null,
            editorTypeEmailReminder: null,
            page_tabs: [
                { title: 'Scheduling Settings', label: this.tab_calendar_setup, icon: <FiSettings /> },
                { title: 'Request Email', label: this.tab_calendar_invite_email, icon: <FiMail /> },
                { title: 'Confirmation Email', label: this.tab_calendar_confirmation_email, icon: <FiMail /> },
                { title: 'Reminder Email', label: this.tab_calendar_reminder_email, icon: <FiMail /> }
            ],
            page_tab_selected: this.tab_calendar_setup,
            /*study_sessions: [
                {
                    date: '2021-05-23',
                    time: '18:00',
                    duration: 60,
                    participants: 4
                },
                {
                    date: '2021-05-24',
                    time: '14:00',
                    duration: 90,
                    participants: 2
                }
            ],*/
            showSessionEditor: false,
            showSessionEditor_index: false,
            showSessionEditor_session: {},
            isAddingReminder: false,
            isSendingUpdate: false,
            isManageStudyMembersOpen: false
        };

        this.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        this.timezoneOffset = -1 * new Date().getTimezoneOffset();
        this.generateTimes = this.generateTimes.bind(this);

        this.checkIfRespondentsScheduledInFuture = this.checkIfRespondentsScheduledInFuture.bind(this);
        this.renderObservers = this.renderObservers.bind(this);
        this.onSessionEditFormChange = this.onSessionEditFormChange.bind(this);
        this.addSession = this.addSession.bind(this);
        this.editSession = this.editSession.bind(this);
        this.removeSession = this.removeSession.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onTimezoneChange = this.onTimezoneChange.bind(this);
        this.onAvailabilityChange = this.onAvailabilityChange.bind(this);
        this.onAvailabilitySessionsChange = this.onAvailabilitySessionsChange.bind(this);
        this.updateTimezoneToLocal = this.updateTimezoneToLocal.bind(this);
        this.onUpdateStudyLocation = this.onUpdateStudyLocation.bind(this);
        this.getEventTypes = this.getEventTypes.bind(this);
        this.onChangeEditorEmailSchedulingRequest = this.onChangeEditorEmailSchedulingRequest.bind(this);
        this.onChangeEditorEmailConfirmation = this.onChangeEditorEmailConfirmation.bind(this);
        this.onChangeEditorEmailReminder = this.onChangeEditorEmailReminder.bind(this);
        this.calculateDateRangeDays = this.calculateDateRangeDays.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onSelectionChange2 = this.onSelectionChange2.bind(this);
        this.onSelectionReset = this.onSelectionReset.bind(this);
        this.createReminder = this.createReminder.bind(this);
        this.onSessionCopy = this.onSessionCopy.bind(this);
        this.renderSessions = this.renderSessions.bind(this);

        const studySchedlingOnboardingTask = `study-scheduling-${study_id}`;
        if (!helpers.isOnboardingTaskComplete(this.props.auth.user, studySchedlingOnboardingTask)) {
            services.completeOnboardingTask(studySchedlingOnboardingTask);
        }

        this.schedulingConfigurationRef = React.createRef();
        this.sessionsRef = React.createRef();
        this.generalAvailabilityRef = React.createRef();
        this.selfSchedulingRef = React.createRef();
        this.calendarEventRef = React.createRef();
        this.remindersRef = React.createRef();
        this.saveDelay = React.createRef();
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    onChangeEditorEmailSchedulingRequest(editorDataEmailSchedulingRequest, editorTypeEmailSchedulingRequest) {
        this.onFormChange({
            target: {
                value: {
                    email_scheduling_body: editorDataEmailSchedulingRequest,
                    email_scheduling_type: editorTypeEmailSchedulingRequest
                },
                name: 'email_scheduling'
            }
        });
        this.setState({ editorDataEmailSchedulingRequest, editorTypeEmailSchedulingRequest });
    }

    onChangeEditorEmailConfirmation(editorDataEmailConfirmation, editorTypeEmailConfirmation) {
        this.onFormChange({
            target: {
                value: {
                    email_confirmation_body: editorDataEmailConfirmation,
                    email_confirmation_type: editorTypeEmailConfirmation
                },
                name: 'email_confirmation'
            }
        });
        this.setState({ editorDataEmailConfirmation, editorTypeEmailConfirmation });
    }

    onChangeEditorEmailReminder(editorDataEmailReminder, editorTypeEmailReminder) {
        this.onFormChange({
            target: {
                value: {
                    email_reminder_body: editorDataEmailReminder,
                    email_reminder_type: editorTypeEmailReminder
                },
                name: 'email_reminder'
            }
        });
        this.setState({ editorDataEmailReminder, editorTypeEmailReminder });
    }

    getEventTypes(user_uri) {
        console.log('getEventTypes', user_uri);
        services
            .getCalendlyEventTypes(user_uri)
            .then(calendlyEventTypes => {
                this.setState({ calendlyEventTypes });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    getCalendlyUsers() {
        console.log('getCalendlyUsers');
        services
            .getCalendlyUsers()
            .then(calendlyUsers => {
                console.log('calendlyUsers', calendlyUsers);
                this.setState({ calendlyUsers });

                if (this.state.study.calendly_user_uri) {
                    this.getEventTypes(this.state.study.calendly_user_uri);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
            });
    }

    componentDidMount() {
        const studyId = this.props.match.params.id;
        services.getStudy(studyId).then(study => {
            let editorDataEmailSchedulingRequest_xhr;
            if (study.email_scheduling_type === EMAIL_TEMPLATE_TYPE.BLOCKS) {
                editorDataEmailSchedulingRequest_xhr = helpers.isObject(study.email_scheduling_body)
                    ? study.email_scheduling_body
                    : helpers.makeBlocksFromText(study.email_scheduling_body);
            } else {
                editorDataEmailSchedulingRequest_xhr = helpers.isObject(study.email_scheduling_body)
                    ? JSON.stringify(study.email_scheduling_body)
                    : study.email_scheduling_body;
            }

            let editorDataEmailConfirmation_xhr;
            if (study.email_confirmation_type === EMAIL_TEMPLATE_TYPE.BLOCKS) {
                editorDataEmailConfirmation_xhr = helpers.isObject(study.email_confirmation_body)
                    ? study.email_confirmation_body
                    : helpers.makeBlocksFromText(study.email_confirmation_body);
            } else {
                editorDataEmailConfirmation_xhr = helpers.isObject(study.email_confirmation_body)
                    ? JSON.stringify(study.email_confirmation_body)
                    : study.email_confirmation_body;
            }

            let editorDataEmailReminder_xhr;
            if (study.email_reminder_type === EMAIL_TEMPLATE_TYPE.BLOCKS) {
                editorDataEmailReminder_xhr = helpers.isObject(study.email_reminder_body)
                    ? study.email_reminder_body
                    : helpers.makeBlocksFromText(study.email_reminder_body);
            } else {
                editorDataEmailReminder_xhr = helpers.isObject(study.email_reminder_body)
                    ? JSON.stringify(study.email_reminder_body)
                    : study.email_reminder_body;
            }

            cache.set(`study.${studyId}`, study);
            this.setState({
                study,
                loading: false,
                editorDataEmailSchedulingRequest: editorDataEmailSchedulingRequest_xhr,
                editorTypeEmailSchedulingRequest: study.email_scheduling_type,
                editorDataEmailConfirmation: editorDataEmailConfirmation_xhr,
                editorTypeEmailConfirmation: study.email_confirmation_type,
                editorDataEmailReminder: editorDataEmailReminder_xhr,
                editorTypeEmailReminder: study.email_reminder_type
            });

            if (study.scheduling_type == 'calendly') {
                //this.getEventTypes();
                this.getCalendlyUsers();
            }

            this.getAvailabilityXHR();
            this.getStudyCollaborators();

            // scroll to ~8am
            setTimeout(() => {
                var objDiv = document.getElementById('availability-scroll');
                if (objDiv) {
                    objDiv.scroll(0, 760);
                }
            }, 1000);
        });

        services.getTimezones().then(timezones => {
            this.setState({ timezones });
        });
        this.onRouteChanged();
    }

    /* eslint-disable react/no-did-update-set-state */
    componentDidUpdate(prevProps, prevState) {
        // ignore updates before the study is loaded
        if (prevState.loading) {
            return;
        }

        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }
    /* eslint-enable react/no-did-update-set-state */

    onRouteChanged() {
        const { location } = this.props;
        const tabLabel = location.hash.replace('#', '');
        if (this.state.page_tabs.filter(tab => tab.label === tabLabel).length > 0)
            this.setState({ page_tab_selected: tabLabel || this.state.page_tabs[0].label });
        else if (this.tab_calendar_setup_labels.filter(label => label === tabLabel).length > 0)
            this.setState({ page_tab_selected: this.tab_calendar_setup });
    }

    futureSessionsExist() {
        let exist = false;
        // @todo fix this.. no longer study.study_people
        if (this.state.study.people_counts.all > 0) {
            /*this.state.study.study_people_count.forEach(sp => {
                if (sp.spot && moment.utc(sp.spot).isAfter()) {
                    exist = true;
                }
            });*/
        }

        return exist;
    }

    updateTimezoneToLocal() {
        this.onFormChange({
            target: {
                name: 'timezone',
                value: this.timezone
            }
        });
    }

    getAvailabilityXHR() {
        const { toastManager } = this.props;
        const show_taken_spots = 1;
        studyService
            .getStudyAvailability(this.props.match.params.id, show_taken_spots)
            .then(study_availability => {
                if (study_availability && study_availability.freebusy) {
                    this.setState({ calendar_freebusy: study_availability.freebusy });
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getStudyCollaborators() {
        const { toastManager } = this.props;
        studyService
            .studyGetUsers(this.props.match.params.id)
            .then(study_users_xhr => {
                this.setState({ study_users: study_users_xhr });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    /**
     * Make am API call to update the study
     *
     * @param {any} study The study object to update
     */
    async updateStudy(study) {
        const { toastManager } = this.props;
        try {
            await services.updateStudy(study);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    /**
     * Update the study with new form data
     *
     * @param {{ body: any; subject: string; type: number; id: number }} template
     * @param {'scheduling' | 'confirmation' | 'reminder'} destination
     */
    onTemplate(template, destination) {
        this.setState(
            ({ study }) => {
                if (destination === 'scheduling') {
                    study.email_scheduling_body = template.body;
                    study.email_scheduling_type = template.type;
                    study.email_scheduling_subject = template.subject;
                } else if (destination === 'confirmation') {
                    study.email_confirmation_body = template.body;
                    study.email_confirmation_type = template.type;
                    study.email_confirmation_subject = template.subject;
                } else if (destination === 'reminder') {
                    study.email_reminder_body = template.body;
                    study.email_reminder_type = template.type;
                    study.email_reminder_subject = template.subject;
                }

                return { study };
            },
            () => {
                this.updateStudy({ ...this.state.study, tz_offset: this.timezoneOffset });
            }
        );
    }

    onFormChange(e) {
        const { toastManager } = this.props;
        if (!e.target.name) {
            return;
        }
        const study = { ...this.state.study };

        //console.log('change!', e.target.name, e.target.value);

        if (['daterange_enabled'].includes(e.target.name)) {
            // console.log(e.target.value, e.target.checked);
            study[e.target.name] = e.target.checked ? 1 : 0;
        } else if (e.target.name == 'daterange_start') {
            if (e.target.value) {
                study[e.target.name] = moment(e.target.value).format('YYYY-MM-DD');
            } else {
                study[e.target.name] = null;
            }
        } else if (e.target.name == 'daterange_end') {
            if (e.target.value) {
                study[e.target.name] = moment(e.target.value).format('YYYY-MM-DD');
            } else {
                study[e.target.name] = null;
            }
        } else if (e.target.name === 'email_scheduling') {
            study['email_scheduling_body'] = e.target.value.email_scheduling_body;
            study['email_scheduling_type'] = e.target.value.email_scheduling_type;
        } else if (e.target.name === 'email_confirmation') {
            study['email_confirmation_body'] = e.target.value.email_confirmation_body;
            study['email_confirmation_type'] = e.target.value.email_confirmation_type;
        } else if (e.target.name === 'email_reminder') {
            study['email_reminder_body'] = e.target.value.email_reminder_body;
            study['email_reminder_type'] = e.target.value.email_reminder_type;
        } else if (
            e.target.name === 'availability_buffer_after' ||
            e.target.name === 'availability_buffer_before' ||
            e.target.name === 'availability_minimum_booking_notice'
        ) {
            study[e.target.name] = +e.target.value;
        } else if (e.target.name === 'availability_generic') {
            // clear nulls from the availability generic object
            study[e.target.name] = _.mapValues(e.target.value, day => _.compact(day));
        } else {
            study[e.target.name] = e.target.value;
        }

        // safety check for duration difference
        if (e.target.name == 'daterange_start' || e.target.name == 'daterange_end') {
            console.log('compare dates?', study['daterange_start'], study['daterange_end']);
            if (study['daterange_start'] && study['daterange_end']) {
                const MAX_DIFF_DAYS = 90;
                const actual_diff = moment(study['daterange_end']).diff(moment(study['daterange_start']), 'days');

                console.log('compare dates! yes', actual_diff);

                if (actual_diff > MAX_DIFF_DAYS) {
                    const date_range_error = `The End Date must be within ${MAX_DIFF_DAYS} days from the Start Date.`;
                    toastManager.add(date_range_error, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    return;
                }
            }
        }

        let changed_key = e.target.name;

        if (changed_key === 'duration') {
            const { availabilityGeneric, availabilityRange } = this.onChangeDuration(study.duration);
            study.availability_generic = availabilityGeneric;
            study.availability_range = availabilityRange;
        }

        const scheduledInFutureKeys = ['duration', 'session_info', 'session_title'];
        const isSessionTitleEmpty = study.session_title === '';

        this.setState({ study });

        // detect change, and clear timeout if exists
        if (this.saveDelay.current) {
            clearTimeout(this.saveDelay.current);
        }

        if (study.scheduling_type == 'calendly' && this.state.study.scheduling_type != study.scheduling_type) {
            //this.getEventTypes();
            this.getCalendlyUsers();
        }

        this.saveDelay.current = setTimeout(() => {
            services
                .updateStudy({
                    ...study,
                    tz_offset: this.timezoneOffset
                })
                .then(() => {
                    if (scheduledInFutureKeys.includes(changed_key) && !isSessionTitleEmpty) {
                        this.checkIfRespondentsScheduledInFuture();
                    }
                })
                .catch(error => {
                    this.setState({ sendUpdateToParticipants: false });
                    const errorText = services.parseAndTrackXhrErrors(error);
                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }, 500);
    }

    onAvailabilityChange(availability) {
        const { toastManager } = this.props;

        services
            .updateStudy({
                ...this.state.study,
                availability,
                tz_offset: this.timezoneOffset
            })
            .then(() => {
                toastManager.add('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onAvailabilitySessionsChange() {
        const { toastManager } = this.props;

        services
            .updateStudy(this.state.study)
            .then(() => {
                toastManager.add('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    checkIfRespondentsScheduledInFuture() {
        const { toastManager } = this.props;

        studyService
            .checkIfRespondentsScheduledInFuture(this.state.study.id)
            .then(data => {
                if (data.in_future) {
                    this.setState({ sendUpdateToParticipants: true });
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    /**
     * @param {any} next The new study object
     */
    onUpdateStudyLocation(next) {
        const { toastManager } = this.props;

        const prev = this.state.study;

        // if location type changed, update the popup mode
        if (prev.location_type === STUDY_LOCATION_TYPE.ZOOM && next.location_type !== STUDY_LOCATION_TYPE.ZOOM) {
            this.setState({ updatePopupMode: 'zoomOff' });
        } else if (prev.location_type !== STUDY_LOCATION_TYPE.ZOOM && next.location_type === STUDY_LOCATION_TYPE.ZOOM) {
            this.setState({ updatePopupMode: 'zoomOn' });
        }

        // if location changed, check to see if there are scheduled respondents (in the future)
        // that would need to be notified
        this.checkIfRespondentsScheduledInFuture();

        this.setState({ study: next }, async () => {
            try {
                await services.updateStudy(this.state.study);
                toastManager.add('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            } catch (error) {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        });
    }

    renderCalendlyUsers() {
        let render = [];
        const options = [];

        this.state.calendlyUsers.forEach(calendlyUser => {
            console.log('calendlyUser', calendlyUser);
            if (calendlyUser.user) {
                if (!this.state.study.calendly_user_uri) {
                    this.onFormChange({
                        target: { name: 'calendly_user_uri', value: calendlyUser.user.uri }
                    });
                }
                options.push(<option value={calendlyUser.user.uri}>{calendlyUser.user.name}</option>);
            }
        });

        if (!options.length) {
            render = (
                <div>
                    You have 0 users available. Update them in{' '}
                    <a href="https://calendly.com/app/organization/users" target="_blank">
                        Calendly
                    </a>{' '}
                    and try again.
                </div>
            );
        } else {
            render = (
                <Select
                    width={200}
                    name="calendly_user_uri"
                    value={this.state.study.calendly_user_uri}
                    onChange={e => {
                        this.onFormChange(e);
                        this.getEventTypes(e.target.value);
                    }}
                >
                    {options}
                </Select>
            );
        }

        return render;
    }

    renderCalendlyEventTypes() {
        let render = [];
        const options = [];

        this.state.calendlyEventTypes.forEach(cet => {
            if (cet.active) {
                if (!this.state.study.calendly_event_type_uri) {
                    this.onFormChange({
                        target: { name: 'calendly_event_type_uri', value: cet.uri }
                    });
                }
                options.push(<option value={cet.uri}>{cet.name}</option>);
            }
        });

        if (!options.length) {
            render = (
                <div>
                    You have 0 active event types available. Update them in{' '}
                    <a href="https://calendly.com/event_types/user/me" target="_blank">
                        Calendly
                    </a>{' '}
                    and try again.
                </div>
            );
        } else {
            render = (
                <Select
                    width={200}
                    name="calendly_event_type_uri"
                    value={this.state.study.calendly_event_type_uri}
                    onChange={this.onFormChange}
                >
                    {options}
                </Select>
            );
        }

        return render;
    }

    renderCalendlyPreviewLink() {
        let ret = '';
        if (this.state.study.calendly_event_type_uri && this.state.calendlyEventTypes.length) {
            const calendlyEventType = this.state.calendlyEventTypes.find(
                et => et.uri == this.state.study.calendly_event_type_uri
            );
            if (calendlyEventType) {
                ret = (
                    <a href={calendlyEventType.scheduling_url} target="_blank">
                        <Button type="button" variant="primary" mr={0}>
                            Preview Scheduling
                        </Button>
                    </a>
                );
            } else {
                ret = 'Not found';
            }
        }
        return ret;
    }

    generateTimes(date, selectedTimes, dateRanges) {
        const times = [];

        for (let hour = 0; hour < 24; hour++) {
            const increments_per_hour = 60 / this.state.study.increments;

            for (var i = 0; i < increments_per_hour; i++) {
                times.push(
                    moment
                        .tz(
                            {
                                y: 2000, // YES THIS IS KINDA HACKY :D
                                M: 1, // the goal is to track the time, more than date
                                d: 1, // on the server, we rip out the time only, and insert a new date based on date_range
                                hour
                            },
                            this.state.study.timezone
                        )
                        .add(this.state.study.increments * i, 'minutes')
                );
            }
        }

        // unavailable times
        let unavailable_times = {};
        const precedingIncrementsTaken = parseInt(this.state.study.duration) / 15 - 1;
        _.keys(selectedTimes).forEach(time => {
            // depending on duration, set how many preceeding times are unavailable
            // 60 minutes, then 1, 2, 3 preceding increments
            // 15 minutes = none
            // 30 minutes = 2
            for (let i = 0; i <= precedingIncrementsTaken; i++) {
                unavailable_times[time - i * 15 * 60] = true;
            }
        });

        const result = [];

        times.forEach(time => {
            const timestamp = time.format('X');

            result.push({
                id: timestamp,
                title: time.format(HUMAN_TIME_FORMAT),
                available: unavailable_times[timestamp] ? false : true
            });
        });

        return result;
    }

    hasSmsEnabled() {
        let enabled = false;
        try {
            enabled = helpers.hasFeatureEnabled(this.props.auth.account, PAID_FEATURE.SMS_MASS);
        } catch (e) {
            helpers.trackError(e);
        }
        return enabled;
    }

    calculateRanges(date, duration, availability_obj) {
        let ranges = [];
        let range_start = null;
        let range_end = null;
        let timestamp_prev = null;
        let mom1,
            mom2 = null;

        let timestamps = _.keys(availability_obj);
        if (timestamps.length) {
            timestamps.sort();
            // console.log('availability_obj', timestamps)
            timestamps.forEach((timestamp, index) => {
                if (!range_start) {
                    range_start = timestamp;
                }

                if (index != 0) {
                    //console.log('compare', mom1.format('lll'), mom2.format('lll'))
                    if (
                        moment.unix(timestamp).format('lll') ==
                        moment
                            .unix(timestamp_prev)
                            .add(duration, 'minutes')
                            .format('lll')
                    ) {
                        // if last element & the current & last match
                        if (timestamps.length == index + 1) {
                            range_end = timestamp;
                        }
                    } else {
                        range_end = timestamp_prev;

                        // if its a single last spot (current & last dont match)
                        if (timestamps.length == index + 1) {
                            ranges.push({
                                start: timestamp,
                                end: parseInt(timestamp) + 60 * parseInt(duration)
                            });
                        }
                    }
                }

                // if its only 1 spot available
                if (timestamps.length == 1) {
                    range_end = timestamp;
                }

                // if end found, or is last element, or is only 1 element
                if (range_end) {
                    ranges.push({
                        start: range_start,
                        end: parseInt(range_end) + 60 * parseInt(duration)
                    });
                    range_start = timestamp;
                    range_end = null;
                }

                timestamp_prev = timestamp;
            });
        }

        return ranges;
    }

    calculateRanges2(date, duration, availability_obj, key) {
        let ranges = [];
        let range_start = null;
        let range_end = null;
        let timestamp_prev = null;

        // check if new key is allowed to be selected
        // key CAN be selected if the duration after the key is available
        // is there any end_timestamp in this day where the start time starts before
        // is there any start_timestamp in this day where end time ends after

        //console.log('check if availability between', key, key_end_time);

        let timestamps = _.keys(availability_obj);
        if (timestamps.length) {
            timestamps.sort();
            // console.log('availability_obj', timestamps)
            timestamps.forEach((timestamp, index) => {
                //console.log('timestamps', timestamp, index)

                // if sends 1st one
                // start range, add duration, and set end range

                // if sends 2nd one
                // dont touch any other ranges (which means if key , create new range

                if (!range_start) {
                    range_start = timestamp;
                }

                if (index != 0) {
                    /*console.log('compare', moment.unix(timestamp).format('lll'), moment
                            .unix(range_start)
                            .add(duration, 'minutes')
                            .format('lll'))*/
                    // if the current timestamp is equal to the range_start timestamp + increment
                    // that means the range keeps going (unless its the last item)
                    if (parseInt(timestamp) < parseInt(range_start) + parseInt(duration) * 60) {
                        // if last element & the current & last match
                        if (timestamps.length == index + 1) {
                            //console.log('in range, last timestamp');
                            range_end = timestamp;
                        } else {
                            //console.log('in range, more timestamps to go');
                        }
                    } else {
                        range_end = timestamp_prev;

                        // if its a single last spot (current & last dont match)
                        // creates new range from current timestamp
                        if (timestamps.length == index + 1) {
                            //console.log('not in range, last timestamp');
                            ranges.push({
                                start: timestamp,
                                end: parseInt(timestamp) + 60 * parseInt(duration)
                            });
                        } else {
                            //console.log('not in range, more to go');
                        }
                    }
                }

                // if its only 1 spot available
                if (timestamps.length == 1) {
                    range_end = timestamp;
                }

                // if end found, or is last element, or is only 1 element
                if (range_end) {
                    //console.log('range ends!', timestamp, range_end)
                    // creates previous range from current timestamp
                    ranges.push({
                        start: range_start,
                        end: parseInt(range_start) + 60 * parseInt(duration)
                    });
                    range_start = timestamp;
                    range_end = null;
                }

                timestamp_prev = timestamp;
            });
        }
        return ranges;
    }

    getReminders() {
        const { toastManager } = this.props;

        studyService
            .getStudyReminders(this.study_id)
            .then(reminders => {
                let { study } = this.state;
                study.reminders = reminders;
                this.setState({ study });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    deleteReminder(reminder_id) {
        const { toastManager } = this.props;

        if (confirm('Are you sure you want to delete this reminder?')) {
            studyService
                .deleteStudyReminder(this.study_id, reminder_id)
                .then(reminders => {
                    let { study } = this.state;
                    study.reminders = reminders;
                    this.setState({ study });

                    toastManager.add('Successfully deleted reminder', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    createReminder() {
        const { toastManager } = this.props;

        this.setState({ isAddingReminder: true });

        studyService
            .createStudyReminder(this.study_id)
            .then(reminders => {
                this.setState({ isAddingReminder: false });

                let { study } = this.state;
                study.reminders = reminders;
                this.setState({ study });

                toastManager.add('Successfully created reminder', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                this.setState({ isAddingReminder: false });
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    updateReminder(reminder_id, name, value) {
        const { toastManager } = this.props;
        let { study } = this.state;

        if (study.reminders && study.reminders.length) {
            let reminder_index = study.reminders.findIndex(r => r.id == reminder_id);
            if (reminder_index >= 0) {
                let reminder_found = study.reminders[reminder_index];
                reminder_found[name] = value;
                study.reminders[reminder_index] = reminder_found;

                this.setState({ study });

                studyService
                    .updateStudyReminder(this.study_id, reminder_found)
                    .then(() => {
                        toastManager.add('Successfully updated reminder', {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    })
                    .catch(error => {
                        const errorText = services.parseAndTrackXhrErrors(error);
                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }
        }
    }

    onSelectionReset() {
        const { study } = this.state;
        if (study.daterange_enabled) {
            this.onFormChange({
                target: {
                    name: 'availability_range',
                    value: {}
                }
            });
        } else {
            this.onFormChange({
                target: {
                    name: 'availability_generic',
                    value: {}
                }
            });
        }
    }

    trimOutOfBoundsTimes(availability) {
        const midnight_moment = moment.tz(
            {
                y: 2000,
                M: 1,
                d: 1,
                hour: 0
            },
            this.state.study.timezone
        );
        const midnight_timestamp = parseInt(midnight_moment.format('X'));

        const midnight_moment_end = moment.tz(
            {
                y: 2000,
                M: 1,
                d: 2,
                hour: 0
            },
            this.state.study.timezone
        );
        const midnight_timestamp_end = parseInt(midnight_moment_end.format('X'));

        Object.keys(availability).forEach(date => {
            let ranges = availability[date];

            ranges.forEach((range, range_index) => {
                if (range) {
                    // if the range ends before midnight, unset this
                    let end = parseInt(range.end);
                    let start = parseInt(range.start);

                    //console.log('end', end, midnight_timestamp)
                    //console.log('start', start, midnight_timestamp)

                    // check for overlap with previous day
                    if (end < midnight_timestamp) {
                        //console.log('end too small');
                        delete availability[date][range_index];
                    } else if (start < midnight_timestamp) {
                        //console.log('start too small');
                        //console.log('too early')
                        availability[date][range_index]['start'] = midnight_timestamp;
                    }
                    // check for overlap with next day
                    else if (start > midnight_timestamp_end) {
                        delete availability[date][range_index];
                    } else if (end > midnight_timestamp_end) {
                        availability[date][range_index]['end'] = midnight_timestamp_end;
                    }
                }
            });
        });

        return availability;
    }

    onTimezoneChange(e) {
        if (this.state.study.availability_type == STUDY_AVAILABILITY_TYPES.SESSIONS) {
            // since timezone changes for "sessions" dont change anything else.. just save that
            this.onFormChange(e);
        } else {
            const { study } = this.state;
            study.timezone = e.target.value;

            // also save the timezone within the callback to server, via onFormChange
            this.setState({ study }, () => {
                if (study.daterange_enabled) {
                    // clean up
                    let availability_range = study.availability_range;
                    availability_range = this.trimOutOfBoundsTimes(availability_range);

                    this.onFormChange({
                        target: {
                            name: 'availability_range',
                            value: availability_range
                        }
                    });
                } else {
                    let availability_generic = study.availability_generic;
                    availability_generic = this.trimOutOfBoundsTimes(availability_generic);

                    this.onFormChange({
                        target: {
                            name: 'availability_generic',
                            value: availability_generic
                        }
                    });
                }
            });
        }
    }

    onSelectionChange(date, availability_obj) {
        console.log('onSelectionChange', date, availability_obj);
        // update existing avail
        const { study } = this.state;
        if (study.daterange_enabled) {
            let availability_range = study.availability_range;
            availability_range[date] = this.calculateRanges(date, this.state.study.duration, availability_obj);
            this.onFormChange({
                target: {
                    name: 'availability_range',
                    value: availability_range
                }
            });
        } else {
            let availability_generic = study.availability_generic;
            availability_generic[date] = this.calculateRanges(date, this.state.study.duration, availability_obj);
            this.onFormChange({
                target: {
                    name: 'availability_generic',
                    value: availability_generic
                }
            });
        }
    }

    /**
     *
     * @param {string} date - date in YYYY-MM-DD format or just weekday
     * @param {{ start: numberl end: number }} range
     */
    onSelectionChange2(date, range) {
        const { study } = this.state;

        if (study.daterange_enabled) {
            const availabilityDateRange = study.availability_range[date];

            const newAvailabilityRange = {
                ...study.availability_range,
                [date]: availabilityDateRange ? [...availabilityDateRange, range] : [range]
            };

            this.onFormChange({
                target: {
                    name: 'availability_range',
                    value: newAvailabilityRange
                }
            });
        } else {
            const availabilityDateGeneric = study.availability_generic[date];

            const newAvailabilityGeneric = {
                ...study.availability_generic,
                [date]: availabilityDateGeneric ? [...availabilityDateGeneric, range] : [range]
            };

            this.onFormChange({
                target: {
                    name: 'availability_generic',
                    value: newAvailabilityGeneric
                }
            });
        }
    }

    /**
     *
     * @param {string} date
     * @param {{ start: number | string; end: number | string }} range
     */
    onRemoveRange(date, range) {
        const { study } = this.state;
        if (study.daterange_enabled) {
            const rangeIndex = study.availability_range[date].findIndex(
                r => +r.start === +range.start && +r.end === +range.end
            );
            const dateAvailability = [...study.availability_range[date]];
            dateAvailability.splice(rangeIndex, 1); // clone array to avoid mutation

            const newAvailabilityRange = {
                ...study.availability_range,
                [date]: dateAvailability
            };

            this.onFormChange({
                target: {
                    name: 'availability_range',
                    value: newAvailabilityRange
                }
            });
        } else {
            const rangeIndex = study.availability_generic[date].findIndex(
                r => +r.start === +range.start && +r.end === +range.end
            );
            const dateAvailability = [...study.availability_generic[date]];
            dateAvailability.splice(rangeIndex, 1); // clone array to avoid mutation

            const newAvailabilityGeneric = {
                ...study.availability_generic,
                [date]: dateAvailability
            };

            this.onFormChange({
                target: {
                    name: 'availability_generic',
                    value: newAvailabilityGeneric
                }
            });
        }
    }

    /**
     * Refreshes the end time of the ranges.
     *
     * @param {Record<string, { start: number|string; end: number|string }>} dateRanges range object
     * @param {number} duration event duration in minutes
     */
    refreshEvents(dateRanges, duration) {
        Object.keys(dateRanges).forEach(date => {
            dateRanges[date].forEach(range => {
                range.end = parseInt(range.start) + parseInt(duration) * 60;
            });
        });
    }

    onChangeDuration(duration) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const availabilityRange = cloneDeep(this.state.study.availability_range);
        const availabilityGeneric = cloneDeep(this.state.study.availability_generic);

        this.refreshEvents(availabilityRange, duration);
        this.refreshEvents(availabilityGeneric, duration);

        return {
            availabilityRange,
            availabilityGeneric
        };
    }

    calculateDateRangeDays() {
        let days_range = [];
        if (this.state.study) {
            if (this.state.study.daterange_enabled) {
                if (this.state.study.daterange_start && this.state.study.daterange_end) {
                    var start = moment(this.state.study.daterange_start);
                    var end = moment(this.state.study.daterange_end);
                    let cursor = start;
                    while (cursor <= end) {
                        days_range.push(cursor.format('YYYY-MM-DD'));
                        //cursor.format('ddd, MMM Do')
                        cursor.add(1, 'days');
                    }
                }
            } else {
                days_range = moment.weekdays();
                // console.log('days_range', days_range)
            }
        }
        return days_range;
    }

    mapAvailabilityRangeToTimes(availability_range, increments) {
        let result = {};
        if (availability_range && Object.keys(availability_range).length) {
            Object.keys(availability_range).forEach(date => {
                result[date] = {};
                //console.log('___', date, availability_range[date])
                if (availability_range[date]) {
                    availability_range[date].forEach(range => {
                        if (range) {
                            for (
                                let i = parseInt(range.start);
                                i < parseInt(range.end);
                                i += parseInt(increments) * 60
                            ) {
                                //console.log(i);
                                result[date][i] = true;
                            }
                        }
                    });
                }
            });
        }
        return result;
    }

    onSessionEditFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const { showSessionEditor_session } = this.state;

        //console.log(e.target.name, e.target.value);

        if (e.target.name == 'date') {
            if (e.target.value) {
                showSessionEditor_session[e.target.name] = moment(e.target.value).format('YYYY-MM-DD');
            } else {
                showSessionEditor_session[e.target.name] = null;
            }
        } else {
            showSessionEditor_session[e.target.name] = e.target.value;
        }

        this.setState({ showSessionEditor_session });
    }

    removeSession(index) {
        if (confirm('Are you sure?')) {
            let { study } = this.state;
            study.availability_sessions.splice(index, 1);
            this.setState({ study }, () => {
                this.onAvailabilitySessionsChange();
            });
        }
    }

    prepareForSort(availability_sessions) {
        if (availability_sessions) {
            return availability_sessions.map((item, index) => {
                return {
                    ...item,
                    id: index,
                    when: new Date(item.date + ' ' + item.time)
                };
            });
        }
    }

    addSession() {
        let { study } = this.state;
        study.availability_sessions.push(this.state.showSessionEditor_session);
        this.setState({ study, showSessionEditor: false }, () => {
            this.onAvailabilitySessionsChange();
        });
    }

    editSession() {
        let { study } = this.state;

        study.availability_sessions[this.state.showSessionEditor_index] = this.state.showSessionEditor_session;
        this.setState({ study, showSessionEditor: false }, () => {
            this.onAvailabilitySessionsChange();
        });
    }

    onSessionCopy(prevSession) {
        const newSession = cloneDeep(prevSession);
        newSession.title = prevSession.title ? 'copy of ' + prevSession.title : '';
        let { study } = this.state;
        study.availability_sessions.push(newSession);
        this.setState({ study, showSessionEditor: false }, () => {
            this.onAvailabilitySessionsChange();
        });
    }

    renderSessions() {
        const sortable_availability_sessions = this.prepareForSort(this.state.study.availability_sessions);

        if (sortable_availability_sessions && sortable_availability_sessions.length) {
            return sortable_availability_sessions
                .sort((a, b) => a.when - b.when)
                .map(s => {
                    return (
                        <Box className="row-hover row-basic-grid" key={s.id}>
                            {s.title ? (
                                <Box className="text-primary medium" style={{ margin: '2px 0 -6px 0' }}>
                                    {s.title}
                                </Box>
                            ) : (
                                ''
                            )}
                            <Flex style={{ width: '100%' }} fontSize={1} className="text-primary">
                                <Box width={4 / 8} sx={{ paddingTop: '6px' }}>
                                    {moment(`${s.date} ${s.time}`).format('MMM Do, YYYY')} @{' '}
                                    {moment(`${s.date} ${s.time}`).format('h:mma')} ({s.duration} min)
                                </Box>
                                <Box width={2 / 8} sx={{ paddingTop: '6px' }}>
                                    {s.participants}
                                </Box>
                                <Box width={2 / 8}>
                                    <Flex>
                                        <Button
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            tabIndex="-1"
                                            mr={2}
                                            style={{ marginLeft: 'auto' }}
                                            onClick={() => {
                                                this.setState({
                                                    showSessionEditor: 'edit',
                                                    showSessionEditor_index: s.id,
                                                    showSessionEditor_session: s
                                                });
                                            }}
                                        >
                                            <FiEdit />
                                        </Button>
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            tabIndex="-1"
                                            mr={2}
                                            onClick={() => {
                                                this.onSessionCopy(s);
                                            }}
                                        >
                                            <FiCopy />
                                        </Button>
                                        <Button
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            onClick={() => {
                                                this.removeSession(s.id);
                                            }}
                                            mr={0}
                                        >
                                            <FiTrash2 />
                                        </Button>
                                    </Flex>
                                </Box>
                            </Flex>
                        </Box>
                    );
                });
        } else {
            return null;
        }
    }

    renderObservers() {
        let observersAsArray = this.state.study.observers ? this.state.study.observers.split(',') : [];
        // clean them
        let observersAsArrayClean = observersAsArray.filter(email => {
            return email && email.trim() ? true : false;
        });

        if (observersAsArrayClean && observersAsArrayClean.length) {
            return (
                <Flex
                    style={{
                        flexWrap: 'wrap',
                        gap: '6px'
                    }}
                >
                    {observersAsArrayClean.map(email => {
                        email = email.trim();
                        return (
                            <Box
                                sx={{
                                    padding: '4px 16px 4px 16px',
                                    backgroundColor: '#FAFAFA',
                                    borderRadius: '4px'
                                }}
                                className={classNames(
                                    'fs-accent-14 color-text-primary',
                                    !helpers.isValidEmail(email) && 'border border-danger'
                                )}
                                key={email}
                            >
                                {email}
                            </Box>
                        );
                    })}
                </Flex>
            );
        } else {
            return <Box mb={2}>No observers yet.</Box>;
        }
    }

    render() {
        const before_session = ' before the session';
        let reminder_options = [
            { value: 0, label: 'Off' },
            { value: 0.25, label: '15 minutes' + before_session },
            { value: 0.5, label: '30 minutes' + before_session },
            { value: 1, label: '1 hour' + before_session },
            { value: 2, label: '2 hours' + before_session },
            { value: 3, label: '3 hours' + before_session },
            { value: 4, label: '4 hours' + before_session },
            { value: 5, label: '5 hours' + before_session },
            { value: 6, label: '6 hours' + before_session },
            { value: 12, label: '12 hours' + before_session },
            { value: 24, label: '1 day' + before_session },
            { value: 48, label: '2 days' + before_session },
            { value: 72, label: '3 days' + before_session }
        ];

        // duration options
        // Calculate the number of time slots. Each slot represents 5 minutes in an 8-hour period (480 minutes).
        // Subtract 10 from 480 to account for some offset or break, then divide by 5 (minutes per slot).
        const numberOfTimeSlots = (480 - 10) / 5; // 8hr

        // Create an array with a length equal to the number of time slots.
        // The Array.from() method creates a new array instance from a length object ({ length: numberOfTimeSlots }).
        // The second argument is a map function that generates the value for each slot.
        // For each slot, it starts from 15 and increments by 5 (i * 5) for each subsequent slot.
        // For example, if numberOfTimeSlots is 94, timeSlotOptions will be an array like [15, 20, 25, ..., 480].
        const timeSlotOptions = Array.from({ length: numberOfTimeSlots }, (_, i) => 15 + i * 5); // i starts from 0

        // min notice options
        const minNoticeOptions = helpers.getMinNoticeOptions();

        // generate demo data
        const date_range_days = this.calculateDateRangeDays();

        let selectedChildren = {};
        if (this.state.study.daterange_enabled) {
            selectedChildren = this.mapAvailabilityRangeToTimes(
                this.state.study.availability_range,
                this.state.study.increments
            );
            // console.log('SHOULD RERENDER....', selectedChildren)
        } else {
            selectedChildren = this.mapAvailabilityRangeToTimes(
                this.state.study.availability_generic,
                this.state.study.increments
            );
        }

        const listTimezones = [];

        Object.keys(this.state.timezones).forEach(tzKey => {
            listTimezones.push(<option value={tzKey}>{this.state.timezones[tzKey]}</option>);
        });

        let maxParticipantsTotalOptions = [];
        for (let i = 1; i <= 500; i++) {
            maxParticipantsTotalOptions.push(i);
        }

        let maxParticipantsPerDay = [];
        for (let i = 1; i <= 500; i++) {
            maxParticipantsPerDay.push(i);
        }

        let participantsPerSession = [];
        for (let i = 2; i <= 200; i++) {
            participantsPerSession.push(i);
        }

        //console.log(moment(this.state.study.daterange_start).format('YYYY-MM-DD h:mm'));

        const remindersSorted = [].concat(this.state.study.reminders);
        //.sort((a, b) => a.hours > b.hours ? 1 : -1)

        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{`${this.state.study.title} Scheduling`}</title>
                    </Helmet>

                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                            <BreadcrumbTitle isLoading={this.state.loading} title={this.state.study.title} />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <Flex>
                        <StudyPageSubNav study_id={this.props.match.params.id} study={this.state.study} />
                    </Flex>

                    <Flex sx={{ flexDirection: 'row' }} mt={4} px={4} className="tertiary-nav">
                        {this.state.page_tabs.map(pt => {
                            return (
                                <HeaderSubnavButton
                                    className={this.state.page_tab_selected == pt.label && 'nav-item-active'}
                                    onClick={() => this.props.history.push(`#${pt.label}`)}
                                >
                                    {pt.icon}
                                    {pt.title}
                                </HeaderSubnavButton>
                            );
                        })}
                    </Flex>

                    <AppPageWrapperSectionBody>
                        <StudyUpdatedPopup
                            studyId={this.state.study.id}
                            isOpen={this.state.sendUpdateToParticipants}
                            onClose={() => this.setState({ sendUpdateToParticipants: false, updatePopupMode: null })}
                            mode={this.state.updatePopupMode}
                        />

                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <Box mt={3} mx={4} flex="1 1 0%">
                                {this.state.page_tab_selected == this.tab_calendar_setup && (
                                    <Flex flex="1 1 0%" justifyContent="space-between">
                                        <Box flex="0 0 auto">
                                            <Box
                                                id="calendar-setup-scheduling-configuration"
                                                ref={this.schedulingConfigurationRef}
                                                className="form-wrapper2 form-wrapper2-border"
                                                sx={{ borderTop: 0 }}
                                            >
                                                <Flex className="settings-header">
                                                    <Box>
                                                        <H2>Scheduling Configuration</H2>
                                                        <Box className="form-section-information">
                                                            Set up the way your study's session availability is
                                                            configured.
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                                <Box sx={{ flexGrow: 1 }}>
                                                    <Box>
                                                        {this.state.study.scheduling_type == 'panelfox' && (
                                                            <Button
                                                                variant="primary"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    window.open(
                                                                        helpers.getAvailabilityLink(this.state.study)
                                                                    );
                                                                }}
                                                                target="_blank"
                                                                mr={0}
                                                            >
                                                                Preview Scheduling
                                                            </Button>
                                                        )}

                                                        {this.state.study.scheduling_type == 'calendly' &&
                                                            this.renderCalendlyPreviewLink()}
                                                    </Box>
                                                    <Box>
                                                        <Box className="form-label form-row-margin">
                                                            Scheduling Tool{' '}
                                                            <a
                                                                href="#"
                                                                data-beacon-article="62fadefa61ff5d5f24f9c1ad"
                                                                className="help-question"
                                                            >
                                                                ?
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Select
                                                            //style={{ maxWidth: '140px' }}
                                                            name="scheduling_type"
                                                            value={this.state.study.scheduling_type}
                                                            onChange={this.onFormChange}
                                                        >
                                                            <option value="panelfox">Panelfox</option>
                                                            <option
                                                                value="calendly"
                                                                disabled={
                                                                    !this.props.auth.account.calendly_access_token
                                                                }
                                                            >
                                                                Calendly{' '}
                                                                {!this.props.auth.account.calendly_access_token
                                                                    ? '(Integration not configured)'
                                                                    : ''}
                                                            </option>
                                                        </Select>
                                                    </Box>
                                                    <Box width={1 / 3} className="form-label form-row-margin">
                                                        Study Timezone
                                                        {/*When viewing dates & times, this will be the default timezone for this study.*/}
                                                    </Box>
                                                    <Box width={2 / 3} className="form-data">
                                                        <Select
                                                            name="timezone"
                                                            value={this.state.study.timezone}
                                                            onChange={this.onTimezoneChange}
                                                            //style={{ maxWidth: '320px' }}
                                                        >
                                                            {listTimezones}
                                                        </Select>
                                                    </Box>
                                                    {this.state.study.scheduling_type == 'calendly' && (
                                                        <>
                                                            <Box width={1 / 3} className="form-label form-row-margin">
                                                                Calendly User
                                                            </Box>
                                                            <Box width={2 / 3} className="form-data">
                                                                {this.renderCalendlyUsers()}
                                                            </Box>

                                                            <Box width={1 / 3} className="form-label form-row-margin">
                                                                Calendly Event Type
                                                            </Box>
                                                            <Box width={2 / 3} className="form-data">
                                                                {this.renderCalendlyEventTypes()}
                                                            </Box>
                                                        </>
                                                    )}

                                                    {this.state.study.scheduling_type == 'panelfox' && (
                                                        <>
                                                            <Box width={1 / 3} className="form-label form-row-margin">
                                                                Availability Type
                                                            </Box>
                                                            <Box width={2 / 3} className="form-data">
                                                                <Select
                                                                    //style={{ maxWidth: '140px' }}
                                                                    name="availability_type"
                                                                    value={this.state.study.availability_type}
                                                                    onChange={this.onFormChange}
                                                                >
                                                                    <option value="ranges">General Availability</option>
                                                                    <option value="sessions">Sessions</option>
                                                                </Select>
                                                            </Box>
                                                        </>
                                                    )}

                                                    <Box className="form-label form-row-margin">Study Members</Box>
                                                    <Box className="form-data">
                                                        <Box
                                                            as="p"
                                                            className="fs-accent-14 color-text-secondary"
                                                            style={{ marginBottom: '8px' }}
                                                        >
                                                            Collaborators
                                                        </Box>
                                                        <Box style={{ marginBottom: '12px' }}>
                                                            {this.state.study_users.length > 0 ? (
                                                                <Flex
                                                                    style={{
                                                                        flexWrap: 'wrap',
                                                                        gap: '6px'
                                                                    }}
                                                                >
                                                                    {this.state.study_users.map(su => (
                                                                        <Box
                                                                            key={su.user.id}
                                                                            sx={{
                                                                                padding: '4px 16px 4px 16px',
                                                                                backgroundColor: '#FAFAFA',
                                                                                borderRadius: '4px'
                                                                            }}
                                                                            className="fs-accent-14 color-text-primary"
                                                                        >
                                                                            {su.user.name}{' '}
                                                                            {su.is_organizer && (
                                                                                <span className="text-secondary fs-accent-12">
                                                                                    (Organizer)
                                                                                </span>
                                                                            )}
                                                                        </Box>
                                                                    ))}
                                                                </Flex>
                                                            ) : (
                                                                <Flex>No collaborators yet.</Flex>
                                                            )}
                                                        </Box>
                                                        <Box
                                                            as="p"
                                                            className="fs-accent-14 color-text-secondary"
                                                            style={{ marginBottom: '8px' }}
                                                        >
                                                            Observers
                                                        </Box>
                                                        <Box style={{ marginBottom: '16px' }}>
                                                            {this.renderObservers()}
                                                        </Box>
                                                        <Button
                                                            type="button"
                                                            variant="secondary-gray"
                                                            onClick={() =>
                                                                this.setState({
                                                                    isManageStudyMembersOpen: true
                                                                })
                                                            }
                                                        >
                                                            Manage Study Members
                                                            <FiArrowRight style={{ margin: '3px 0 0 5px' }} />
                                                        </Button>
                                                    </Box>

                                                    {/*<Box className="form-data">
                                                        <Box mt={3} p={3} sx={{borderRadius: '8px', background: 'rgba(243, 243, 243, 0.24)'}}>
                                                            <Box className='fs-12' mb={2}>
                                                                <Box className='text-primary medium' mb={1} sx={{textTransform: 'uppercase'}}><FiAward style={{fontSize: '16px', margin: '1px 4px 0 0', verticalAlign:'top'}} /> Research Opportunity - Scheduling</Box>
                                                                <Box className='text-secondary'>
                                                                    We want to improve the scheduling tool for you. If you are starting a new study or updating an existing one,
                                                                    could you please click <span className='medium'>Record Screen</span> and talk us through your process and pain points?
                                                                </Box>
                                                            </Box>

                                                            <a href="https://panelfox.hellozest.io/recorder" target="_blank">
                                                                <Button type="button" variant="secondary" className="secondary-tiny"><FiVideo/> Record Screen</Button>
                                                            </a>

                                                        </Box>
                                                    </Box>*/}
                                                </Box>
                                            </Box>
                                            <>
                                                {this.state.study.scheduling_type == 'panelfox' &&
                                                    this.state.study.availability_type ==
                                                        STUDY_AVAILABILITY_TYPES.SESSIONS && (
                                                        <Box
                                                            id="calendar-setup-sessions"
                                                            ref={this.sessionsRef}
                                                            className="form-wrapper2 form-wrapper2-border"
                                                        >
                                                            <Flex className="settings-header">
                                                                <Box>
                                                                    <H2>Sessions</H2>
                                                                    <Box className="form-section-information">
                                                                        <Box>Create focus group or 1:1 sessions.</Box>
                                                                    </Box>
                                                                </Box>
                                                            </Flex>
                                                            <Box className="form-data">
                                                                <MinimumSchedulingNotice
                                                                    value={
                                                                        this.state.study
                                                                            .availability_minimum_booking_notice
                                                                    }
                                                                    onChange={this.onFormChange}
                                                                    options={minNoticeOptions}
                                                                    style={{ marginBottom: '22px' }}
                                                                />
                                                                <Flex
                                                                    alignItems="top"
                                                                    style={{
                                                                        gap: '24px',
                                                                        marginBottom: '22px'
                                                                    }}
                                                                >
                                                                    <MaxScheduledParticipants
                                                                        value={this.state.study.participants}
                                                                        onChange={this.onFormChange}
                                                                        options={maxParticipantsTotalOptions}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                    <MaxParticipantsPerDay
                                                                        value={this.state.study.max_daily_sessions}
                                                                        onChange={this.onFormChange}
                                                                        options={maxParticipantsPerDay}
                                                                        style={{ width: '100%' }}
                                                                    />
                                                                </Flex>
                                                                {this.state.study.availability_sessions &&
                                                                this.state.study.availability_sessions.length > 0 ? (
                                                                    <>
                                                                        {/*<Flex
                                                                            style={{ width: '100%', borderBottom: '1px solid #E7E8F1', padding: '0 0 4px'}}
                                                                            fontSize={1}
                                                                            mb={0}
                                                                            className="text-secondary"
                                                                        >
                                                                            <Box width={4/8}>
                                                                                Session
                                                                            </Box>
                                                                            <Box width={2/8}>
                                                                                Participants
                                                                            </Box>
                                                                            <Box width={2/8}>
                                                                                
                                                                            </Box>
                                                                            
                                                                        </Flex>*/}
                                                                        {this.renderSessions()}
                                                                    </>
                                                                ) : (
                                                                    <Box className="text-primary" mt={3}>
                                                                        0 sessions created
                                                                    </Box>
                                                                )}

                                                                <Box mt={3}>
                                                                    <Button
                                                                        variant="secondary"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                showSessionEditor: 'new',
                                                                                showSessionEditor_session: {}
                                                                            });
                                                                        }}
                                                                        mr={0}
                                                                    >
                                                                        <FiPlus /> Add Session
                                                                    </Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )}

                                                {this.state.study.scheduling_type == 'panelfox' &&
                                                    this.state.study.availability_type ==
                                                        STUDY_AVAILABILITY_TYPES.RANGES && (
                                                        <Box
                                                            id="calendar-setup-general-availability"
                                                            ref={this.generalAvailabilityRef}
                                                            className="form-wrapper2 form-wrapper2-border"
                                                        >
                                                            <Flex className="settings-header">
                                                                <Box>
                                                                    <H2>General Availability</H2>
                                                                    <Box className="form-section-information">
                                                                        <Box>
                                                                            Create a general set of available sessions.
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Flex>
                                                            <Box width="100%">
                                                                <Box className="form-data">
                                                                    <Flex sx={{ gridGap: '24px' }}>
                                                                        <Box width={1 / 2}>
                                                                            <Box className="form-label">
                                                                                Session Duration
                                                                            </Box>
                                                                            <Box>
                                                                                <Select
                                                                                    name="duration"
                                                                                    value={this.state.study.duration}
                                                                                    onChange={this.onFormChange}
                                                                                    sx={{ width: '100% !important' }}
                                                                                >
                                                                                    {timeSlotOptions.map(d => (
                                                                                        <option value={d}>
                                                                                            {helpers.minutesToHoursPretty(
                                                                                                d
                                                                                            )}
                                                                                        </option>
                                                                                    ))}
                                                                                </Select>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box width={1 / 2}>
                                                                            <MaxParticipantsPerDay
                                                                                value={
                                                                                    this.state.study.max_daily_sessions
                                                                                }
                                                                                onChange={this.onFormChange}
                                                                                options={maxParticipantsPerDay}
                                                                            />
                                                                            {/*<Box className="form-label">
                                                                                Time Increments
                                                                            </Box>
                                                                            <Box>
                                                                                <Select
                                                                                    name="increments"
                                                                                    value={this.state.study.increments}
                                                                                    onChange={this.onFormChange}
                                                                                    sx={{width: '100% !important'}}
                                                                                >
                                                                                    <option value={15}>15min</option>
                                                                                    <option value={30}>30min</option>
                                                                                    <option value={45}>45min</option>
                                                                                    <option value={60}>60min</option>
                                                                                    <option value={90}>90min</option>
                                                                                    <option value={120}>120min</option>
                                                                                </Select>

                                                                            </Box>*/}
                                                                        </Box>
                                                                    </Flex>

                                                                    <Flex
                                                                        sx={{ gridGap: '24px' }}
                                                                        className="form-row-margin"
                                                                    >
                                                                        <Box width={1 / 2}>
                                                                            <MaxScheduledParticipants
                                                                                value={this.state.study.participants}
                                                                                onChange={this.onFormChange}
                                                                                options={maxParticipantsTotalOptions}
                                                                            />
                                                                        </Box>
                                                                        <Box width={1 / 2}>
                                                                            <Box className="form-label">
                                                                                Multiple Participants Per Session
                                                                            </Box>
                                                                            <Box>
                                                                                <Select
                                                                                    sx={{ width: '100% !important' }}
                                                                                    name="availability_allow_multiple"
                                                                                    value={
                                                                                        this.state.study
                                                                                            .availability_allow_multiple
                                                                                    }
                                                                                    onChange={this.onFormChange}
                                                                                >
                                                                                    <option value={0}>
                                                                                        Not allowed
                                                                                    </option>
                                                                                    <option value={-1}>
                                                                                        Unlimited
                                                                                    </option>
                                                                                    {participantsPerSession.map(num => (
                                                                                        <option value={num}>
                                                                                            {num}
                                                                                        </option>
                                                                                    ))}
                                                                                </Select>
                                                                            </Box>
                                                                        </Box>
                                                                    </Flex>
                                                                    <MinimumSchedulingNotice
                                                                        value={
                                                                            this.state.study
                                                                                .availability_minimum_booking_notice
                                                                        }
                                                                        onChange={this.onFormChange}
                                                                        options={minNoticeOptions}
                                                                        className="form-row-margin"
                                                                    />
                                                                    {/* Section below is temporarily hidden */}
                                                                    {/* <Flex
                                                                        sx={{ gridGap: '24px' }}
                                                                        className="form-row-margin"
                                                                    >
                                                                        <Box width={1 / 2}>
                                                                            <Box className="form-label">
                                                                                Buffer before time
                                                                            </Box>
                                                                            <Box>
                                                                                <Select
                                                                                    name="availability_buffer_before"
                                                                                    value={
                                                                                        this.state.study
                                                                                            .availability_buffer_before
                                                                                    }
                                                                                    onChange={this.onFormChange}
                                                                                    sx={{ width: '100% !important' }}
                                                                                >
                                                                                    <option value={0}>Off</option>
                                                                                    {timeSlotOptions.map(
                                                                                        timeSlotOption => (
                                                                                            <option
                                                                                                key={timeSlotOption}
                                                                                                value={timeSlotOption}
                                                                                            >
                                                                                                {helpers.minutesToHoursPretty(
                                                                                                    timeSlotOption
                                                                                                )}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </Select>
                                                                            </Box>
                                                                        </Box>
                                                                        <Box width={1 / 2}>
                                                                            <Box className="form-label">
                                                                                Buffer after time
                                                                            </Box>
                                                                            <Box>
                                                                                <Select
                                                                                    name="availability_buffer_after"
                                                                                    value={
                                                                                        this.state.study
                                                                                            .availability_buffer_after
                                                                                    }
                                                                                    onChange={this.onFormChange}
                                                                                    sx={{ width: '100% !important' }}
                                                                                >
                                                                                    <option value={0}>Off</option>
                                                                                    {timeSlotOptions.map(
                                                                                        timeSlotOption => (
                                                                                            <option
                                                                                                key={timeSlotOption}
                                                                                                value={timeSlotOption}
                                                                                            >
                                                                                                {helpers.minutesToHoursPretty(
                                                                                                    timeSlotOption
                                                                                                )}
                                                                                            </option>
                                                                                        )
                                                                                    )}
                                                                                </Select>
                                                                            </Box>
                                                                        </Box>
                                                                    </Flex> */}

                                                                    <Flex
                                                                        sx={{ gridGap: '24px' }}
                                                                        className="form-row-margin"
                                                                    >
                                                                        <Box width={1 / 2}>
                                                                            <Box className="form-label">Date Range</Box>
                                                                            <Box>
                                                                                <Label
                                                                                    style={{
                                                                                        cursor: 'pointer',
                                                                                        width: '230px'
                                                                                    }}
                                                                                >
                                                                                    <Checkbox
                                                                                        name="daterange_enabled"
                                                                                        checked={
                                                                                            this.state.study
                                                                                                .daterange_enabled == 1
                                                                                        }
                                                                                        onChange={this.onFormChange}
                                                                                    />
                                                                                    <span
                                                                                        className="text-primary"
                                                                                        style={{ padding: '4px 0 0 0' }}
                                                                                    >
                                                                                        Enable custom date range
                                                                                    </span>
                                                                                </Label>
                                                                            </Box>
                                                                        </Box>
                                                                    </Flex>

                                                                    {this.state.study.daterange_enabled == 1 && (
                                                                        <Flex
                                                                            sx={{ gridGap: '24px' }}
                                                                            className="form-row-margin"
                                                                        >
                                                                            <Box width={1 / 2}>
                                                                                <Box className="form-label">
                                                                                    Start Date
                                                                                </Box>
                                                                                <Box className="react-datepicker-w100p">
                                                                                    <DatePicker
                                                                                        selected={
                                                                                            this.state.study
                                                                                                .daterange_start
                                                                                                ? moment(
                                                                                                      this.state.study
                                                                                                          .daterange_start
                                                                                                  ).toDate()
                                                                                                : ''
                                                                                        }
                                                                                        onChange={e =>
                                                                                            this.onFormChange({
                                                                                                target: {
                                                                                                    name:
                                                                                                        'daterange_start',
                                                                                                    value: e
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        //placeholderText="Start Date"
                                                                                        className={`theme-input ${!this
                                                                                            .state.study
                                                                                            .daterange_start &&
                                                                                            'error'}`}
                                                                                        style={{ width: '100%' }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                            <Box width={1 / 2}>
                                                                                <Box className="form-label">
                                                                                    End Date
                                                                                </Box>
                                                                                <Box className="react-datepicker-w100p">
                                                                                    <DatePicker
                                                                                        selected={
                                                                                            this.state.study
                                                                                                .daterange_end
                                                                                                ? moment(
                                                                                                      this.state.study
                                                                                                          .daterange_end
                                                                                                  ).toDate()
                                                                                                : ''
                                                                                        }
                                                                                        onChange={e =>
                                                                                            this.onFormChange({
                                                                                                target: {
                                                                                                    name:
                                                                                                        'daterange_end',
                                                                                                    value: e
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                        placeholderText="End Date"
                                                                                        className={`theme-input ${!this
                                                                                            .state.study
                                                                                            .daterange_end && 'error'}`}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        </Flex>
                                                                    )}
                                                                </Box>
                                                                <Box>
                                                                    {this.state.study.daterange_enabled == true &&
                                                                        (!this.state.study.daterange_start ||
                                                                            !this.state.study.daterange_end) && (
                                                                            <Box mt={3} className="error">
                                                                                Please select a start and end date.
                                                                            </Box>
                                                                        )}
                                                                    {/*JSON.stringify(this.state.study.availability_range)*/}

                                                                    <Flex
                                                                        className="form-label form-row-margin"
                                                                        sx={{ justifyContent: 'space-between' }}
                                                                    >
                                                                        <Box>
                                                                            <Box>Availability</Box>
                                                                            {/*<Flex>
                                                                                <Box>
                                                                                    <span className='text-primary'>Feature update:</span>{' '}
                                                                                    watch <a href="https://www.loom.com/share/6f51b7944c644e9785c0705df39a4e10" target="_blank">video explanation</a>,{' '}
                                                                                    or submit <a href="https://panelfox.hellozest.io/recorder" target="_blank">feedback/bug</a>.

                                                                                </Box>
                                                                            </Flex>*/}
                                                                        </Box>
                                                                    </Flex>
                                                                    <Flex
                                                                        id="availability-scroll"
                                                                        sx={{
                                                                            height: '520px',
                                                                            overflow: 'scroll',
                                                                            position: 'relative',
                                                                            flexDirection: 'column'
                                                                        }}
                                                                    >
                                                                        <Flex
                                                                            className="select-box-header-wrapper"
                                                                            sx={{
                                                                                width: `${date_range_days.length *
                                                                                    104}px`,
                                                                                gridGap: '12px'
                                                                            }}
                                                                        >
                                                                            {date_range_days.map(date => (
                                                                                <Box className="select-box-header">
                                                                                    {this.state.study
                                                                                        .daterange_enabled ? (
                                                                                        <>
                                                                                            <div className="text-secondary">
                                                                                                {moment(date).format(
                                                                                                    'MMM'
                                                                                                )}
                                                                                            </div>

                                                                                            <div
                                                                                                className="text-primary bold"
                                                                                                style={{
                                                                                                    fontSize: '24px',
                                                                                                    lineHeight: '23px'
                                                                                                }}
                                                                                            >
                                                                                                {moment(date).format(
                                                                                                    'D'
                                                                                                )}
                                                                                            </div>

                                                                                            <div className="text-secondary uppercase">
                                                                                                {moment(date).format(
                                                                                                    'ddd'
                                                                                                )}
                                                                                            </div>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div
                                                                                                className="text-primary"
                                                                                                style={{
                                                                                                    marginTop: '26px'
                                                                                                }}
                                                                                            >
                                                                                                {date}
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </Box>
                                                                            ))}
                                                                        </Flex>
                                                                        <Flex sx={{ margin: '0', gridGap: '12px' }}>
                                                                            {date_range_days.map(date => {
                                                                                let date_ranges = [];
                                                                                if (
                                                                                    this.state.study.daterange_enabled
                                                                                ) {
                                                                                    if (
                                                                                        this.state.study
                                                                                            .availability_range[date]
                                                                                    ) {
                                                                                        date_ranges = this.state.study
                                                                                            .availability_range[date];
                                                                                    }
                                                                                } else {
                                                                                    if (
                                                                                        this.state.study
                                                                                            .availability_generic[date]
                                                                                    ) {
                                                                                        date_ranges = this.state.study
                                                                                            .availability_generic[date];
                                                                                    }
                                                                                }

                                                                                const dateTimes = this.generateTimes(
                                                                                    date,
                                                                                    selectedChildren[date]
                                                                                );

                                                                                return (
                                                                                    <CalendarTrack
                                                                                        timezone={
                                                                                            this.state.study.timezone
                                                                                        }
                                                                                        ranges={date_ranges}
                                                                                        onRemoveRange={range =>
                                                                                            this.onRemoveRange(
                                                                                                date,
                                                                                                range
                                                                                            )
                                                                                        }
                                                                                        duration={
                                                                                            this.state.study.duration
                                                                                        }
                                                                                        onSelectionChange={range =>
                                                                                            this.onSelectionChange2(
                                                                                                date,
                                                                                                range
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {dateTimes.map(time => (
                                                                                            <CalendarTimeSlot
                                                                                                key={time.id}
                                                                                                text={time.title}
                                                                                                available={
                                                                                                    time.available
                                                                                                }
                                                                                                timestamp={time.id}
                                                                                            />
                                                                                        ))}
                                                                                    </CalendarTrack>
                                                                                );
                                                                            })}
                                                                        </Flex>
                                                                    </Flex>

                                                                    <Box mt={3}>
                                                                        <Button
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            className="muted"
                                                                            fontSize={1}
                                                                            onClick={() => {
                                                                                if (
                                                                                    confirm(
                                                                                        'Are you sure you want to reset the schedule?'
                                                                                    )
                                                                                ) {
                                                                                    this.onSelectionReset();
                                                                                }
                                                                            }}
                                                                        >
                                                                            Clear Availability
                                                                        </Button>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    )}
                                            </>
                                            <Box
                                                id="calendar-setup-calendar-event"
                                                ref={this.calendarEventRef}
                                                className="form-wrapper2 form-wrapper2-border"
                                            >
                                                <Flex className="settings-header">
                                                    <Box>
                                                        <H2>Calendar Event</H2>
                                                        <Box className="form-section-information">
                                                            This information be added as the event summary to the
                                                            calendar invite, and reminder emails.
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                                <Box flexGrow={1}>
                                                    <Flex style={{ gap: '8px' }}>
                                                        <Box flexGrow={1}>
                                                            <Box className="form-label">Event title</Box>
                                                            <Box className="form-data">
                                                                <Input
                                                                    name="session_title"
                                                                    placeholder="Session title"
                                                                    value={this.state.study.session_title}
                                                                    onChange={this.onFormChange}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <VariablesHelper
                                                            style={{ minWidth: '30%', flexGrow: 0 }}
                                                            study={this.state.study}
                                                            templateType={EMAIL_TEMPLATE_TYPE.PLAIN}
                                                        />
                                                    </Flex>
                                                    <Box className="form-label form-row-margin">Event Summary</Box>
                                                    <Box className="form-data">
                                                        <SaveEditEditor
                                                            modules={EXTERNAL_CALENDAR_RESTRICTIONS_MODULES}
                                                            defaultValue={this.state.study.session_info}
                                                            onSave={html =>
                                                                this.onFormChange({
                                                                    target: { name: 'session_info', value: html }
                                                                })
                                                            }
                                                        />
                                                        {/*<Label htmlFor="session_info" mt={3}>
                                                            Reschedule and Cancellation links will be automatically added to the
                                                            Calendar invite.
                                                        </Label>*/}
                                                    </Box>
                                                    <Box className="form-label form-row-margin">
                                                        Global Location{' '}
                                                        <a
                                                            href="#"
                                                            data-beacon-article="633629aae1c306062a1d2ff7"
                                                            className="help-question"
                                                        >
                                                            ?
                                                        </a>
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Box>
                                                            <LocationSettings
                                                                study={this.state.study}
                                                                studyUsers={this.state.study_users}
                                                                auth={this.props.auth}
                                                                onChange={this.onUpdateStudyLocation}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box
                                                id="self-scheduling"
                                                ref={this.selfSchedulingRef}
                                                className="form-wrapper2 form-wrapper2-border"
                                            >
                                                <Flex className="settings-header">
                                                    <Box>
                                                        <H2>Self-Scheduling</H2>
                                                        <Box className="form-section-information">
                                                            Configure the respondent self-scheduling experience
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                                <Box flexGrow={1}>
                                                    <Box className="form-label">
                                                        Self-Scheduling Confirmation{' '}
                                                        <a
                                                            href="#"
                                                            data-beacon-article="62fadefa61ff5d5f24f9c1ad"
                                                            className="help-question"
                                                        >
                                                            ?
                                                        </a>
                                                    </Box>
                                                    <Box className="form-data">
                                                        <SaveEditEditor
                                                            modules={SESSION_CONFIRMATION_MODULES}
                                                            defaultValue={this.state.study.scheduling_confirmation}
                                                            onSave={html => {
                                                                this.onFormChange({
                                                                    target: {
                                                                        name: 'scheduling_confirmation',
                                                                        value: html
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </Box>
                                                    {/*This control is locked due to lack of direct operation. This variable is global,*/}
                                                    {/*and we don't want it to be affected from the study view,*/}
                                                    {/*but we want to let the user know where it is and what its value is at the moment.*/}
                                                    <Box className="form-label form-row-margin">
                                                        Allow panelists to reschedule & cancel sessions{' '}
                                                        <a
                                                            target="_blank"
                                                            href="/settings#reschedule-and-cancel-sessions"
                                                        >
                                                            <FiExternalLink style={{ margin: 0 }} />
                                                        </a>
                                                    </Box>
                                                    <Box classname="form-data">
                                                        <Switch
                                                            className="switch-small"
                                                            disabled={true}
                                                            checked={
                                                                this.props.auth.account.allow_session_changes
                                                                    ? true
                                                                    : false
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box
                                                id="calendar-setup-reminders"
                                                ref={this.remindersRef}
                                                className="form-wrapper2 form-wrapper2-border"
                                            >
                                                <Flex className="settings-header">
                                                    <Box>
                                                        <H2>Reminders</H2>
                                                        <Box className="form-section-information">
                                                            Send meeting reminder emails in advance.{' '}
                                                            <a
                                                                href="#"
                                                                data-beacon-article="6305318486ac2a0f07249a2d"
                                                                className="help-question"
                                                            >
                                                                ?
                                                            </a>
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                                <Box>
                                                    <Box className="form-data">
                                                        {remindersSorted.map(r => {
                                                            return (
                                                                <Box mb={3}>
                                                                    <Flex fontSize={1}>
                                                                        <Select
                                                                            value={r.type}
                                                                            name="type"
                                                                            onChange={e =>
                                                                                this.updateReminder(
                                                                                    r.id,
                                                                                    e.target.name,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            sx={{ width: '120px !important' }}
                                                                            mr={3}
                                                                        >
                                                                            <option value="email">Email</option>
                                                                            <option
                                                                                value="sms"
                                                                                disabled={!this.hasSmsEnabled()}
                                                                            >
                                                                                SMS
                                                                            </option>
                                                                            <option
                                                                                value="call"
                                                                                disabled={!this.hasSmsEnabled()}
                                                                            >
                                                                                Phone Call
                                                                            </option>
                                                                        </Select>

                                                                        <Select
                                                                            value={r.hours}
                                                                            name="hours"
                                                                            onChange={e =>
                                                                                this.updateReminder(
                                                                                    r.id,
                                                                                    e.target.name,
                                                                                    e.target.value
                                                                                )
                                                                            }
                                                                            sx={{
                                                                                width: '210px !important',
                                                                                flexGrow: 1
                                                                            }}
                                                                        >
                                                                            {reminder_options.map(ro => {
                                                                                return (
                                                                                    <option value={ro.value}>
                                                                                        {ro.label}
                                                                                    </option>
                                                                                );
                                                                            })}
                                                                        </Select>

                                                                        <Button
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            ml={3}
                                                                            onClick={() => {
                                                                                if (r.type == 'email') {
                                                                                    this.props.history.push(
                                                                                        `#${this.tab_calendar_reminder_email}`
                                                                                    );
                                                                                } else {
                                                                                    this.setState({
                                                                                        showModalEditReminderBody: true,
                                                                                        editing_reminder_id: r.id,
                                                                                        editing_reminder_body: r.body
                                                                                    });
                                                                                }
                                                                            }}
                                                                        >
                                                                            <FiEdit /> Edit Template
                                                                        </Button>

                                                                        <Button
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            className="secondary-icon"
                                                                            ml={2}
                                                                            mr={0}
                                                                            onClick={() => this.deleteReminder(r.id)}
                                                                        >
                                                                            <FiTrash2 />
                                                                        </Button>
                                                                    </Flex>
                                                                    {r.type != 'email' && (
                                                                        <Box
                                                                            mt={2}
                                                                            className={` fs-13 all-breaks blockquote-notes italic ${
                                                                                r.body ? 'gray' : 'red'
                                                                            }`}
                                                                        >
                                                                            {r.body ? r.body : 'Missing reminder'}
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            );
                                                        })}
                                                        {this.state.isAddingReminder == true && (
                                                            <div style={{ width: 0, marginBottom: '32px' }}>
                                                                <LoadingWrapper>
                                                                    <LoadingIndicator />
                                                                </LoadingWrapper>
                                                            </div>
                                                        )}
                                                    </Box>

                                                    <Box mt={3}>
                                                        <Button
                                                            type="button"
                                                            variant="secondary"
                                                            onClick={this.createReminder}
                                                            mr={0}
                                                        >
                                                            <FiPlus /> Add Reminder
                                                        </Button>
                                                        {!this.hasSmsEnabled() && (
                                                            <UpgradeButton
                                                                prefix="SMS Message"
                                                                style={{ marginLeft: '8px' }}
                                                                type="sms-mass"
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box flex="0 0 auto">
                                            <SideNav
                                                sections={[
                                                    {
                                                        id: 'calendar-setup-scheduling-configuration',
                                                        ref: this.schedulingConfigurationRef,
                                                        label: 'Scheduling Configuration',
                                                        display: true
                                                    },
                                                    {
                                                        id: 'calendar-setup-sessions',
                                                        ref: this.sessionsRef,
                                                        label: 'Sessions',
                                                        display:
                                                            this.state.study.scheduling_type == 'panelfox' &&
                                                            this.state.study.availability_type ==
                                                                STUDY_AVAILABILITY_TYPES.SESSIONS
                                                    },
                                                    {
                                                        id: 'calendar-setup-general-availability',
                                                        ref: this.generalAvailabilityRef,
                                                        label: 'General Availability',
                                                        display:
                                                            this.state.study.scheduling_type == 'panelfox' &&
                                                            this.state.study.availability_type ==
                                                                STUDY_AVAILABILITY_TYPES.RANGES
                                                    },
                                                    {
                                                        id: 'calendar-setup-calendar-event',
                                                        ref: this.calendarEventRef,
                                                        label: 'Calendar Event',
                                                        display: true
                                                    },
                                                    {
                                                        id: 'self-scheduling',
                                                        ref: this.selfSchedulingRef,
                                                        label: 'Self Scheduling',
                                                        display: true
                                                    },
                                                    {
                                                        id: 'calendar-setup-reminders',
                                                        ref: this.remindersRef,
                                                        label: 'Reminders',
                                                        display: true
                                                    }
                                                ]}
                                            />
                                        </Box>
                                    </Flex>
                                )}

                                {this.state.page_tab_selected == this.tab_calendar_invite_email && (
                                    <Box>
                                        <AppPageWrapperSectionHeader className="experience" style={{ padding: 0 }}>
                                            <Box>
                                                <H1>Request Email</H1>
                                                <Box className="experience-description">
                                                    Invite respondents to schedule themselves.
                                                </Box>
                                            </Box>
                                            <Box>
                                                {this.state.editorTypeEmailSchedulingRequest ===
                                                    EMAIL_TEMPLATE_TYPE.BLOCKS && (
                                                    <Button
                                                        variant="primary"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            helpers.popupWindow({
                                                                url: `${config.API_URL}/studies/${this.state.study.id}/scheduling-email-preview?account_id=${this.state.study.account_id}&email_action=preview`,
                                                                w: 800,
                                                                h: 600
                                                            });
                                                        }}
                                                        mr={0}
                                                    >
                                                        <FiEye style={{ marginRight: '8px' }} />
                                                        Preview Email
                                                    </Button>
                                                )}
                                            </Box>
                                        </AppPageWrapperSectionHeader>
                                        <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                            <Flex sx={{ gridGap: '32px' }} my="24px" alignItems="self-end">
                                                <Box flex="2">
                                                    <Box className="form-label">Email Subject</Box>
                                                    <Box>
                                                        <Input
                                                            type="text"
                                                            name="email_scheduling_subject"
                                                            value={this.state.study.email_scheduling_subject}
                                                            onChange={this.onFormChange}
                                                            placeholder="Email Subject"
                                                        />
                                                    </Box>
                                                </Box>
                                                <VariablesHelper
                                                    study={this.state.study}
                                                    templateType={this.state.editorTypeEmailSchedulingRequest}
                                                />
                                                <EmailTemplateTypeLabel
                                                    type={this.state.editorTypeEmailSchedulingRequest}
                                                />
                                            </Flex>
                                        </Flex>
                                        {this.state.editorDataEmailSchedulingRequest && (
                                            <Box sx={{ width: '100%' }}>
                                                <EmailEditor
                                                    type={this.state.editorTypeEmailSchedulingRequest}
                                                    editorData={this.state.editorDataEmailSchedulingRequest}
                                                    onChange={this.onChangeEditorEmailSchedulingRequest}
                                                    allowDscoutButton
                                                    allowScreenerButton
                                                    onTemplate={template => this.onTemplate(template, 'scheduling')}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                {this.state.page_tab_selected == this.tab_calendar_confirmation_email && (
                                    <Box>
                                        <AppPageWrapperSectionHeader className="experience" style={{ padding: 0 }}>
                                            <Box>
                                                <H1>Confirmation Email</H1>
                                                <Box className="experience-description">
                                                    Confirm participation with your respondents.
                                                </Box>
                                            </Box>
                                            <Box>
                                                {this.state.editorTypeEmailConfirmation ===
                                                    EMAIL_TEMPLATE_TYPE.BLOCKS && (
                                                    <Button
                                                        variant="primary"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            helpers.popupWindow({
                                                                url: `${config.API_URL}/studies/${this.state.study.id}/confirmation-email-preview?account_id=${this.state.study.account_id}&email_action=preview`,
                                                                w: 800,
                                                                h: 600
                                                            });
                                                        }}
                                                        mr={0}
                                                    >
                                                        <FiEye style={{ marginRight: '8px' }} />
                                                        Preview Email
                                                    </Button>
                                                )}
                                            </Box>
                                        </AppPageWrapperSectionHeader>
                                        <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                            <Flex sx={{ gridGap: '32px' }} my="24px" alignItems="self-end">
                                                <Box flex="2">
                                                    <Box className="form-label">Email Subject</Box>
                                                    <Box>
                                                        <Input
                                                            type="text"
                                                            name="email_confirmation_subject"
                                                            value={this.state.study.email_confirmation_subject}
                                                            onChange={this.onFormChange}
                                                            placeholder="Email Subject"
                                                        />
                                                    </Box>
                                                </Box>
                                                <VariablesHelper
                                                    study={this.state.study}
                                                    templateType={this.state.editorTypeEmailConfirmation}
                                                />
                                                <EmailTemplateTypeLabel type={this.state.editorTypeEmailConfirmation} />
                                            </Flex>
                                        </Flex>
                                        {this.state.editorDataEmailConfirmation && (
                                            <Box sx={{ width: '100%' }}>
                                                <EmailEditor
                                                    type={this.state.editorTypeEmailConfirmation}
                                                    editorData={this.state.editorDataEmailConfirmation}
                                                    onChange={this.onChangeEditorEmailConfirmation}
                                                    allowScreenerButton
                                                    allowDscoutButton
                                                    onTemplate={template => this.onTemplate(template, 'confirmation')}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                {this.state.page_tab_selected == this.tab_calendar_reminder_email && (
                                    <Box>
                                        <AppPageWrapperSectionHeader className="experience" style={{ padding: 0 }}>
                                            <Box>
                                                <H1>Reminder Email</H1>
                                                <Box className="experience-description">
                                                    Send reminders to your respondents.
                                                </Box>
                                            </Box>
                                            <Box>
                                                {this.state.editorTypeEmailReminder === EMAIL_TEMPLATE_TYPE.BLOCKS && (
                                                    <Button
                                                        variant="primary"
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            helpers.popupWindow({
                                                                url: `${config.API_URL}/studies/${this.state.study.id}/reminder-email-preview?account_id=${this.state.study.account_id}&email_action=preview`,
                                                                w: 800,
                                                                h: 600
                                                            });
                                                        }}
                                                        mr={0}
                                                    >
                                                        <FiEye style={{ marginRight: '8px' }} />
                                                        Preview Email
                                                    </Button>
                                                )}
                                            </Box>
                                        </AppPageWrapperSectionHeader>
                                        <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                            <Flex sx={{ gridGap: '32px' }} my="24px" alignItems="self-end">
                                                <Box flex="2">
                                                    <Box className="form-label">Email Subject</Box>
                                                    <Box>
                                                        <Input
                                                            type="text"
                                                            name="email_reminder_subject"
                                                            value={this.state.study.email_reminder_subject}
                                                            onChange={this.onFormChange}
                                                            placeholder="Email Subject"
                                                        />
                                                    </Box>
                                                </Box>
                                                <VariablesHelper
                                                    study={this.state.study}
                                                    templateType={this.state.editorTypeEmailReminder}
                                                />
                                                <EmailTemplateTypeLabel type={this.state.editorTypeEmailReminder} />
                                            </Flex>
                                        </Flex>
                                        {this.state.editorDataEmailReminder && (
                                            <Box sx={{ width: '100%' }}>
                                                <EmailEditor
                                                    type={this.state.editorTypeEmailReminder}
                                                    editorData={this.state.editorDataEmailReminder}
                                                    onChange={this.onChangeEditorEmailReminder}
                                                    allowScreenerButton
                                                    allowDscoutButton
                                                    onTemplate={template => this.onTemplate(template, 'reminder')}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                <br />
                                <br />
                            </Box>
                        )}
                    </AppPageWrapperSectionBody>

                    <SimpleModalTextarea
                        title={'Edit Reminder Body'}
                        maxCharacters={320}
                        isOpen={this.state.showModalEditReminderBody}
                        onRequestClose={() => {
                            this.setState({ showModalEditReminderBody: false });
                        }}
                        aboveTextArea={<VariablesHelper />}
                        value={this.state.editing_reminder_body}
                        onSubmit={value => {
                            this.updateReminder(this.state.editing_reminder_id, 'body', value);
                            // reset modal value
                            this.setState({ editing_reminder_body: '' });
                        }}
                    />

                    <SimpleModalTextarea
                        title={'Edit SMS Body'}
                        maxCharacters={320}
                        isOpen={this.state.showModalEditReminderSmsBody}
                        onRequestClose={() => {
                            this.setState({ showModalEditReminderSmsBody: false });
                        }}
                        aboveTextArea={<VariablesHelper />}
                        value={this.state.study.participant_reminders_sms_body}
                        onSubmit={value => {
                            this.onFormChange({
                                target: {
                                    name: 'participant_reminders_sms_body',
                                    value: value
                                }
                            });
                        }}
                    />

                    <SimpleModalTextarea
                        title={'Edit Voice Call Body'}
                        maxCharacters={5000}
                        isOpen={this.state.showModalEditReminderCallBody}
                        onRequestClose={() => {
                            this.setState({ showModalEditReminderCallBody: false });
                        }}
                        aboveTextArea={<VariablesHelper />}
                        value={this.state.study.participant_reminders_call_body}
                        onSubmit={value => {
                            this.onFormChange({
                                target: {
                                    name: 'participant_reminders_call_body',
                                    value: value
                                }
                            });
                        }}
                    />

                    <NiceModal
                        isOpen={this.state.showSessionEditor}
                        onRequestClose={() => {
                            this.setState({ showSessionEditor: false });
                        }}
                        title={(this.state.showSessionEditor == 'new' ? 'Create a new' : 'Update') + ' session'}
                    >
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                if (this.state.showSessionEditor == 'new') {
                                    this.addSession();
                                } else {
                                    this.editSession();
                                }
                                //const email_action = 'preview';
                                //this.handleModalSendCampaign_review(email_action);
                                //this.setState({ showModalSendCampaign_editMode: false });
                            }}
                        >
                            <Box fontSize={1}>
                                <Flex sx={{ width: '100%', gridGap: 3 }}>
                                    <Box width={1 / 2}>
                                        <Label>Date *</Label>
                                        <DatePicker
                                            selected={
                                                this.state.showSessionEditor_session.date
                                                    ? moment(this.state.showSessionEditor_session.date).toDate()
                                                    : ''
                                            }
                                            onChange={e =>
                                                this.onSessionEditFormChange({
                                                    target: {
                                                        name: 'date',
                                                        value: e
                                                    }
                                                })
                                            }
                                            className={`theme-input`}
                                            style={{ width: '100%' }}
                                            required
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <Label>Time *</Label>
                                        <Input
                                            type="time"
                                            name="time"
                                            value={this.state.showSessionEditor_session.time}
                                            onChange={this.onSessionEditFormChange}
                                            required
                                        />
                                    </Box>
                                </Flex>
                                <Flex sx={{ width: '100%', gridGap: 3 }}>
                                    <Box width={1 / 2}>
                                        <Label mt={3}>Duration (minutes) *</Label>
                                        <Input
                                            type="number"
                                            name="duration"
                                            value={this.state.showSessionEditor_session.duration}
                                            onChange={this.onSessionEditFormChange}
                                            required
                                        />
                                    </Box>
                                    <Box width={1 / 2}>
                                        <Label mt={3}>Max Number Participants *</Label>
                                        <Input
                                            type="number"
                                            name="participants"
                                            value={this.state.showSessionEditor_session.participants}
                                            onChange={this.onSessionEditFormChange}
                                            required
                                        />
                                    </Box>
                                </Flex>
                                <Box>
                                    <Label mt={3}>Internal Title (optional)</Label>
                                    <Input
                                        type="text"
                                        name="title"
                                        value={this.state.showSessionEditor_session.title}
                                        onChange={this.onSessionEditFormChange}
                                        autoComplete="off"
                                    />
                                </Box>
                                {/*<Label>Location</Label>
                                <Input type='text' name="location" value={this.state.showSessionEditor_session.location} onChange={this.onSessionEditFormChange} />
                                <Label>Description</Label>
                                <Textarea name="description" value={this.state.showSessionEditor_session.description} onChange={this.onSessionEditFormChange}></Textarea>*/}
                            </Box>
                            <Box className="modal-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => {
                                        this.setState({ showSessionEditor: false });
                                    }}
                                >
                                    Cancel
                                </Button>

                                <Button type="submit" variant="primary" sx={{ marginRight: 0 }}>
                                    {this.state.showSessionEditor == 'new' ? 'Create' : 'Update'} session
                                </Button>
                            </Box>
                        </form>
                    </NiceModal>
                </AppPageWrapper>
                <ManageStudyMembersModal
                    studyId={this.state.study.id}
                    isOpen={this.state.isManageStudyMembersOpen}
                    onClose={() => this.setState({ isManageStudyMembersOpen: false })}
                    onObserversUpdate={observers => {
                        this.setState(
                            produce(draft => {
                                draft.study.observers = observers.join(',');
                            })
                        );
                        this.checkIfRespondentsScheduledInFuture();
                    }}
                    onCollaboratorsUpdate={collaborators => {
                        this.setState({ study_users: collaborators });
                        this.checkIfRespondentsScheduledInFuture();
                    }}
                />
            </Flex>
        );
    }
}

export default withRouter(withToastManager(StudySchedulingPage));
